import React, { useState, useContext, useEffect } from 'react';
import '../styles/VestrPlus.css';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../helper/PaymentHelper';
import axios from 'axios';
import config from '../config';
import vestrPlus1 from '../assets/vestrPlus1.png';
import vestrPlus1Bg from '../assets/vestrPlus1Bg.png';
import vestrPlus2 from '../assets/vestrPlus2.png';
import vestrPlus2Bg from '../assets/vestrPlus2Bg.png';
import vestrPlus3 from '../assets/vestrPlus3.png';
import vestrPlus3Bg from '../assets/vestrPlus3Bg.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../context/AuthContext';
import useResponsiveWidth from '../hooks/useResponsiveWidth';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const cycleMap = {"Annual" : "year", "Monthly" : "month"};

export default function VestrPlus() {
  useResponsiveWidth();
  const [activeTab, setActiveTab] = useState('Monthly');
  const { isAuthenticated, userToken } = useContext(AuthContext);  
  const [vestrMembership, setVestrMembership] = useState({});

  useEffect(() => {
    const fetchMembershipDetails = async () => {
      if (!userToken) return;
      try {
        const response = await axios.get(`${config.backendUrl}/api/payments/membership-details`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.status === 200) {
          const fetchedMembership = response.data.vestrMembership;
          setVestrMembership(fetchedMembership);
        } else {
          console.error('Error fetching membership details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching membership details:', error);
      }
    };

    fetchMembershipDetails();
  }, [userToken]);

  const handleCheckout = async (productTier) => {
    
    if (!isAuthenticated) {
      alert('Please sign in to continue');
      return;
    }

    if (productTier === 'SILVER') {
      alert('Silver membership is free. You do not need to checkout.');
      return;
    }
    if (vestrMembership?.type.toLowerCase() === productTier.toLowerCase() && vestrMembership?.cycle === cycleMap[activeTab]) {
      alert('You are already subscribed to this membership.');
      return;
    }
    const stripe = await stripePromise;
    try {
      const response = await axios.post(`${config.backendUrl}/api/payments/create-checkout-session`, {
        productTier, //Green or gold
        billingCycle: activeTab, // Monthly or Annual
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      });

      const session = response.data;

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const renderButton = (membershipType) => {

    if (vestrMembership.type === membershipType && vestrMembership.cycle === cycleMap[activeTab]) {
      return 'Current';
    } 
    return 'Start now';
  };

  return (
    <div className="vp-container">
      <div className='vp-header-container'>
        <h2>Vestr +</h2>


   <div className="se-toggle-container">
      <div
        className={`se-toggle-option bold ${activeTab === 'Monthly' ? 'active' : ''}`}
        onClick={() => {
          setActiveTab('Monthly');
        }}
      >
        Monthly 
      </div>
      <div
        className={`se-toggle-option bold ${activeTab === 'Annual' ? 'active' : ''}`}
        onClick={() => {
          setActiveTab('Annual');
        }}
      >
        Annual 
      </div>
    </div>
    </div>
      <div className="vp-content">
        

        {/* Silver Membership */}
        <div className="vp-card">
          <div className="vp-image-container">
            <img className="vp-image-bg" src={vestrPlus1Bg} alt="Vestr Plus Background" />
            <img className="vp-image" src={vestrPlus1} alt="Vestr Plus 1" />
          </div>
          <h2 className="vp-type-title bold">Silver</h2>
          <h3 className="vp-cost bold">Free</h3>
          <button onClick={()=> {handleCheckout('SILVER')}}className="vp-button bold">{renderButton('Silver')}</button>
          <div className="vp-perks" >
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Silver founder badge on profile</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> No check mark next to name</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Access to Mr. Vestr</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 10 Mr. Vestr prompts/mo</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 5 years of financial data</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 2 years of KPI data</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Global stocks, ETFs & Fund Coverage</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 3 Events (calls, transcripts, and slides)</span>
          </div>
        </div>

        {/* Green Membership */}
        <div className="vp-card">
                 {activeTab === 'Annual' && <div className="vp-off bold">17% off</div>}
          <div className="vp-image-container">
            <img className="vp-image-bg" src={vestrPlus2Bg} alt="Vestr Plus Background" />
            <img className="vp-image" src={vestrPlus2} alt="Vestr Plus 2" />
          </div>
          <h2 className="vp-type-title bold">Green</h2>
          <h3 className="vp-cost bold">
            <span className="bold">${activeTab === 'Annual' ? '280' : '28'}</span> 
            <span className="vp-per">/{activeTab === 'Annual' ? 'year' : 'month'}</span>
          
          </h3>
          <button 
            className="vp-button bold" 
            style={{ border: 'none', color: '#fff', backgroundImage: 'linear-gradient(to right, #1DCF5B, #1BFF6A)' }}
            onClick={() => handleCheckout('GREEN')}
          >
            {renderButton('Green')}
          </button>
          <div className="vp-perks">
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Green founder badge on profile</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Green check mark next to name</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Access to Mr. Vestr (Vestr AI)</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Unlimited Mr. Vestr prompts</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 10 years of financial data</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 5 years of KPI data</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Global stocks, ETFs & Fund Coverage</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 10 Events (calls, transcripts, and slides)</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 3 years of estimates & Ratings</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> First priority support</span>
          </div>
        </div>

        {/* Gold Membership */}
        <div className="vp-card">
          {activeTab === 'Annual' && <div className="vp-off bold">17% off</div>}
          <div className="vp-image-container">
            <img className="vp-image-bg" src={vestrPlus3Bg} alt="Vestr Plus Background" />
            <img className="vp-image" src={vestrPlus3} alt="Vestr Plus 3" />
          </div>
          <h2 className="vp-type-title bold">Gold</h2>
          <h3 className="vp-cost bold">
            <span className="bold">${activeTab === 'Annual' ? '710' : '71'}</span>
            <span className="vp-per medium">/{activeTab === 'Annual' ? 'year' : 'month'}</span>
           
          </h3>
          <button
            className="vp-button bold"
            style={{ border: 'none', color: '#fff', backgroundImage: 'linear-gradient(to right, #FFBF19, #FFDF8B)' }}
            onClick={() => handleCheckout('GOLD')}
          >
            {renderButton('Gold')}
          </button>
          <div className="vp-perks">
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Gold founder badge on profile</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Gold name and check mark next to name</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Access to Mr. Vestr (Vestr AI)</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Unlimited Mr. Vestr prompts</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 20 years of financial data</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 12 years of KPI data</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Global stocks, ETFs & Fund Coverage</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 30+ Events (calls, transcripts, and slides)</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> 3 years of estimates & Ratings</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> First priority support</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Invite to monthly call with Vestr Team to discuss roadmap and strategy</span>
              <span><FontAwesomeIcon icon={faCheck} className ="vp-fa"/> Personal phone call from Vestr CEO</span>
          </div>
        </div>


      </div>
    </div>
  );
}
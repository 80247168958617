import { useEffect } from 'react';

const useResponsiveWidth = () => {
  useEffect(() => {
    // Using fixed 
     const initialWidth = window.screen.width;
    const halfInitialWidth = Math.min(1440, 0.75 * initialWidth);
    const maxWidth = initialWidth;
    const minWidth = halfInitialWidth;

    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const rootElement = document.getElementById('root');

      if (currentWidth >= maxWidth) {
        document.documentElement.style.width = '100%';
        document.body.style.width = '100%';
        rootElement.style.width = '100%';
      } else if (currentWidth < minWidth) {
        document.documentElement.style.width = `${minWidth}px`;
        document.body.style.width = `${minWidth}px`;
        rootElement.style.width = `${minWidth}px`;
      } else {
        document.documentElement.style.width = '100%';
        document.body.style.width = '100%';
        rootElement.style.width = '100%';
      }
    };

    // Apply initial width adjustments
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};

export default useResponsiveWidth;
import React, { useState } from "react";
import "./DataTable.css";
import dayjs from "dayjs";

const DataTable = ({ data, rowsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  // Get the data for the current page
  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    return ret;
  }

  return (
    <div className="dt-data-table-container">
      <table className="dt-data-table">
        <thead className="dt-data-table-header">
          <tr>
            <th className="dt-data-table-header-cell">Name</th>
            <th className="dt-data-table-header-cell">Page</th>
            <th className="dt-data-table-header-cell">Duration</th>
            <th className="dt-data-table-header-cell">IP Address</th>
            <th className="dt-data-table-header-cell">Date</th>
          </tr>
        </thead>
        <tbody className="dt-data-table-body">
          {currentData.length == 0 && (
            <tr>
              <td colSpan={5}>No records found.</td>
            </tr>
          )}
          {currentData.map((item, index) => (
            <tr key={index} className="dt-data-table-row">
              <td className="dt-data-table-cell">{item.user}</td>
              <td className="dt-data-table-cell">{item.page}</td>
              <td className="dt-data-table-cell">
                {fancyTimeFormat(item.duration)}
              </td>
              <td className="dt-data-table-cell">
                {item.ip_address && item.ip_address} {!item.ip_address && "N/A"}
              </td>
              <td className="dt-data-table-cell">
                {dayjs(item.createdAt).format("MM-DD-YYYY HH:mm:ss")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="dt-pagination-container">
        <button
          className="dt-pagination-button"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="dt-pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="dt-pagination-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DataTable;

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import '../styles/Stock.css';
import Select from 'react-select';
import { createStockChart } from '../helper/StockChartHelper';
import {
   getUnixTimestamp,
   formatNumber,
   getUnixTimestampRange,
   getESTDate,
   intervalMap,
   filters,
   getThreeMonthsAgo,
   getMarketStatus,
   filterHighMap,
   isOldCompany,
   getLastValidTradingDay,
   timespanToMilliseconds,
   handleBuyAsset,
   handleSellAsset,
   fetchUserPortfolio,
   fetchAllTransactions,
   fetchDailyOpenClose,
   getDailyChange,
   fetchCompanyDetails,
   fetchStockLists,
   fetchDividendYield,
   fetchEPSAndPERatio,
   renderAfterMarketChange,
   getStartOfTradingWeek,
   calculateReturns,
   getChangePercentageColor,
   handleAddToWatchlist,
   handleRemoveFromWatchlist,
   handleAddToList,
   handleRemoveFromList,
   getWatchList,
   getTickerNews,
   getStartOfTradingDay,
   calculatePercentageChange,
   fetchAllPortfolios,
} from '../helper/MarketsHelper';
import { AuthContext } from '../context/AuthContext';
import config from '../config';
import { LoadingSpinner, CustomDateInput, formatCurrency as fc, removeCommas as rc, customStylesPlus } from '../helper/StylesHelper';
import DatePicker from 'react-datepicker'; 
import { ThemeContext } from '../context/ThemeContext';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import MarketSearchBar from './MarketSearchBar';
import BackButton from './BackButton';
import { DateTime } from 'luxon';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
import { shareTrade } from '../helper/CreatePostHelper';



export default function Stock() {
  useResponsiveWidth();
  const { ticker } = useParams();
  const navigate = useNavigate();
  const {tc,  theme} = useContext(ThemeContext);
  const {isAuthenticated, userToken, username} = useContext(AuthContext);
  const [stockData, setStockData] = useState([]);
  const [dailyData, setDailyData] = useState({});
  const [filter, setFilter] = useState('1d');
  const [currentPrice, setCurrentPrice] = useState(null);
  const [changeAmount, setChangeAmount] = useState(null);
  const [changePercent, setChangePercent] = useState(null);
  const [stockName, setStockName] = useState(ticker);
  const [companyDetails, setCompanyDetails] = useState({});
  const [companyDescription, setCompanyDescription] = useState('');
  const [eps, setEps] = useState(null);
  const [dividendYield, setDividendYield] = useState(null);
  const [peRatio, setPERatio] = useState(null);
  const [periodHigh, setPeriodHigh] = useState(null); 
  const [periodLow, setPeriodLow] = useState(null);  
  const [dateIPO, setDateIPO] = useState(null);
  const [mainStockDataFetched, setMainStockDataFetched] = useState(false);
  const [companyDetailsFetched, setCompanyDetailsFetched] = useState(false);

  const [listOptions, setListOptions] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);

  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [referencePriceForChart, setReferencePriceForChart] = useState(null);
  const [marketStatus, setMarketStatus] = useState(null);
  const [dailyChangeData, setDailyChangeData] = useState(null);
  const [lastValidTradingDay, setLastValidTradingDay] = useState(null);
  const [livePrice, setLivePrice] = useState(null);
  const [liveChangeAmount, setLiveChangeAmount] = useState(null);
  const [liveChangePercent, setLiveChangePercent] = useState(null);

  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
  const [hoveredDateTime, setHoveredDateTime] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [openSellModal, setOpenSellModal] = useState(false);
 
  const wsRef = useRef(null);
  const instantPriceTimeoutRef = useRef(null);

  const [quantity, setQuantity] = useState(null); 
  const [estimatedCost, setEstimatedCost] = useState(null); 
  const [bidAsk, setBidAsk] = useState({ bid: null, ask: null });

  const [userPortfolio, setUserPortfolio] = useState(null); 
  const [userTickerPosition, setUserTickerPosition] = useState(null); 
  const [allTransactions, setAllTransactions] = useState([]); 

  const [returnData, setReturnData] = useState(null);

  const [openTradeOptionsModal, setOpenTradeOptionsModal] = useState(false);
  const [earliestDate, setEarliestDate] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null); 
  const [optionType, setOptionType] = useState('call');
  const [optionsData, setOptionsData] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false); 
  const [isInWatchlist, setIsInWatchlist] = useState(false);
  const [stockNews, setStockNews] = useState([]);
  const [isConfirming, setIsConfirming] = useState(false);  
  const [isCongrats, setIsCongrats] = useState(false);  
  const [stock, setStock] = useState('');

  const [availableDates, setAvailableDates] = useState([]);
  const [transactionId, setTransactionId] = useState(null);

  const location = useLocation();

  const [allPortfolios, setAllPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      getWatchList(ticker, userToken, setIsInWatchlist);
    }
  }, [isAuthenticated, userToken, ticker]);

  useEffect(()=> {
    getTickerNews(ticker).then((data) => {
      setStockNews(data);
    });
  }, [ticker]);

  useEffect(()=> {
    document.getElementById('stock-container-wrapper')?.scrollIntoView();
  }, []);

  const loadOptionsData = async (expiration, type) => {
    try {
      setLoadingOptions(true);
      const formattedExpiration = DateTime.fromJSDate(expiration, { zone: 'America/New_York' }).toFormat('yyyy-MM-dd');
      
      const response = await axios.get(`${config.backendUrl}/api/markets/stock/options/${ticker}`, {
        params: { expiration: formattedExpiration, type },
      });
      setOptionsData(response.data);
    } catch (error) {
      setOptionsData([]);
      console.error('Error fetching options data:', error);
    } finally {
      setLoadingOptions(false);
    }
  };

  const loadAvailableDatesOfTheMonth = async (month, year) => {
    try {
      const response = await axios.get(`${config.backendUrl}/api/markets/stock/monthly-options/${ticker}`, {
        params: { month, year }
      });
  
      const allOptionsData = response.data.availableOptionsByDate || [];
      const newDates = allOptionsData.map(option => {
        const dateTimeInET = DateTime.fromISO(`${option.expirationDate}T16:00:00`, { zone: 'America/New_York' });
        if (!dateTimeInET.isValid) {
          console.error('Invalid DateTime:', dateTimeInET.invalidExplanation);
          return null;
        }
        return dateTimeInET.toJSDate();
      }).filter(date => date !== null); 
      setAvailableDates(prevDates => {
        const combinedDates = [...prevDates, ...newDates];
        const uniqueDates = Array.from(new Set(combinedDates.map(date => date.toDateString())))
          .map(dateString => new Date(dateString));
        setEarliestDate(uniqueDates[0]);
        
        return uniqueDates;
      });
    } catch (error) {
      console.error('Error loading dates:', error);
      // fallback if needed
      const nonWeekendDates = [];
      const daysInMonth = new Date(year, month, 0).getDate();
    
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month - 1, day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          nonWeekendDates.push(date);
        }
      }
      setAvailableDates(nonWeekendDates);
      setEarliestDate(nonWeekendDates[0]);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    loadAvailableDatesOfTheMonth(currentDate.getMonth() + 1, currentDate.getFullYear());
  }, [ticker]);

  const handleTradeOptionsClick = () => {
    setOpenTradeOptionsModal(true);
    if (expirationDate && optionType) {
      loadOptionsData(expirationDate, optionType);
    }
  };

  const handleExpirationDateChange = (date) => {
    setExpirationDate(date);
    loadOptionsData(date, optionType);
  };

  const handleOptionTypeChange = (selectedOption) => {
    setOptionType(selectedOption);
    if (expirationDate) {
      loadOptionsData(expirationDate, selectedOption);
    }
  };

  const handleMonthChange = (date) => {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    loadAvailableDatesOfTheMonth(month, year);
  };

  useEffect(() => {
    if (earliestDate && !expirationDate) {
      setExpirationDate(earliestDate);
    }
  }, [earliestDate, expirationDate]);

  useEffect(() => {
    if ((livePrice || currentPrice) && userTickerPosition && allTransactions) {
      let returns = calculateReturns(userTickerPosition, rc(livePrice || currentPrice), allTransactions);
      setReturnData(returns);
    }
  }, [livePrice, currentPrice, userTickerPosition, allTransactions]);

  useEffect(() => {
    if (userPortfolio && ticker) {
      setUserTickerPosition(userPortfolio?.assets.find((stock) => stock?.ticker?.toUpperCase() === ticker?.toUpperCase()));
    }
  }, [userPortfolio, ticker]);

  // Re-fetch portfolio and transactions when selectedPortfolio changes
  useEffect(() => {
    if (userToken && selectedPortfolio && selectedPortfolio._id) {
      fetchUserPortfolio(isAuthenticated, userToken, setUserPortfolio, selectedPortfolio._id);
      fetchAllTransactions(isAuthenticated, userToken, ticker, setAllTransactions, selectedPortfolio._id);
    }
    //eslint-disable-next-line
  }, [userToken, ticker, isAuthenticated, selectedPortfolio]);

  useEffect(() => {
    if ((livePrice || currentPrice)) {
      setEstimatedCost((quantity * rc(livePrice || currentPrice)));
    }
  }, [livePrice, quantity, currentPrice]);

  const updateChanges = (newPrice) => {
    if (!referencePriceForChart) {
      return;
    }
    setLivePrice(newPrice);
    const change = newPrice - referencePriceForChart;
    const changePercentage = (change / referencePriceForChart) * 100;
    if (filter === '1d') {
      let priceChange;
      if (dailyChangeData?.todaysClosePrice) {
        priceChange = dailyChangeData.todaysClosePrice - dailyChangeData?.prevClosePrice;
        setLiveChangeAmount(priceChange);
        setLiveChangePercent(dailyChangeData?.todaysPercentChange);
      } else {
        priceChange = (livePrice || currentPrice) - dailyChangeData?.prevClosePrice;
        setLiveChangeAmount(priceChange);
        setLiveChangePercent(changePercentage);
      }
    } else {
      setLiveChangeAmount(change);
      setLiveChangePercent(changePercentage);
    }
  };

  useEffect(() => {
    const fetchPrice = () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ action: 'getInstantPrice', ticker }));
      }
    };
    const intervalId = setInterval(fetchPrice, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [ticker]); 

  const sendInterval = (intervalMs) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({
        action: 'setInterval',
        ticker,
        intervalMs
      }));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        const [multiplier, timespan] = intervalMap[filter];
        const intervalMs = timespanToMilliseconds(multiplier, timespan);
        sendInterval(intervalMs);
        clearInterval(interval);
      }
    }, 500);
    //eslint-disable-next-line
  }, [filter, ticker]);

  const handleBuyClick = () => {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature');
      return;
    }
    setOpenBuyModal(true);
  };

  const handleSellClick = () => { 
    if (!isAuthenticated) {
      alert('Please sign in to access this feature');
      return;
    }
    setOpenSellModal(true);
  };

  const handleCloseModal = () => {
    setOpenBuyModal(false);
    setOpenSellModal(false);
    setOpenTradeOptionsModal(false);
    setIsConfirming(false);
    setIsCongrats(false);
    setTransactionId(null);
    if (instantPriceTimeoutRef.current) {
      clearTimeout(instantPriceTimeoutRef.current);
    }
  };

  useEffect(() => {
    getMarketStatus().then((data) => {
      setMarketStatus(data);
    });
  }, []);

  const fetchStockData = async () => {
    setChangeAmount(null);
    setPeriodHigh(null);
    setPeriodLow(null);
    setMainStockDataFetched(false);
    setLiveChangeAmount(null);
    setLiveChangePercent(null);
    setLivePrice(null);
    try {
      let fromDateUnixMs;
      let multiplier, timespan;
      let referencePrice;
      let toDateUnixMs = getUnixTimestamp();

      if (filter === 'all time') {
        fromDateUnixMs = dateIPO || getESTDate();
        const earliestAvailableDateStr = '2003-08-01';
        if (new Date(fromDateUnixMs) < new Date(earliestAvailableDateStr)) {
          fromDateUnixMs = earliestAvailableDateStr;
        }
      } else if (filter === '3m') {
        fromDateUnixMs = getThreeMonthsAgo();
      } else if (filter === '1d') {
        const dateInET = getStartOfTradingDay();
        fromDateUnixMs = dateInET.toMillis();
        toDateUnixMs = fromDateUnixMs + 57600000; 
        referencePrice = rc(dailyChangeData?.prevClosePrice);
      } else if (filter === '1w') {
        const dateInET = getStartOfTradingWeek();
        fromDateUnixMs = dateInET.toMillis();
        toDateUnixMs = fromDateUnixMs + 403200000; 
      } else {
        fromDateUnixMs = getUnixTimestampRange(filter);
      }

      const intervalArray = intervalMap[filter];
      multiplier = intervalArray[0];
      timespan = intervalArray[1];
      
      if (filter === 'all time' || filter === '3m') {
        toDateUnixMs = getESTDate();
      }

      const response = await axios.get(`${config.backendUrl}/api/markets/stock/datapoints`, {
        params: {
          ticker,
          fromDateUnixMs,
          toDateUnixMs,
          multiplier,
          timespan
        }
      });
      const data = response.data || [];
      setStockData(data);

      if (data.length > 0) {
        const latestPrice = rc(livePrice || currentPrice || data[data.length - 1].c);
        if (referencePrice) {
          const change = latestPrice - referencePrice;
          const changePercentage = (change / referencePrice) * 100;
          if (filter === '1d') { 
            let priceChange =
            ((dailyChangeData?.todaysClosePrice ? rc(dailyChangeData.todaysClosePrice ): rc(livePrice ||  currentPrice))
            - rc(dailyChangeData?.prevClosePrice));
            setChangeAmount(priceChange);
            setChangePercent(dailyChangeData.todaysPercentChange);
          } else {
            setChangeAmount(change);
            setChangePercent(changePercentage);
          }
        } else {
          const startPrice = rc(data[0].c);
          const change = latestPrice - startPrice;
          const changePercentage = (change / startPrice) * 100;
          setChangeAmount(change);
          setChangePercent(changePercentage);
        }

        const highPrices = data.map(day => day.h);
        const highestPrice = Math.max(...highPrices);
        const lowPrices = data.map(day => day.l);
        setPeriodHigh(highestPrice);
        setPeriodLow(Math.min(...lowPrices));

        setReferencePriceForChart((referencePrice || data[0].c));
      } else {
        setChangeAmount(null);
        setChangePercent(null);
        setPeriodHigh(null);
        setPeriodLow(null);
      }
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
    setMainStockDataFetched(true);
  };

  useEffect(()=> {
    getDailyChange(ticker, setCurrentPrice, setDailyChangeData);
    // eslint-disable-next-line
  }, [ticker]);

  useEffect(() => {
    if (marketStatus) {
      getLastValidTradingDay(marketStatus)
        .then((tradingDay) => {
          setLastValidTradingDay(tradingDay);
        })
        .catch((error) => {
          console.error("Error fetching the last valid trading day:", error);
        });
    }
  }, [marketStatus]);

  const setCompanyStates = (companyInfo) => { 
    setDateIPO(companyInfo.listDate);
    setStockName(companyInfo.name || ticker);
    setCompanyDetails({
      marketCap: companyInfo.marketCap,
    });
    if (companyInfo.description === 'No description available.' || !companyInfo.description) {
      return;
    }
    setCompanyDescription(companyInfo.description || '');
  };

  useEffect(() => {
    fetchCompanyDetails(ticker, setCompanyStates, setCompanyDetailsFetched);
  }, [ticker]);

  useEffect(() => {
    if (!dailyChangeData || !filter ) {
      return;
    }
    fetchStockData();
    // eslint-disable-next-line
  }, [filter, dailyChangeData]);

  useEffect(() => {
    if (!mainStockDataFetched) {
      return;
    }
    fetchDividendYield(ticker, currentPrice, setDividendYield);
    fetchEPSAndPERatio(ticker, setEps, setPERatio);
  }, [ticker, mainStockDataFetched, currentPrice]);

  const buyOrSellAssetCallback = () => {
    setIsCongrats(true);
    fetchUserPortfolio(isAuthenticated, userToken, setUserPortfolio, selectedPortfolio._id);
    fetchAllTransactions(isAuthenticated, userToken, ticker, setAllTransactions, selectedPortfolio._id);
  };

  useEffect(() => {
    if (lastValidTradingDay) {
      fetchDailyOpenClose(lastValidTradingDay, ticker, setDailyData);
    }
  }, [lastValidTradingDay, ticker]);

  useEffect(() => {
    if (!chartRef?.current) return;
    const cleanup = createStockChart({
      chartRef,
      chartInstanceRef: chartInstance,
      stockData,
      referencePriceForChart,
      changeAmount,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    });
    return cleanup;
  }, [stockData, referencePriceForChart, changeAmount, chartRef, theme, openBuyModal, openSellModal, openTradeOptionsModal]);

  // Fetching all portfolios if authenticated
  useEffect(() => {
    fetchAllPortfolios(isAuthenticated, userToken, setAllPortfolios, setSelectedPortfolio);
  }, [isAuthenticated, userToken]);

  const renderConfirmationModal = () => {
    const isBuy = openBuyModal;
    const handleConfirm = async () => {
      try {
        let response;
        if (isBuy) {
          response =  await handleBuyAsset(
            isAuthenticated,
            userToken,
            quantity,
            ticker,
            selectedPortfolio._id,
            buyOrSellAssetCallback,
            location.state
          );
        } else {
          response = await handleSellAsset(
            isAuthenticated,
            userToken,
            quantity,
            ticker,
            selectedPortfolio._id,
            buyOrSellAssetCallback,
            location.state
          );
        }

        let transaction = response?.data?.transaction;
        if (transaction) {
          setTransactionId(transaction._id);
        }
      } catch (error) {
        console.error("Transaction failed:", error);
        alert(error.response?.data?.message || 'Transaction failed');
        setTransactionId(null);
      }
    };

    const totalCost = quantity * rc(livePrice || currentPrice);
    const remainingAmount = isBuy
      ? userPortfolio?.userCash - totalCost
      : (userTickerPosition?.quantity || 0) - quantity;

    return (
      <div className="stock-purchase-modal">
        <div className="stock-transaction-content">
        <h2 className="StockName bold">Confirm</h2>
        <div className="StockConfirmWrapper">
          <span>
            <span className="bold">{quantity}</span> {ticker} shares
          </span>
          <span>
            <span className="bold">${fc(rc(livePrice || currentPrice))}</span> share price
          </span>
        </div>
        <div className="StockConfirmWrapper" style={{ border: 'none' }}>
          <span>
            <span className="bold">${fc(totalCost)}</span> total
          </span>
          <span>
            <span className="bold">
              {isBuy
                ? <> ${fc(remainingAmount)} <span className='medium'> cash remaining </span></>
                :<> {fc(remainingAmount)}  <span className='medium'> shares remaining </span></>
              }
            </span>
          </span>
        </div>
        </div>

        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className={`CreatePostFeedButton ${!isCongrats && 'greenButton'}`}
          disabled={!quantity || parseFloat(quantity) <= 0 || isCongrats}
        >
          Confirm
        </button>
      </div>
    );
  };

  const currentPriceRef = useRef(null);
  useEffect(() => {
    if (optionsData.length > 0 && currentPriceRef.current) {
      currentPriceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [optionsData]); 

  const [lineWidth, setLineWidth] = useState(0);

  useEffect(() => {
    if (optionsData.length > 0 && currentPriceRef.current) {
      const currentPriceTd = currentPriceRef.current;
      const currentPriceWrapper = currentPriceTd.querySelector('.current-price-wrapper');
      if (currentPriceWrapper) {
        const tdWidth = currentPriceTd.offsetWidth;
        const wrapperWidth = currentPriceWrapper.offsetWidth;
        const calculatedWidth = (tdWidth - wrapperWidth) / 2;
        setLineWidth(calculatedWidth);
      }
    }
  }, [optionsData]);

  const renderOptionsModal = () => {  
    return (
      <>
        <div className="options-filters">
          <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
            <div className="option-type-container">
              <div className="se-toggle-container" style={{ padding: 0, height: '3rem', border: 'var(--border)' }}>
                <div
                  className={`se-toggle-option stock-toggle bold ${optionType === 'call' && 'active'}`}
                  onClick={() => handleOptionTypeChange('call')}
                >
                  Call
                </div>
                <div
                  className={`se-toggle-option stock-toggle bold ${optionType === 'put' && 'active'}`}
                  onClick={() => handleOptionTypeChange('put')}
                >
                  Put
                </div>
              </div>
            </div>
            <div className="option-type-container">
              <DatePicker
                selected={expirationDate}
                onChange={handleExpirationDateChange}
                minDate={earliestDate}
                onMonthChange={handleMonthChange}
                filterDate={(date) => availableDates.some(d => d.toDateString() === date.toDateString())}
                dropdownMode="select"
                customInput={<CustomDateInput pretext="Expiring" theme={tc()} />}
                dateFormat="MMMM d, yyyy"
                className="FeedSortButton"
              />
            </div>
          </div>
        </div>
  
        <div className="options-list">
          {loadingOptions ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height: 'var(--stock-chart-height)' }}>
              <LoadingSpinner />
            </div>
          ) : optionsData.length > 0 ? (
            <table className="options-table">
              <thead>
                <tr className="options-table-heading">
                  <th>Strike Price</th>
                  <th>Breakeven</th>
                  <th>To breakeven</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>Open</th>
                  <th>High</th>
                  <th>Low</th>
                  <th>Close</th>
                  <th>1d Change %</th>
                </tr>
              </thead>
              <tbody>
                {optionsData.map((option, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && currentPrice < option.strikePrice && currentPrice >= optionsData[index - 1].strikePrice && (
                     <tr className="current-price-row" ref={currentPriceRef} >
                     <td colSpan="10" className="current-price-td">
                     <div
                          className="current-price-wrapper"
                          style={{
                            "--line-width": `${lineWidth}px`
                          }}
                        >
                         <span className="current-price-text bold">Current share price: ${fc(rc(livePrice || currentPrice))}</span>
                       </div>
                     </td>
                   </tr>
                    )}
                    <tr style={{cursor: 'pointer'}} onClick={() => navigate(`/options/${option.optionTicker}`, {state: {underlyingTicker: ticker, companyName: stockName}})}>
                      <td className="bold">{option?.strikePrice !== undefined ? fc(option.strikePrice) : '--'}</td>
                      <td>{option.breakEvenPrice !== undefined ? fc(option.breakEvenPrice) : '--'}</td>
                      <td>
                        {option.changeToBreakEven !== undefined
                          ? option.changeToBreakEven > 0
                            ? `+${option.changeToBreakEven} (${option.changeToBreakEven > 0 ? '+' : ''}${fc(
                                calculatePercentageChange(
                                  rc(livePrice || currentPrice),
                                  option.breakEvenPrice
                                )
                              )}%)`
                            : `${option.changeToBreakEven} (${fc(
                                calculatePercentageChange(
                                  rc(livePrice || currentPrice),
                                  option.breakEvenPrice
                                )
                              )}%)`
                          : '--'}
                      </td>
                      <td>{option.bid !== undefined ? fc(option.bid) : '--'}</td>
                      <td>{option.ask !== undefined ? fc(option.ask) : '--'}</td>
                      <td>{option.dailyChangeData?.open !== undefined ? fc(option.dailyChangeData.open) : '--'}</td>
                      <td>{option.dailyChangeData?.high !== undefined ? fc(option.dailyChangeData.high) : '--'}</td>
                      <td>{option.dailyChangeData?.low !== undefined ? fc(option.dailyChangeData.low) : '--'}</td>
                      <td>{option.dailyChangeData?.close !== undefined ? fc(option.dailyChangeData.close) : '--'}</td>
                      <td
                        className='bold'
                        style={{
                          color: option.dailyChangeData?.change_percent !== undefined
                            ? getChangePercentageColor(option.dailyChangeData?.change_percent, tc())
                            : 'inherit'
                        }}
                      >
                        {option.dailyChangeData?.change_percent !== undefined
                          ? `${option.dailyChangeData.change_percent >= 0 ? '+' : ''}${fc(option.dailyChangeData.change_percent)}%`
                          : '--'}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <p style={{ fontSize: 'var(--font-med-l)' }}>No options available for this date.</p>
          )}
        </div>
      </>
    );
  };

  const renderBuyOrSellModal = () => {
    const handleQuantityChange = (event) => {
      setIsConfirming(false);
      setIsCongrats(false);
      const value = event.target.value;
      const newQuantity = parseFloat(value);

      if (newQuantity < 0) {
        setQuantity(null);
        setEstimatedCost(null);
        return;
      }

      if (isNaN(newQuantity) || value === '') {
        setQuantity(null);
        setEstimatedCost(null);
      } else {
        setQuantity(newQuantity);
        const price = rc(livePrice || currentPrice);
        const newEstimatedCost = newQuantity * price;
        setEstimatedCost(newEstimatedCost);
      }
    };

    const isBuy = openBuyModal;
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Shares available: ${fc(userTickerPosition?.quantity) || 0}`;
    let buttonLabel = isBuy ? 'Buy' : 'Sell';
    let originalButtonLabel = buttonLabel;
    buttonLabel = isConfirming ? 'Edit' : buttonLabel;

    const handleConfirmClick = () => {  
      if (isConfirming) {
        setIsCongrats(false);
        document.getElementById('share-input').focus();
      }
      setIsConfirming((prev) => !prev);
    };

    return (
      <div style = {{display: 'flex', flexDirection: 'row', gap: '2rem', alignItems: 'center'}}>
        <div className="stock-purchase-modal">
          <div className='stock-transaction-content'>
            <h2 className="StockName bold">{originalButtonLabel}</h2>
            <p className="stock-cash-available">{availableText}</p>
            <div className="stock-input-section">
              <label htmlFor="shares" className="StockName bold">Shares</label>
              <input
                type="number"
                id="share-input"
                className="StockName StockInput bold"
                value={quantity !== null ? quantity : ''}
                onChange={handleQuantityChange}
                inputMode="decimal"
                step="any"
                min="0"
                placeholder="Enter shares"
              />
            </div>
            <div className="stock-input-section" style={{ borderBottom: 'none' }}>
              <label className="StockName bold">Total</label>
              <input
                type="text"
                className="StockName StockInput bold"
                value={
                  estimatedCost !== null ? `$${fc(estimatedCost)}` : '$0.00'
                }
                readOnly
              />
            </div>
          </div>
          <button
            onClick={handleConfirmClick}
            style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
            className={`CreatePostFeedButton ${!isConfirming && 'greenButton'}`}
            disabled={!quantity || parseFloat(quantity) <= 0}
          >
            {buttonLabel}
          </button>
        </div>
        {isConfirming && (
          <>
            <div>
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
            </div>
            {renderConfirmationModal()}
          </>
        )}
        {isCongrats && (
          <>
            <div>
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }} />
            </div>
            {renderCongratsModal()}
          </>
        )}
      </div>
    );
  };

  const renderCongratsModal = () => {
    return (
      <div className="stock-purchase-modal" style = {{borderColor: 'var(--stock-change-pos)'}}>
        <div className="stock-transaction-content">
          <h2 style={{color: 'var(--stock-change-pos)'}} className="StockName bold">Congrats!</h2>
          <div className="StockConfirmWrapper" style = {{border: 'none'}}>
            <span>Do you want to share this trade to your feed?</span>
          </div>
        </div>
        <div className='stock-congrats-btns'>
          <button onClick={()=> handleCloseModal()} className="CreatePostFeedButton">No</button>
          <button onClick={()=> {shareTrade(transactionId, navigate)}} className="CreatePostFeedButton greenButton">Yes</button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!referencePriceForChart || !chartRef.current) {
      return;
    }

    const ws = new WebSocket(config.socketUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      ws.send(JSON.stringify({ action: 'subscribe', ticker }));
    };

    ws.onmessage = (event) => {
      let message;
      try {
        message = JSON.parse(event.data);
      } catch (e) {
        console.error('Error parsing WebSocket message:', e);
        return;
      }

      if (message.type === 'instantPrice') {
        const data = message.data[0];
        if (data && data.ev === 'T' && data.sym === ticker.toUpperCase()) {
          const tradePrice = data.p;
          updateChanges(tradePrice);
        }

        const dataBidAsk = message.data[1];
        if (dataBidAsk && dataBidAsk.ev === 'Q' && dataBidAsk.sym === ticker.toUpperCase()) {
          const bidPrice = dataBidAsk.bp;
          const askPrice = dataBidAsk.ap;
          setBidAsk({ bid: bidPrice, ask: askPrice });
        }

      } else {
        let data = message[0];  
        if (data && data.ev === 'T' && data.sym === ticker.toUpperCase()) {
          const tradePrice = data.p;
          const tradeTime = data.t;
          if (!tradePrice || !tradeTime) {
            console.warn('Invalid trade data:', data);
            return;
          }

          const newPoint = {
            t: tradeTime,
            c: parseFloat(tradePrice),
          };
          
          if (chartInstance.current) {
            const maxDataPoints = 287; 
      
            if (chartInstance.current.data.labels.length >= maxDataPoints && filter === 'live') {
              chartInstance.current.data.labels.shift();
              chartInstance.current.data.datasets[0].data.shift();
            }
      
            chartInstance.current.data.labels.push(
              new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              }).format(new Date(newPoint.t))
            );
      
            chartInstance.current.data.datasets[0].data.push(newPoint.c);
      
            const change = newPoint.c - referencePriceForChart;
            const borderColor = getChangePercentageColor(change, tc());
            const pointBackgroundColor = borderColor;
      
            chartInstance.current.data.datasets[0].borderColor = borderColor;
            chartInstance.current.data.datasets[0].pointBackgroundColor = pointBackgroundColor;
      
            if (chartRef.current && chartInstance.current.data.datasets[0].data.length > 0) {
              try {
                chartInstance.current.update({
                  duration: 100,
                  lazy: true,
                  easing: 'easeInOutBounce',
                });
              } catch (updateError) {
                console.error('Chart update failed:', updateError);
              }
            }
          }
        }
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current = ws;

    return () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ action: 'unsubscribe', ticker }));
        wsRef.current.close();
      }
    };
    //eslint-disable-next-line
  }, [ticker, filter, dailyChangeData, referencePriceForChart, liveChangeAmount]);

  const renderStockNews = () => {
    if (stockNews.length === 0) {
      return <p>No news available</p>;
    }

    return stockNews.map((news, index) => (
      <div key={index} className="StockNewsItem">
        <a style={{ textDecoration: 'none' }} href={news.article_url} target="_blank" rel="noopener noreferrer">
          <img src={news.image_url} alt={news.title} className="StockNewsImage" />
          <h2 className="StockNewsTitle">{news.title}</h2>
        </a>
        <div className="StockNewsMeta">
          <span className="StockNewsAuthor">{news.publisher.name}</span>
          <span className="StockNewsDate">{new Date(news.published_utc).toLocaleDateString()}</span>
        </div>
      </div>
    ));
  };


  const renderPortfolioDropdown = () => {
    const portfolioOptions = allPortfolios.map((pf) => ({ value: pf._id, label: pf.name }));
    const selectedPortfolioOption = selectedPortfolio ? { value: selectedPortfolio._id, label: selectedPortfolio.name } : null;

    return (
      isAuthenticated &&(
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
          <Select
            unstyled
            isSearchable={false}
            options={portfolioOptions}
            value={selectedPortfolioOption}
            onChange={(option) => {
              const newPortfolio = allPortfolios.find((pf) => pf._id === option.value);
              setSelectedPortfolio(newPortfolio);
            }}
            placeholder="Select portfolio"
            styles={customStylesPlus(tc())}
          />
        </div>
      )
    );
  };
  return (
    <div id="stock-container-wrapper" className='StockContainerWrapper'>
      <div className="StockContainer">
        <MarketSearchBar
          stock={stock}
          setStock={setStock}
          isAuthenticated={isAuthenticated}
          userToken={userToken}
          navigate={navigate}
        />
        <div className="MainStockContainer">
          <div className="StockPriceContent">
            <div className="StockGraph">
              <div className="StockHeader">
                <div className="StockInfo">
                  <BackButton callback={()=> {window.history.back()}} fontSize='var(--font-med-l)' padding='0 0 1.25rem 0'/>
                  <h2 className="StockTickerHeader bold">{ticker}</h2>
                  <h1 className="StockName bold">{stockName}</h1>
                  <p className="StockPrice bold">
                  {isHovering
                    ? hoveredPrice !== null
                      ? `$${fc(hoveredPrice)}`
                      : '--'
                    : livePrice || currentPrice
                    ? `$${fc(rc(livePrice || currentPrice))}`
                    : '--'}
                </p>
                  <p
                    className={`StockChange bold ${
                      isHovering
                        ? hoveredChangeAmount >= 0
                          ? 'positive'
                          : 'negative'
                        : (liveChangeAmount || changeAmount) >= 0
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    {isHovering ? (
                      <>
                        {hoveredChangeAmount >= 0.00 ? '+' : ''}
                        {fc(hoveredChangeAmount)} ({fc(hoveredChangePercent)}%)
                      </>
                    ) : (liveChangeAmount || changeAmount) !== null ? (
                      <>
                        {(liveChangeAmount || changeAmount) >= 0.00 ? '+' : ''}
                        {fc(liveChangeAmount || changeAmount)} ({fc(liveChangePercent || changePercent)}%)
                      </>
                    ) : (
                      <span style={{ color: 'var(--action-grey)' }}>--</span>
                    )}{' '}
                    <span className="StockDuration">
                      {isHovering
                        ? hoveredDateTime
                        : filters[filter] === 'all time'
                          ? (
                            <>
                              {filters[filter]} {isOldCompany(dateIPO) && '(since 8/30/2003)'}
                            </>
                          )
                          : filters[filter] === 'live' ? 'live' : `past ${filters[filter]}`
                      }
                    </span>
                  </p>
                  {!isHovering && renderAfterMarketChange(marketStatus, dailyChangeData, filter, livePrice || currentPrice)}
                  { (!openBuyModal && !openSellModal && !openTradeOptionsModal) ?
                    <div className="StockActions">
                      <button onClick={handleBuyClick} className="CreatePostFeedButton greenButton">Buy</button> 
                      <button onClick={handleSellClick} className="CreatePostFeedButton">Sell</button> 
                      <button onClick={handleTradeOptionsClick} className="CreatePostFeedButton">Trade Options</button>
                      <Select
                        options={listOptions}
                        value={selectedLists}
                        placeholder="Add to list +"
                        isClearable={false}
                        isSearchable={false}
                        unstyled
                        isMulti={true}
                        styles={customStylesPlus(tc())}
                        onMenuOpen={() => {
                          if (!isAuthenticated) {
                            alert('Please sign in to add stocks to lists.');
                            return;
                          }
                          fetchStockLists(isAuthenticated, userToken, setListOptions, setSelectedLists, ticker);
                        }}
                        onChange={(selectedOptions) => {
                          // Check if a list was removed
                          if (selectedOptions.length < selectedLists.length) {
                            const removedList = selectedLists.find(
                              (selected) => !selectedOptions.some((option) => option.value === selected.value)
                            );
                            if (removedList) {
                              handleRemoveFromList(
                                isAuthenticated,
                                userToken,
                                ticker,
                                removedList.value,
                                removedList.label,
                                setListOptions,
                                setSelectedLists
                              );
                              setSelectedLists(selectedOptions);
                            }
                          } else if (selectedOptions.length > selectedLists.length) {
                            const addedList = selectedOptions.find(
                              (option) => !selectedLists.some((selected) => selected.value === option.value)
                            );
                            if (addedList) {
                              handleAddToList(
                                isAuthenticated,
                                userToken,
                                ticker,
                                addedList.value,
                                addedList.label,
                                setListOptions,
                                setSelectedLists
                              );
                              setSelectedLists(selectedOptions);
                            }
                          }
                        }}
                      />
                      {renderPortfolioDropdown()} 
                    </div>
                  :
                  <div className="StockActions">
                    <button onClick= {handleCloseModal} className="CreatePostFeedButton">Cancel Order</button> 
                  </div>
                  }
                </div>
              </div>
              <div className="StockGraphMain">
                {!mainStockDataFetched ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height: 'var(--stock-chart-height)' }}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  !openBuyModal && !openSellModal && !openTradeOptionsModal ?
                    <div style={{ height: 'var(--stock-chart-height)'}}>
                      <canvas id="stock-chart" ref={chartRef}></canvas>
                    </div>
                  :
                  openBuyModal || openSellModal ?
                    renderBuyOrSellModal()
                  : openTradeOptionsModal &&
                    renderOptionsModal()
                )}
                {!openBuyModal && !openSellModal && (
                  <div className="StockFilters">
                    {Object.keys(filters).map((key) => (
                      <button key={key} className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`} onClick={() => setFilter(key)}>
                        {key}
                      </button>
                    ))}
                  </div>
                )}
                
                {allTransactions?.length > 0 && (
                  <div className="StockStatistics">
                    <h2 className="StatsHeader">{`Your Position (${selectedPortfolio?.name})`}</h2>
                    
                    <div className="StatsGrid">
                      <div className="label-value-pair">
                        <span>Shares</span>
                        <strong>{fc(userTickerPosition?.quantity)}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Market Value</span>
                        <strong>{fc((userTickerPosition?.quantity || 0) * rc(livePrice || currentPrice))}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Portfolio Allocation</span>
                        <strong>
                          {fc(userPortfolio?.totalValue && userPortfolio?.totalValue !== 0
                            ? (((userTickerPosition?.quantity || 0) * userTickerPosition?.avgBuyPrice) / userPortfolio.totalValue) * 100
                            : 0.0)}%
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Average Cost</span>
                        <strong>{fc(userTickerPosition?.avgBuyPrice)}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Unrealized Return</span>
                        <strong
                          className={
                            returnData?.unrealizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.unrealizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.unrealizedDollar)} ({fc(returnData.unrealizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Realized Return</span>
                        <strong
                          className={
                            returnData?.realizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.realizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.realizedDollar)} ({fc(returnData.realizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}

                {companyDescription && <div className="StockDescription">
                  <h2 className="StockDescriptionHeader">Summary</h2>
                  <p style={{margin:0}}>{companyDescription}</p>
                </div>}
                <div className="StockStatistics">
                  <h2 className="StatsHeader">Stats</h2>
                  <div className="StatsGrid">
                    <div className="label-value-pair">
                      <span>Open</span>
                      <strong>{fc(dailyData?.open) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Close</span>
                      <strong>{fc(dailyData?.close) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>High</span>
                      <strong>{fc(dailyData?.high) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Low</span>
                      <strong>{fc(dailyData?.low) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Pre-Market</span>
                      <strong>{fc(dailyData?.preMarket) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>After-Hours</span>
                      <strong>{fc(dailyData?.afterHours) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Volume</span>
                      <strong>{dailyData?.volume ? formatNumber(dailyData.volume) : '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>P/E Ratio</span>
                      <strong>{fc(peRatio) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span style={{ textTransform: 'capitalize' }}>{filterHighMap[filter]} High</span>
                      <strong>{periodHigh ? fc(periodHigh) : '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span style={{ textTransform: 'capitalize' }}>{filterHighMap[filter]} Low</span>
                      <strong>{periodLow ? fc(periodLow) : '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>EPS (TTM)</span>
                      <strong>{fc(eps) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Dividend Yield %</span>
                      <strong>{dividendYield}{dividendYield !== 'N/A' ? '%' : ''}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Market Cap</span>
                      <strong>{companyDetails?.marketCap ? formatNumber(companyDetails.marketCap) : '--'}</strong>
                    </div>
                  </div>
                </div>
                {stockNews?.length > 0 && <div className="StockNews">
                  <h2 className='StockNewsHeader'> News</h2>
                  <div className="StockNewsList">
                    {renderStockNews()}
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



import React, { useContext, useEffect, useRef, useState } from 'react';
import '../styles/MrVestr.css';
import { AuthContext } from '../context/AuthContext';
import { LoadingSpinner } from '../helper/StylesHelper';
import useResponsiveWidth from '../hooks/useResponsiveWidth';

export default function MrVestr() {
  useResponsiveWidth();
  const { isAuthenticated } = useContext(AuthContext);
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true); 

  const handleAccess = () => {
    if (!isAuthenticated) {
      alert('Please sign in to access Mr. Vestr.');
    }
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    const onIframeLoad = () => {
      handleAccess();
      setIsLoading(false); 
    };

    if (iframe) {
      iframe.addEventListener('load', onIframeLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', onIframeLoad);
      }
    };
  //eslint-disable-next-line
  }, []);

  return (
    <div className="mv-mr-vestr" onClick={handleAccess}>
      {isLoading && (
        <LoadingSpinner/>
      )}
      <iframe
        title='mr-vestr'
        ref={iframeRef}
        className={`mr-vestr-frame ${!isAuthenticated ? 'blurred' : ''}`}
        id="finchat"
        name="finchat"
        src="https://enterprise.finchat.io/demo/17f7604f02154229a1553a57c10a0341"
        allowFullScreen
        style={{ display: isLoading ? 'none' : 'block' }} // Hide iframe while loading
      />
    </div>
  );
}

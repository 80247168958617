import React, {useState} from 'react'
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext';
import retweet from '../../assets/retweet.svg';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { handleRepost, handleUndoRepost } from '../../helper/PostHelper';

export default function Repost({   
    postId,
    profilePic,
    author,
    verified,
    username,
    content,
    title,
    time,
    sentiment,
    type,
    categories,
    images,
    repostCount,
    setRepostCount,
    isCopyReposted,
    setIsCopyReposted,
    taggedAssets,
    transactionId,
    
}) {
    const {isAuthenticated, userToken} = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { dk } = useContext(ThemeContext); 

    const closePopover = () => {
        setAnchorEl(null);
      };
      
    const handleRepostClick = (event) => {
        if (!isAuthenticated) {
          alert('Please sign in to repost posts');
          return;
        }
        setAnchorEl(event.currentTarget);
      };

      const handleQuotePost = () => {
        setAnchorEl(null);
        navigate('/createpost', {
          state: {
            quotePostDetails: {
              postId,
              profilePic,
              author,
              verified,
              username,
              content,
              title,
              timePosted: time,
              sentiment,
              type,
              categories,
              images,
              taggedAssets,
              transactionId,
            },
            isQuotePost: true,
          },
        });
      };
  return (
    <>
    <div className="ActionItem" onClick={handleRepostClick} style={{ gap: '0.5rem' }}>
    <object aria-label="reposts" type="image/svg+xml" data={retweet} alt="Repost" className="ActionIcon" /> {repostCount}
  </div>

         {/* Repost Popover */}
         <Popover
         open={Boolean(anchorEl)}
         anchorEl={anchorEl}
         onClose={closePopover}
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'center',
         }}
         transformOrigin={{
           vertical: 'top',
           horizontal: 'center',
         }}
         slotProps={{
          paper: {
              sx: {
                  backgroundColor: dk() ? '#353A36' : '#FFFFFF', 
              },
          },
      }}
       >

         {
           isCopyReposted ? (
             <Button style = {{color: 'var(--text-color)'}} onClick={()=> handleUndoRepost(postId, userToken, setIsCopyReposted, setRepostCount )}>Undo Repost</Button>
           ) :
           (
             <Button style = {{color: 'var(--text-color)'}} onClick={() => handleRepost(postId, userToken, setIsCopyReposted,  setRepostCount, setAnchorEl) }>Repost</Button>
           )
         }
         <Button style = {{color: 'var(--text-color)'}} onClick={handleQuotePost}>Quote Post</Button>
       </Popover>
       </>
  )
}

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { createStockChart } from '../helper/StockChartHelper';
import {
  getUnixTimestamp,
  formatNumber,
  getUnixTimestampRange,
  filters,
  getThreeMonthsAgo,
  fetchDailyOpenClose,
  getDailyChange,
  fetchStockLists,
  filterHighMap,
  getLastValidTradingDay,
  portfolioIntervalMap,
  fetchUserPortfolio,
  handleAddToWatchlist,
  handleRemoveFromWatchlist,
  handleRemoveFromList,
  handleAddToList,
  getWatchList,
  getStartOfTradingDay,
  getStartOfTradingWeek,
  getESTDate,
  fetchAllTransactions,
  calculateReturns,
  handleBuyOption,
  handleSellOption,
  fetchAllPortfolios, // We'll create a helper for fetching all portfolios
} from '../helper/MarketsHelper';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import config from '../config';
import {
  LoadingSpinner,
  formatOptionNameFromTicker,
  removeCommas as rc,
  formatCurrency as fc,
} from '../helper/StylesHelper';
import MarketSearchBar from './MarketSearchBar';
import BackButton from './BackButton';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
import { shareTrade } from '../helper/CreatePostHelper';
import Select from 'react-select';
import { customStylesPlus } from '../helper/StylesHelper';

export default function Option() {
  useResponsiveWidth();
  const { optionTicker } = useParams();
  const location = useLocation();
  const underlyingTicker = location.state?.underlyingTicker || null;
  const { isAuthenticated, userToken, username} = useContext(AuthContext);
  const { theme, tc } = useContext(ThemeContext);
  const [optionData, setOptionData] = useState([]);
  const [dailyChangeData, setDailyChangeData] = useState(null);
  const [dailyData, setDailyData] = useState({});
  const [filter, setFilter] = useState('1d');
  const [currentPrice, setCurrentPrice] = useState(null);
  const [changeAmount, setChangeAmount] = useState(null);
  const [changePercent, setChangePercent] = useState(null);
  const [optionName, setOptionName] = useState(optionTicker); 
  const [mainOptionDataFetched, setMainOptionDataFetched] = useState(false);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [referencePriceForChart, setReferencePriceForChart] = useState(null);
  const [periodHigh, setPeriodHigh] = useState(null);
  const [periodLow, setPeriodLow] = useState(null);
  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
  const [hoveredDateTime, setHoveredDateTime] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  const [allTransactions, setAllTransactions] = useState([]);
  const [userTickerPosition, setUserTickerPosition] = useState(null);
  const [returnData, setReturnData] = useState(null);
  const [userPortfolio, setUserPortfolio] = useState(null);

  const [listOptions, setListOptions] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);

  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [openSellModal, setOpenSellModal] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [estimatedCost, setEstimatedCost] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isCongrats, setIsCongrats] = useState(false);
  const [stock, setStock] = useState('');
  const [transactionId, setTransactionId] = useState(null);

  const navigate = useNavigate();

  const [isInWatchlist, setIsInWatchlist] = useState(false);

  // For handling multiple portfolios
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      getWatchList(optionTicker, userToken, setIsInWatchlist);
    }
  }, [isAuthenticated, userToken, optionTicker]);

  useEffect(() => {
    if (isAuthenticated && userToken) {
      fetchAllPortfolios(isAuthenticated, userToken, setAllPortfolios, setSelectedPortfolio);
    }
  }, [isAuthenticated, userToken]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio._id) return;
    fetchUserPortfolio(isAuthenticated, userToken, setUserPortfolio, selectedPortfolio._id);
    fetchAllTransactions(isAuthenticated, userToken, optionTicker, setAllTransactions, selectedPortfolio._id);
  }, [selectedPortfolio?._id, userToken, isAuthenticated, optionTicker]);

  useEffect(() => {
    if (userPortfolio && optionTicker) {
      setUserTickerPosition(
        userPortfolio?.assets.find(
          (stock) => stock?.ticker?.toUpperCase() === optionTicker?.toUpperCase()
        )
      );
    }
  }, [userPortfolio, optionTicker]);

  useEffect(() => {
    if (currentPrice && userTickerPosition && allTransactions) {
      let returns = calculateReturns(
        userTickerPosition,
        rc(currentPrice) * 100,
        allTransactions
      );
      setReturnData(returns);
    }
  }, [currentPrice, userTickerPosition, allTransactions]);

  const handleBuyClick = () => {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature');
      return;
    }
    setOpenBuyModal(true);
  };

  const handleSellClick = () => {
    if (!isAuthenticated) {
      alert('Please sign in to access this feature');
      return;
    }
    setOpenSellModal(true);
  };

  const handleCloseModal = () => {
    setOpenBuyModal(false);
    setOpenSellModal(false);
    setIsConfirming(false);
    setIsCongrats(false);
    setTransactionId(null);
  };

  useEffect(() => {
    setOptionName(formatOptionNameFromTicker(optionTicker));
  }, [optionTicker]);

  const fetchOptionData = async () => {
    setChangeAmount(null);
    setPeriodHigh(null);
    setPeriodLow(null);
    setMainOptionDataFetched(false);
    setReferencePriceForChart(null);
    try {
      let fromDateUnixMs;
      let multiplier, timespan;
      let referencePrice;
      let toDateUnixMs = getUnixTimestamp();

      if (filter === '3m') {
        fromDateUnixMs = getThreeMonthsAgo();
      } else if (filter === '1d') {
        const dateInET = getStartOfTradingDay();
        fromDateUnixMs = dateInET.toMillis();
        toDateUnixMs = fromDateUnixMs + 57600000; 
        referencePrice = rc(dailyChangeData?.prevClosePrice); 
      } else if (filter === '1w') {
        const dateInET = getStartOfTradingWeek();
        fromDateUnixMs = dateInET.toMillis();
        toDateUnixMs = fromDateUnixMs + 403200000; 
      } else if (filter === 'all time') {
        fromDateUnixMs = getESTDate();
      } else {
        fromDateUnixMs = getUnixTimestampRange(filter);
      }

      const intervalArray = portfolioIntervalMap[filter];
      multiplier = intervalArray[0];
      timespan = intervalArray[1];

      const response = await axios.get(
        `${config.backendUrl}/api/markets/option/datapoints`,
        {
          params: {
            optionTicker,
            fromDateUnixMs,
            toDateUnixMs,
            multiplier,
            timespan,
          },
        }
      );
      const data = response.data || [];
      setOptionData(data);
      if (data.length > 0) {
        const latestPrice = rc(currentPrice || data[data.length - 1].c);

        if (filter === '1d' && dailyChangeData?.prevClosePrice) {
          const dailyClosePrice = rc(dailyChangeData?.todaysClosePrice || latestPrice);
          const priceChange = dailyClosePrice - rc(dailyChangeData?.prevClosePrice);
          const changePercentage =
            (priceChange / rc(dailyChangeData?.prevClosePrice)) * 100;

          setChangeAmount(priceChange);
          setChangePercent(changePercentage);
          setReferencePriceForChart(rc(dailyChangeData?.prevClosePrice));
        } else {
          const startPrice = rc(data[0].c);
          const change = latestPrice - startPrice;
          const changePercentage = (change / startPrice) * 100;

          setChangeAmount(change);
          setChangePercent(changePercentage);
          setReferencePriceForChart(startPrice);
        }

        const highPrices = data.map((day) => day.h);
        const lowPrices = data.map((day) => day.l);
        const highestPrice = Math.max(...highPrices);
        const lowestPrice = Math.min(...lowPrices);
        setPeriodHigh(highestPrice);
        setPeriodLow(lowestPrice);
      } else {
        setChangeAmount(null);
        setChangePercent(null);
        setPeriodHigh(null);
        setPeriodLow(null);
      }
    } catch (error) {
      console.error('Error fetching option data:', error);
    }
    setMainOptionDataFetched(true);
  };

  useEffect(() => {
    getDailyChange(optionTicker, setCurrentPrice, setDailyChangeData, underlyingTicker);
  }, [optionTicker, underlyingTicker]);

  useEffect(() => {
    const fetchOptionDailyData = async () => {
      try {
        const lastValidDay = await getLastValidTradingDay();
        fetchDailyOpenClose(lastValidDay, optionTicker, setDailyData);
      } catch (error) {
        console.error(
          'Error fetching last valid trading day or daily open/close data:',
          error
        );
      }
    };
    fetchOptionDailyData();
  }, [optionTicker]);

  useEffect(() => {
    if (!dailyChangeData || !filter) {
      return;
    }
    fetchOptionData();
    // eslint-disable-next-line
  }, [filter, dailyChangeData]);

  useEffect(() => {
    if (!chartRef?.current) return;
    const cleanup = createStockChart({
      chartRef,
      chartInstanceRef: chartInstance,
      stockData: optionData,
      referencePriceForChart,
      changeAmount,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    });
    return cleanup;
  }, [optionData, referencePriceForChart, changeAmount, theme]);

  useEffect(() => {
    if (userToken) {
      fetchStockLists(
        isAuthenticated,
        userToken,
        setListOptions,
        setSelectedLists,
        optionTicker
      );
    }
    //eslint-disable-next-line
  }, [userToken, optionTicker]);

  const buyOrSellAssetCallback = () => {
    setIsCongrats(true);
    if (selectedPortfolio && selectedPortfolio._id) {
      fetchUserPortfolio(isAuthenticated, userToken, setUserPortfolio, selectedPortfolio._id);
      fetchAllTransactions(isAuthenticated, userToken, optionTicker, setAllTransactions, selectedPortfolio._id);
    }
  };

  const renderConfirmationModal = () => {
    const isBuy = openBuyModal;

    const handleConfirm = async () => {
      try {
        if (!selectedPortfolio) {
          alert("Please select a portfolio first.");
          return;
        }

        let response;
        if (isBuy) {
          response = await handleBuyOption(
            isAuthenticated,
            userToken,
            quantity,
            optionTicker,
            buyOrSellAssetCallback,
            selectedPortfolio._id
          );
        } else {
          response = await handleSellOption(
            isAuthenticated,
            userToken,
            quantity,
            optionTicker,
            buyOrSellAssetCallback,
            selectedPortfolio._id
          );
        }
        let transaction = response?.data?.transaction;
        if (transaction) {
          setTransactionId(transaction._id);
        }
      } catch (error) {
        console.error('Transaction failed:', error);
        setTransactionId(null);
        alert(error.response?.data?.message || 'Transaction failed');
      }
    };

    const totalCost = quantity * rc(currentPrice) * 100;
    const remainingAmount = isBuy
      ? userPortfolio?.userCash - totalCost
      : userTickerPosition?.quantity - quantity;

    return (
      <div className="stock-purchase-modal stock-p-modal-large">
        <div className="stock-transaction-content">
          <h2 className="StockName bold">Confirm</h2>
          <div className="StockConfirmWrapper">
            <span>
              <span className="bold">{quantity}</span> {optionTicker} contracts
            </span>
            <span>
              <span>
                <span className="bold">${fc(rc(currentPrice))} </span>
                option price x <span className="bold">{quantity} </span>
                contracts x <span className="bold">100</span>{' '}
              </span>
            </span>
          </div>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>
              <span className="bold">${fc(totalCost)}</span> total
            </span>
            <span>
              <span className="bold">
                {isBuy ? (
                  <>
                    {' '}
                    ${fc(remainingAmount)} <span className="medium"> cash remaining </span>
                  </>
                ) : (
                  <>
                    {' '}
                    {fc(remainingAmount)} <span className="medium"> contracts remaining </span>
                  </>
                )}
              </span>
            </span>
          </div>
        </div>

        <button
          onClick={handleConfirm}
          style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
          className={`CreatePostFeedButton ${!isCongrats && 'greenButton'}`}
          disabled={!quantity || parseFloat(quantity) <= 0 || isCongrats}
        >
          Confirm
        </button>
      </div>
    );
  };

  const renderCongratsModal = () => {
    return (
      <div className="stock-purchase-modal" style={{ borderColor: 'var(--stock-change-pos)' }}>
        <div className="stock-transaction-content">
          <h2 style={{ color: 'var(--stock-change-pos)' }} className="StockName bold">
            Congrats!
          </h2>
          <div className="StockConfirmWrapper" style={{ border: 'none' }}>
            <span>Do you want to share this trade to your feed?</span>
          </div>
        </div>
        <div className="stock-congrats-btns">
          <button onClick={() => handleCloseModal()} className="CreatePostFeedButton">
            No
          </button>

          <button onClick={() => {shareTrade(transactionId, navigate)}} className="CreatePostFeedButton greenButton">
            Yes
          </button>
        </div>
      </div>
    );
  };

  const renderBuyOrSellModal = () => {
    const handleQuantityChange = (event) => {
      setIsConfirming(false);
      setIsCongrats(false);

      const value = event.target.value;
      const newQuantity = parseFloat(value);

      if (newQuantity < 0) {
        setQuantity(null);
        setEstimatedCost(null);
        return;
      }

      if (isNaN(newQuantity) || value === '') {
        setQuantity(null);
        setEstimatedCost(null);
      } else {
        setQuantity(newQuantity);
        const price = rc(currentPrice);
        const newEstimatedCost = newQuantity * price * 100;
        setEstimatedCost(newEstimatedCost);
      }
    };

    const isBuy = openBuyModal;
    const availableText = isBuy
      ? `Cash available: $${fc(userPortfolio?.userCash)}`
      : `Contracts available: ${fc(userTickerPosition?.quantity) || 0}`;
    let buttonLabel = isBuy ? 'Buy' : 'Sell';
    let originalButtonLabel = buttonLabel;
    buttonLabel = isConfirming ? 'Edit' : buttonLabel;

    const handleConfirmClick = () => {
      if (isConfirming) {
        setIsCongrats(false);
        document.getElementById('contract-input').focus();
      }
      setIsConfirming((prev) => !prev);
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', alignItems: 'center' }}>
        <div className="stock-purchase-modal stock-p-modal-large">
          <div className="stock-transaction-content">
            <h2 className="StockName bold">{originalButtonLabel}</h2>
            <p className="stock-cash-available">{availableText}</p>

            <div className="stock-input-section">
              <label htmlFor="contracts" className="StockName bold">
                Contracts
              </label>
              <input
                type="number"
                id="contract-input"
                className="StockName StockInput StockInputLarge bold"
                value={quantity !== null ? quantity : ''}
                onChange={handleQuantityChange}
                inputMode="decimal"
                step="any"
                min="0"
                placeholder="Enter contracts"
              />
            </div>

            <div className="stock-input-section" style={{ borderBottom: 'none' }}>
              <label className="StockName bold">Total</label>
              <input
                type="text"
                className="StockName StockInput StockInputLarge bold"
                value={estimatedCost !== null ? `$${fc(estimatedCost)}` : '$0.00'}
                readOnly
              />
            </div>
          </div>

          <button
            onClick={handleConfirmClick}
            style={{ width: '33%', alignSelf: 'flex-end', marginTop: '1rem' }}
            className={`CreatePostFeedButton ${!isConfirming && 'greenButton'}`}
            disabled={!quantity || parseFloat(quantity) <= 0}
          >
            {buttonLabel}
          </button>
        </div>
        {isConfirming && (
          <>
            <div>
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem' }} />
            </div>

            {renderConfirmationModal()}
          </>
        )}

        {isCongrats && (
          <>
            <div>
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '5rem', color: 'var(--stock-change-pos)' }} />
            </div>

            {renderCongratsModal()}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    console.log('Selected portfolio:', selectedPortfolio);

  }, [selectedPortfolio]);
  const renderPortfolioDropdown = () => {
    const portfolioOptions = allPortfolios.map((pf) => ({ value: pf._id, label: pf.name }));
    const selectedPortfolioOption = selectedPortfolio ? { value: selectedPortfolio._id, label: selectedPortfolio.name } : null;

    return (
      isAuthenticated &&(
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
          <Select
            unstyled
            isSearchable={false}
            options={portfolioOptions}
            value={selectedPortfolioOption}
            onChange={(option) => {
              const newPortfolio = allPortfolios.find((pf) => pf._id === option.value);
              setSelectedPortfolio(newPortfolio);
            }}
            placeholder="Select portfolio"
            styles={customStylesPlus(tc())}
          />
        </div>
      )
    );
  };
  return (
    <div className="StockContainerWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="StockContainer">
        <MarketSearchBar
          stock={stock}
          setStock={setStock}
          isAuthenticated={isAuthenticated}
          userToken={userToken}
          navigate={navigate}
        />

        <div className="MainStockContainer">
          <div className="StockPriceContent">
            <div className="StockGraph">
              <div className="StockHeader">
                <div className="StockInfo">
                  <BackButton
                    callback={() => {
                      window.history.back();
                    }}
                    fontSize="var(--font-med-l)"
                    padding="0 0 1.25rem 0"
                  />
                  <h2 className="StockTickerHeader bold">{optionTicker}</h2>
                  <h1 className="StockName bold">{optionName}</h1>
                  <p className="StockPrice bold">
                  {isHovering
                    ? hoveredPrice !== null
                      ? `$${fc(hoveredPrice)}`
                      : '--'
                    :  currentPrice
                    ? `$${fc(rc(currentPrice))}`
                    : '--'}
                </p>
                  <p
                    className={`StockChange bold ${
                      (isHovering ? fc(hoveredChangeAmount) : changeAmount) >= 0
                        ? 'positive'
                        : 'negative'
                    }`}
                  >
                    {isHovering ? (
                      <>
                        {hoveredChangeAmount >= 0 ? '+' : ''}
                        {fc(hoveredChangeAmount)} ({fc(hoveredChangePercent)}%)
                      </>
                    ) : changeAmount !== null ? (
                      <>
                        {changeAmount >= 0 ? '+' : ''}
                        {fc(changeAmount)} ({fc(changePercent)}%)
                      </>
                    ) : (
                      <span style={{ color: 'var(--action-grey)' }}>--</span>
                    )}
                    <span className="StockDuration">
                      {' '}
                      {isHovering
                        ? hoveredDateTime
                        : filters[filter] === 'all time'
                        ? 'all time'
                        : filters[filter] === 'live'
                        ? 'live'
                        : `past ${filters[filter]}`}
                    </span>
                  </p>

                  {!openBuyModal && !openSellModal ? (
                    <div className="StockActions">
                      <button onClick={handleBuyClick} className="CreatePostFeedButton greenButton">
                        Buy
                      </button>
                      <button className="CreatePostFeedButton" onClick={handleSellClick}>
                        Sell
                      </button>
                      <Select
                        options={listOptions}
                        value={selectedLists}
                        placeholder="Add to list +"
                        isClearable={false}
                        isSearchable={false}
                        unstyled
                        isMulti={true}
                        styles={customStylesPlus(tc())}
                        onMenuOpen={() => {
                          if (!isAuthenticated) {
                            alert('Please sign in to add stocks to lists.');
                            return;
                          }
                          fetchStockLists(isAuthenticated, userToken, setListOptions, setSelectedLists, optionTicker);
                        }}
                        onChange={(selectedOptions) => {
                          if (selectedOptions.length < selectedLists.length) {
                            const removedList = selectedLists.find(
                              (selected) => !selectedOptions.some((option) => option.value === selected.value)
                            );
                            if (removedList) {
                              handleRemoveFromList(
                                isAuthenticated,
                                userToken,
                                optionTicker,
                                removedList.value,
                                removedList.label,
                                setListOptions,
                                setSelectedLists
                              );
                              setSelectedLists(selectedOptions);
                            }
                          } else if (selectedOptions.length > selectedLists.length) {
                            const addedList = selectedOptions.find(
                              (option) => !selectedLists.some((selected) => selected.value === option.value)
                            );
                            if (addedList) {
                              handleAddToList(
                                isAuthenticated,
                                userToken,
                                optionTicker,
                                addedList.value,
                                addedList.label,
                                setListOptions,
                                setSelectedLists
                              );
                              setSelectedLists(selectedOptions);
                            }
                          }
                        }}
                      />
                      {renderPortfolioDropdown()}
                    </div>
                  ) : (
                    <div className="StockActions">
                      <button onClick={handleCloseModal} className="CreatePostFeedButton">
                        Cancel Order
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="StockGraphMain">
                {!mainOptionDataFetched ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 'var(--stock-chart-height)',
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                ) : !openBuyModal && !openSellModal ? (
                  <div style={{ height: 'var(--stock-chart-height)' }}>
                    <canvas ref={chartRef}></canvas>
                  </div>
                ) : openBuyModal || openSellModal ? (
                  renderBuyOrSellModal()
                ) : null}
                {!openBuyModal && !openSellModal && (
                  <div className="StockFilters">
                    {Object.keys(filters).map((key) => (
                      <button
                        key={key}
                        className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`}
                        onClick={() => setFilter(key)}
                      >
                        {key}
                      </button>
                    ))}
                  </div>
                )}

                {allTransactions?.length > 0 && (
                  <div className="StockStatistics">
                    <h2 className="StatsHeader">{`Your Position (${selectedPortfolio?.name})`}</h2>
                    <div className="StatsGrid">
                      <div className="label-value-pair">
                        <span>Contracts</span>
                        <strong>{fc(userTickerPosition?.quantity)}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Market Value</span>
                        <strong>{fc(userTickerPosition?.quantity * rc(currentPrice) * 100)}</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Portfolio Allocation</span>
                        <strong>
                          {fc(
                            userPortfolio?.totalValue && userPortfolio?.totalValue !== 0
                              ? ((userTickerPosition?.quantity *
                                  userTickerPosition?.avgBuyPrice) /
                                  userPortfolio.totalValue) *
                                  100
                              : 0.0
                          )}
                          %
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Average Cost</span>
                        <strong>{fc(userTickerPosition?.avgBuyPrice)} per contract</strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Unrealized Return</span>
                        <strong
                          className={
                            returnData?.unrealizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.unrealizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.unrealizedDollar)} (
                              {fc(returnData.unrealizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                      <div className="label-value-pair">
                        <span>Realized Return</span>
                        <strong
                          className={
                            returnData?.realizedDollar >= 0
                              ? 'StockChange bold positive'
                              : 'StockChange bold negative'
                          }
                        >
                          {returnData ? (
                            <>
                              {returnData.realizedDollar >= 0 ? '+' : ''}
                              {fc(returnData.realizedDollar)} (
                              {fc(returnData.realizedPercentage, 2, true)}%)
                            </>
                          ) : (
                            '--'
                          )}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}

                <div className="StockStatistics" style={{ paddingBottom: '4rem' }}>
                  <h2 className="StatsHeader">Stats</h2>
                  <div className="StatsGrid">
                    <div className="label-value-pair">
                      <span>Open</span>
                      <strong>{fc(dailyData?.open) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Close</span>
                      <strong>{fc(dailyData?.close) || '--'}</strong>
                    </div>

                    <div className="label-value-pair">
                      <span>High</span>
                      <strong>{fc(dailyData?.high) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Low</span>
                      <strong>{fc(dailyData?.low) || '--'}</strong>
                    </div>

                    <div className="label-value-pair">
                      <span>Pre-Market</span>
                      <strong>{fc(dailyData?.preMarket) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>After-Hours</span>
                      <strong>{fc(dailyData?.afterHours) || '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span>Volume</span>
                      <strong>
                        {dailyData?.volume ? formatNumber(dailyData.volume) : '--'}
                      </strong>
                    </div>
                    <div className="label-value-pair">
                      <span style={{ textTransform: 'capitalize' }}>
                        {filterHighMap[filter]} High
                      </span>
                      <strong>{periodHigh ? fc(periodHigh) : '--'}</strong>
                    </div>
                    <div className="label-value-pair">
                      <span style={{ textTransform: 'capitalize' }}>
                        {filterHighMap[filter]} Low
                      </span>
                      <strong>{periodLow ? fc(periodLow) : '--'}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
}
import React from 'react';
import '../styles/PrivacyPolicy.css'; // Import the renamed stylesheet for this component
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
const PrivacyPolicy = () => {
  useResponsiveWidth();
    const location = useLocation();
    const fromSettings = location.state?.fromSettings || false;
    const navigate = useNavigate();
  return (
    <div className="privacy-container">
         {/* Borrowing styles from post view */}
      {fromSettings && <div className="pv-BackToFeedContainer">
        <button onClick={() =>{
            navigate('/settings');
        }} className="pv-BackButton">
          <FontAwesomeIcon icon={faArrowLeft} /> <span style = {{color: '#707070'}}>Settings</span>
        </button>
      </div>
      }
      <h1 className="privacy-title bold">Privacy Policy</h1>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">Introduction</h2>
          At Vestr, we are committed to protecting your personal information and respecting your privacy. This Privacy Policy explains how we collect, use, and share information from users of our platform, including account holders and visitors. By accessing or using Vestr's services, you consent to the practices described in this policy.
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">Information We Collect</h2>
        <ul className="privacy-list">
          <li className="privacy-text">
            <span className="bold">Personal Information:</span> Name, email address, phone number, and other contact details provided during account creation. Profile details such as bio, interests, and social interactions with other users. Payment information for processing transactions (e.g., purchasing stocks, portfolio management).
          </li>
          <li className="privacy-text">
            <span className="bold">Usage Data:</span> Information on how you use the platform, including activity on your social feed, portfolio tracking, trading behavior, and interaction with other users and professional investors.
          </li>
          <li className="privacy-text">
            <span className="bold">Transaction Data:</span> Data on asset purchases, sales, portfolio composition, and trading history. Financial information used for transactions and copy trading activities.
          </li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">How We Use Your Information</h2>
        <ul className="privacy-list">
          <li className="privacy-text">Create and manage your user account.</li>
          <li className="privacy-text">Facilitate asset trading, portfolio tracking, and other investment-related activities.</li>
          <li className="privacy-text">Enable social interactions, such as following investors, posting research, and engaging in social feeds.</li>
          <li className="privacy-text">Process transactions and deliver investment news and market insights.</li>
          <li className="privacy-text">Provide customer support and respond to inquiries.</li>
          <li className="privacy-text">Conduct internal analytics to improve platform features, security, and user experience.</li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">Sharing Your Information</h2>
        <ul className="privacy-list">
          <li className="privacy-text">
            <span className="bold">With Third-Party Service Providers:</span> To help us operate our platform (e.g., payment processors, hosting services, or analytics providers).
          </li>
          <li className="privacy-text">
            <span className="bold">With Other Users:</span> When you engage in social activities like publishing research or interacting on social feeds, your profile and content may be visible to other users.
          </li>
          <li className="privacy-text">
            <span className="bold">With Legal Authorities:</span> When required by law or in response to valid legal processes (e.g., subpoenas, court orders).
          </li>
          <li className="privacy-text">
            <span className="bold">For Corporate Transactions:</span> In the event of a merger, acquisition, or asset sale, we may transfer your data as part of the transaction.
          </li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">Cookies and Tracking</h2>
        <p className="privacy-text">
          Vestr uses cookies and other tracking technologies to personalize your experience and monitor platform performance. You can control cookie preferences through your browser settings, but note that disabling cookies may affect the functionality of certain features.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">Data Security</h2>
        <p className="privacy-text">
          We take data security seriously and implement industry-standard measures to protect your personal and financial information. While we strive to secure your data, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">User Controls and Choices</h2>
        <ul className="privacy-list">
          <li className="privacy-text">
            <span className="bold">Profile Settings:</span> You can manage what information is publicly visible on your profile.
          </li>
          <li className="privacy-text">
            <span className="bold">Communication Preferences:</span> You can opt in or out of marketing communications from Vestr.
          </li>
          <li className="privacy-text">
            <span className="bold">Data Deletion:</span> You can request the deletion of your personal data, though certain legal or financial obligations may require us to retain some information.
          </li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">Children's Privacy</h2>
        <p className="privacy-text">
          Vestr's platform is not intended for individuals under the age of 18. We do not knowingly collect personal information from minors. If we discover that a minor has provided us with personal data, we will delete the data promptly.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">Changes to this Privacy Policy</h2>
        <p className="privacy-text">
          Vestr reserves the right to modify this Privacy Policy at any time. We will notify users of any significant changes through the platform or via email. Continued use of our platform after such updates constitutes acceptance of the revised Privacy Policy.
        </p>
      </section>

      <section className="privacy-section">
        <h2 className="privacy-subtitle bold">Contact Us</h2>
        <p className="privacy-text">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <address className="privacy-address">
          Vestr Inc.<br />
          221 N Broad St, Suite 3A<br />
          Middletown, DE 19709<br />
          Email: support@vestr.io
        </address>
      </section>
    </div>
  );
};

export default PrivacyPolicy;

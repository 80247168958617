import React, {useState} from 'react'
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { handleUpvoteClick, submitUpvoteCount } from '../../helper/PostHelper';
import { textFieldStyles } from '../../helper/StylesHelper';
import upvote from '../../assets/upvote.svg';
import greenUpvote from '../../assets/greenUpvote.svg';
import config from '../../config';
import TextField from '@mui/material/TextField';

export default function Upvote({isAdmin, isUpvotedState, setIsUpvotedState, setIsDownvotedState, setUpvoteCount, setDownvoteCount, postId, upvoteCount}) {
    const {isAuthenticated, userToken} = useContext(AuthContext);
    const [upvoteAnchorEl, setUpvoteAnchorEl] = useState(null);
    const [upvoteCountInput, setUpvoteCountInput] = useState(1);
    const { dk } = useContext(ThemeContext);  
    const closePopover = () => {
        setUpvoteAnchorEl(null);
      }
    const handleUpvote = (event) => {
        if (isAdmin && !isUpvotedState) {
        setUpvoteAnchorEl(event.currentTarget);
        } else {
        handleUpvoteClick({
            isAuthenticated,
            currentUser: { isAdmin },
            isUpvoted: isUpvotedState,
            setIsUpvoted: setIsUpvotedState,
            setIsDownvoted: setIsDownvotedState,
            setUpvoteCount,
            setDownvoteCount,
            config,
            postId,
            userToken,
        });
        }
    };


  return (
    <>
    <div className="ActionItem" onClick={handleUpvote}>
        <object aria-label="upvotes" type="image/svg+xml" data={isUpvotedState ? greenUpvote : upvote} alt="Upvote" className="ActionIcon" />
        {upvoteCount}
        </div>

          {/* Upvote Popover */}
          {isAdmin && (
            <Popover
              open={Boolean(upvoteAnchorEl)}
              anchorEl={upvoteAnchorEl}
              onClose={closePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              
              slotProps={{
                paper: {
                    sx: {
                        backgroundColor: dk() ? '#353A36' : '#FFFFFF', // Replace background logic with theme-based dynamic colors
                    },
                },
            }}
            >
              <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TextField
                  label="Add upvotes"
                  type="number"
                  value={upvoteCountInput}
                  onChange={(e) => setUpvoteCountInput(e.target.value)}
                  sx={textFieldStyles}
                  slotProps={{
                    input: {
                      inputProps: { min: 1 }, // Set the min property for the input element
                    },
                    
                  }}
                />
                <Button style = {{color: 'var(--text-color)'}}
                onClick={ ()=> 
                 submitUpvoteCount({
                upvoteCountInput,
                setUpvoteCount,
                setDownvoteCount,
                setIsUpvoted: setIsUpvotedState,
                setIsDownvoted: setIsDownvotedState,
                closeUpvoteModal: closePopover,
                config,
                postId,
                userToken,
                })
    
                 }>Submit</Button>
                <Button style = {{color: 'var(--text-color)'}}
                onClick={closePopover}>Cancel</Button>
              </div>
            </Popover>
          )}

          </>
  )
}

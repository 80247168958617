import React, { useState, useContext, useEffect } from 'react';
import '../styles/Settings.css';
import { AuthContext } from '../context/AuthContext';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useResponsiveWidth from '../hooks/useResponsiveWidth';

export default function Settings() {
  useResponsiveWidth();
  const [isEditing, setIsEditing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [isPublicState, setIsPublicState] = useState(false);
  const [vestrMembership, setVestrMembership] = useState(null); 
  const [membershipStatus, setMembershipStatus] = useState(null); 
  const [changePinActive, setChangePinActive] = useState(false);
  const [currentPin, setCurrentPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmNewPin, setConfirmNewPin] = useState("");
  
  const navigate = useNavigate();



  const { phoneNum, email, isPublic, userToken, userId, setPhoneNum, setIsPublic, setEmail } = useContext(AuthContext);

  useEffect(() => {
    setPhoneNumber(phoneNum);
    setEmailAddress(email);
    setIsPublicState(isPublic);
  }, [phoneNum, email, isPublic]);

  const handlePinChange = async () => {
    if (newPin !== confirmNewPin) {
      alert("New PINs do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${config.backendUrl}/api/auth/change-pin`,
        { currentPin, newPin },
        { headers: { Authorization: `Bearer ${userToken}` } }
      );

      if (response.status === 200) {
        alert("PIN changed successfully");
        setChangePinActive(false);
        setCurrentPin("");
        setNewPin("");
        setConfirmNewPin("");
      } else {
        alert(response.data.message || "Failed to change PIN");
      }
    } catch (error) {
      console.error("Error changing PIN:", error);
      alert("Error changing PIN. Please try again.");
    }
  };





  useEffect(() => {
    const fetchMembershipDetails = async () => {
      if (!userToken) return;
      try {
        const response = await axios.get(`${config.backendUrl}/api/payments/membership-details`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.status === 200) {
          const fetchedMembership = response.data.vestrMembership;
          const fetchedMembershipStatus = response.data.membershipStatus;

          setVestrMembership(fetchedMembership);
          setMembershipStatus(fetchedMembershipStatus);

        } else {
          console.error('Error fetching membership details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching membership details:', error);
      }
    };

    fetchMembershipDetails();
  }, [userToken, isEditing]);

  const handleEditClick = () => {
    if (isEditing) {
      saveChanges();
    }
    setIsEditing(!isEditing);
  };


  const handleResumeMembership = async () => {
    const confirm = window.confirm('Are you sure you want to resume your subscription?');
    if (!confirm) {
      return;
    }
    try {
      const response = await axios.post(`${config.backendUrl}/api/payments/resume-subscription`, {}, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
  
      if (response.status === 200) {
        alert('Your subscription has been resumed.');
        window.location.reload(); // Refresh the page to reflect the updated membership status
      } else {
        alert(response.data.error || 'Failed to resume subscription.');
      }
    } catch (error) {
      console.error('Error resuming membership:', error);
      alert('Error resuming subscription. Please try again later.');
    }
  };


  const handleCancelMembership = async () => {
    const confirm = window.confirm('Are you sure you want to cancel your subscription?');
    if (!confirm) {
      return;
    }
    try {
      const response = await axios.post(`${config.backendUrl}/api/payments/cancel-subscription`, {}, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200) {
        alert('Your subscription has been canceled. You will have access until the end of your current period.');
        window.location.reload();
      } else {
        alert(response.data.error || 'Failed to cancel subscription.');
      }
    } catch (error) {
      console.error('Error canceling membership:', error);
      alert('Error canceling subscription. Please try again later.');
    }
  };

  const saveChanges = async () => {
    try {
      const response = await axios.put(`${config.backendUrl}/api/auth/update-profile`, {
        userId,
        phoneNum: phoneNumber,
        email: emailAddress,
        isPublic: isPublicState,
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      });

      if (response.status === 200) {
        setPhoneNum(phoneNumber);
        setEmail(emailAddress);
        setIsPublic(isPublicState);
        localStorage.setItem('phoneNum', phoneNumber);
        localStorage.setItem('email', emailAddress);
        localStorage.setItem('isPublic', isPublicState);
        alert('Profile updated successfully');
      } else {
        alert(response.data.message || 'Error updating profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  return (
    <div className="se-page-container">
      <div className="se-settings-container">
  
        <h1 className="se-settings-title bold">Settings</h1>
        <div className="se-settings-main-btns">
        <button className="CreatePostFeedButton greenButton"  onClick={handleEditClick}>
          {isEditing ? 'Save' : 'Edit'}
        </button>
        {isEditing && <button className="CreatePostFeedButton greenButton"  
        onClick={()=>{ setIsEditing(false);  setPhoneNumber(phoneNum);
    setEmailAddress(email);
    setIsPublicState(isPublic);}}>
          Cancel
        </button>}
        </div>
      
        <div className='se-sections-container'>
          
     
        <div className="se-section">
          <h2 className="se-section-title bold">Account info</h2>
          <div className="se-info-grid-row" >
            <div className="se-info-item">
              <span className="se-info-label bold">Phone #</span>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="se-info-value editable"
                  onClick={() => setIsEditing(true)}
                  
                />
            </div>
            <div className="se-info-item">
              <span className="se-info-label bold">Email</span>
                <input
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  className="se-info-value editable"
                  onClick={() => setIsEditing(true)}
                />
            </div>
          </div>
       
        </div>


        <div className="se-section">
          <h2 className="se-section-title bold">Manage subscription</h2>
          {vestrMembership ? (
            <>
            <div className='se-sub-wrapper'>
              <p className="se-subscription-details">
                Vestr+ {vestrMembership.type}:{' '}
                {vestrMembership.price && vestrMembership.cycle
                  ? `$${vestrMembership.price.toFixed(2)}/${vestrMembership.cycle}`
                  : 'Free'}
              </p>
              <p className="se-subscription-details">
            {membershipStatus === 'active' ? 'Next payment:' : 'Expires:'}{' '}
            {vestrMembership.expires
              ? new Date(vestrMembership.expires).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : 'No upcoming payment'}
          </p>
          </div>
            </>
          ) : (
            <p>Loading membership details...</p>
          )}
            <div className='se-info-grid'> 

   
          <button onClick= {()=> navigate('/vestrplus')}className="CreatePostFeedButton greenButton">Change subscription</button>
         { membershipStatus === 'active' && <button onClick = {handleCancelMembership} className="CreatePostFeedButton greenButton">Cancel subscription</button>}
         {membershipStatus === 'canceled' && <button onClick = {handleResumeMembership} className="CreatePostFeedButton greenButton">Resume subscription</button>}

         </div>
        </div>


        <div className="se-section" style={{ height: '19rem' }}>
      <h2 className="se-section-title bold">Security</h2>
      <div className="se-info-grid">
        <button onClick={() => setChangePinActive(!changePinActive)} className="CreatePostFeedButton greenButton">
          Change PIN
        </button>
        {changePinActive && (
          <>
            <input
              className="se-info-value editable"
              type="password"
              placeholder="Enter Current PIN"
              value={currentPin}
              onChange={(e) => setCurrentPin(e.target.value)}
            />
            <input
                    className="se-info-value editable"
              type="password"
              placeholder="Enter New PIN"
              value={newPin}
              onChange={(e) => setNewPin(e.target.value)}
            />
            <input
                    className="se-info-value editable"
              type="password"
              placeholder="Confirm New PIN"
              value={confirmNewPin}
              onChange={(e) => setConfirmNewPin(e.target.value)}
            />
            <button onClick={handlePinChange} className="CreatePostFeedButton greenButton">
              Save PIN
            </button>
          </>
        )}
      </div>
    </div>

      
 



        <div className="se-section" style = {{height: '19rem'}}>
          <h2 className="se-section-title bold">Account Privacy</h2>
          <div className="se-info-grid">
          <div className="se-toggle-container">
  <div
    className={`se-toggle-option bold ${!isPublicState ? 'active' : ''}`}
    onClick={() =>{ setIsEditing(true); setIsPublicState(false)}}
  >
    Private
  </div>
  <div
    className={`se-toggle-option bold ${isPublicState ? 'active' : ''}`}
    onClick={() => {setIsEditing(true); setIsPublicState(true)}}
  >
    Public
  </div>
          </div>
            
          </div>
        </div>
             
        </div>

        <div className="se-policies">
          <span
            onClick={() => navigate('/privacy', {
              state: { fromSettings: true }
            })}
            className="se-terms-link bold">
            Privacy Policy
          </span>
          <span
            onClick={() => navigate('/terms', {
              state: { fromSettings: true }
            })}
            className="se-terms-link bold">
            Terms of Service
          </span>
        </div>
      </div>
    </div>
  );
}
import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import "../styles/Portfolio.css";
import { AuthContext } from '../context/AuthContext';
import {
  fetchAllTransactionsGeneral,
  fetchUserPortfolio,
  fetchUserCashBalance,
  getChangeColor,
  getChangePercentageColor,
  filters,
  getUnixTimestampRange,
  getUnixTimestamp,
  calculateReturns,
  fetchCompanyDetails,
  portfolioIntervalMap,
  isOption,
  handleNavigateToAsset,
  isCrypto,
  getTypeOfQuantity,
} from '../helper/MarketsHelper';
import { LoadingSpinner, formatCurrency as fc } from '../helper/StylesHelper';
import config from '../config';
import { createPortfolioChart } from '../helper/PortfolioChartHelper';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
import { shareTrade } from '../helper/CreatePostHelper';
import BackButton from './BackButton';

export default function Portfolio() {
  
  useResponsiveWidth();
  const [transactions, setTransactions] = useState([]);
  const [cashBalance, setCashBalance] = useState(null); 
  const [assetsValue, setAssetsValue] = useState(null); 

  const [allPortfolios, setAllPortfolios] = useState([]); 
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const [newPortfolioName, setNewPortfolioName] = useState('');

  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [filter, setFilter] = useState('1d');
  const [livePriceMap, setLivePriceMap] = useState(null);
  const [quantityMap, setQuantityMap] = useState(null);
  const [intervalPriceMap, setIntervalPriceMap] = useState(null); 
  const [portfolioChangeAmount, setPortfolioChangeAmount] = useState(null);
  const [portfolioChangePercentage, setPortfolioChangePercentage] = useState(null);
  const wsRef = useRef(null); 
  const navigate = useNavigate();

  const [referenceValue, setReferenceValue] = useState(null);

  const { userToken, isAuthenticated, username: currentUsername } = useContext(AuthContext);
  const {tc, theme} = useContext(ThemeContext);
  const { username } = useParams();
  const location = useLocation();

  const [portfolioData, setPortfolioData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
  const [hoveredDateTime, setHoveredDateTime] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [tickerCompanyMap, setTickerCompanyMap] = useState({});
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [showAddCash, setShowAddCash] = useState(false);
  const [cashToAdd, setCashToAdd] = useState('');

  const [portfolioStats, setPortfolioStats] = useState({});

  const fetchAllPortfolios = async () => {  
    try {
      const response = await axios.get(`${config.backendUrl}/api/portfolios`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: {
          username,
        },
      });

      console.log('Portfolios:', response.data);

      setAllPortfolios(response.data);

      if (response.data && response.data.length > 0) {
        // Find the portfolio with isMainPortfolio set to true
        const mainPortfolio = response.data.find(portfolio => portfolio.isMainPortfolio);
      
        // Set the selected portfolio to the mainPortfolio if it exists, otherwise the first portfolio
        setSelectedPortfolio(mainPortfolio || response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching portfolios:', error);
    }
  };

  useEffect(() => { 
    fetchAllPortfolios();
  }, [username, userToken, isAuthenticated]);

  const createNewPortfolio = async () => {  
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/portfolios`,
        { name: newPortfolioName },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      setAllPortfolios((prevPortfolios) => [...prevPortfolios, response.data]);
    } catch (error) {
      alert(error.response?.data?.message || 'An error occurred while creating the portfolio.');
      console.error('Error creating portfolio:', error);
    }
  };

  // Whenever selectedPortfolio changes, refetch transactions, portfolio details, and cash
  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio._id) return;

    // Fetch portfolio details again (in case updated):
    fetchUserPortfolio(isAuthenticated, userToken, setSelectedPortfolio, selectedPortfolio._id);

    // Fetch transactions for this portfolio
    fetchAllTransactionsGeneral(isAuthenticated, userToken, setTransactions, selectedPortfolio._id)
      .then(() => setTransactionsLoading(false));

    // Fetch cash balance for this portfolio
    fetchUserCashBalance(isAuthenticated, userToken, setCashBalance, selectedPortfolio._id);

  }, [selectedPortfolio?._id, userToken, isAuthenticated]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;

    const tickerArray = selectedPortfolio.assets.map((stock) => stock.ticker);
  
    const fetchAllCompanyDetails = async () => {
      try {
        const companyDetailsPromises = tickerArray.map((ticker) =>
          fetchCompanyDetails(ticker)
        );
  
        const allCompanyDetails = await Promise.all(companyDetailsPromises);
  
        const tickerCompanyDetailsMap = allCompanyDetails.reduce((acc, companyInfo, index) => {
          const ticker = tickerArray[index];
          acc[ticker] = companyInfo.name;
          return acc;
        }, {});
  
        setTickerCompanyMap(tickerCompanyDetailsMap);
      } catch (error) {
        console.error("Error fetching company details for tickers:", error);
      }
    };

    const fetchIntervalPrices = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/api/markets/intervalprices`, {
          params: {
            tickers: tickerArray.join(','),
          },
        });
    
        const data = response.data;
    
        const intervalPriceMapTemp = {};
        data.forEach((item) => {
          const ticker = item.ticker;
          const prices = item.prices;
          intervalPriceMapTemp[ticker] = prices;
        });
    
        setIntervalPriceMap(intervalPriceMapTemp);
      } catch (error) {
        console.error("Error fetching interval prices:", error);
      }
    };
    fetchAllCompanyDetails();
    fetchIntervalPrices();
  }, [selectedPortfolio]);

  // Compute portfolio statistics when data changes
  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets || !livePriceMap || !tickerCompanyMap || !transactions) {
      return;
    }

    // Build buy/sell maps
    const buyMap = {};  
    const sellMap = {};
    const buyVolumeMap = {};  
    const sellVolumeMap = {};

    transactions.forEach((transaction) => {
      const { ticker, type, assetType } = transaction;
      const factor = (assetType === 'option' ? 100 : 1);
      if (type === 'buy') {
        buyMap[ticker] = (buyMap[ticker] || 0) + 1;
        buyVolumeMap[ticker] = (buyVolumeMap[ticker] || 0) + (transaction.quantity * transaction.price * factor);
      }
      if (type === 'sell') {
        sellMap[ticker] = (sellMap[ticker] || 0) + 1;
        sellVolumeMap[ticker] = (sellVolumeMap[ticker] || 0) + (transaction.quantity * transaction.price * factor);
      }
    });

    // Compute stats for each asset
    const statsTemp = {};
    selectedPortfolio.assets.forEach((stock) => {
      const ticker = stock.ticker.toUpperCase();
      const livePrice = livePriceMap[ticker];
      const tickerTransactions = transactions.filter((transaction) => transaction.ticker === ticker); 
      const { unrealizedDollar, unrealizedPercentage, realizedDollar, realizedPercentage } = calculateReturns(stock, livePrice, tickerTransactions);

      statsTemp[ticker] = {
        totalInvested: stock.quantity * stock.avgBuyPrice,
        marketValue: livePrice * stock.quantity,
        unrealizedDollar,
        unrealizedPercentage,
        realizedDollar,
        realizedPercentage,
        buyCount: buyMap[ticker] || 0,
        sellCount: sellMap[ticker] || 0,
        buyVolumeMap: buyVolumeMap[ticker] || 0,
        sellVolumeMap: sellVolumeMap[ticker] || 0,
      };
    });

    // Aggregate totals
    let totalInvested = Object.keys(statsTemp).reduce((total, ticker) => {
      return total + statsTemp[ticker].totalInvested;
    }, 0);
  
    let marketValue = Object.keys(statsTemp).reduce((total, ticker) => {
      return total + statsTemp[ticker].marketValue;
    }, 0);
  
    let totalRealizedDollar = Object.keys(statsTemp).reduce((total, ticker) => {
      return total + statsTemp[ticker].realizedDollar;
    }, 0);

    let totalUnrealizedDollar = Object.keys(statsTemp).reduce((total, ticker) => {
      return total + statsTemp[ticker].unrealizedDollar;
    }, 0);
  
    const initialCash = 100000; // initial cash for a new portfolio
    const cashDeposited = selectedPortfolio?.cashDeposited || 0;
    const totalCash = initialCash + cashDeposited;
    let totalUnrealizedPercentage = totalCash > 0 ? (totalUnrealizedDollar / totalCash) * 100 : 0;
    let totalRealizedPercentage = totalCash > 0 ? (totalRealizedDollar / totalCash) * 100 : 0;

    let totalBuys = Object.keys(statsTemp).reduce((total, ticker) => {
      return total + statsTemp[ticker].buyCount;
    }, 0);
  
    let totalSells = Object.keys(statsTemp).reduce((total, ticker) => {
      return total + statsTemp[ticker].sellCount;
    }, 0);

    let totalBuyVolume = Object.keys(statsTemp).reduce((total, ticker) => {
      return total + statsTemp[ticker].buyVolumeMap;
    }, 0);

    let totalSellVolume = Object.keys(statsTemp).reduce((total, ticker) => {
      return total + statsTemp[ticker].sellVolumeMap;
    }, 0);

    setPortfolioStats({
      totalInvested,
      marketValue,
      totalUnrealizedPercentage,
      totalUnrealizedDollar,
      totalRealizedDollar,
      totalRealizedPercentage,
      totalBuys,
      totalSells,
      totalBuyVolume,
      totalSellVolume,
      ...statsTemp,
    });

  }, [selectedPortfolio, livePriceMap, transactions, tickerCompanyMap]);

  useEffect(() => {
    if (portfolioStats && tickerCompanyMap ) {
      setTableLoading(false);
    }
  }, [portfolioStats, tickerCompanyMap]);

  const handleConfirmAddCash = async () => {
    try {
      const amount = parseFloat(cashToAdd);
      if (isNaN(amount) || amount <= 0) {
        alert('Please enter a valid amount greater than 0.');
        return;
      }
  
      const response = await axios.post(
        `${config.backendUrl}/api/stockActions/addCash`,
        { amount, portfolioId: selectedPortfolio._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      setCashBalance(response.data.cash);
      setShowAddCash(false);
      setCashToAdd('');

      alert(response.data.message);
      // Refresh data
      fetchAllTransactionsGeneral(isAuthenticated, userToken, setTransactions, selectedPortfolio._id);
      fetchUserPortfolio(isAuthenticated, userToken, setSelectedPortfolio, selectedPortfolio._id);
    } catch (error) {
      console.error('Error adding cash:', error);
      alert(error.response?.data?.message || 'An error occurred while adding cash.');
    }
  };
  
  const handleCancelAddCash = () => {
    setShowAddCash(false);
    setCashToAdd('');
  };

  const fetchPortfolioDataPoints = async () => {
    if (!selectedPortfolio || !selectedPortfolio._id) return;

    setDataFetched(false);
    try {
      let fromDateUnixMs;
      let multiplier, timespan;
      let toDateUnixMs = getUnixTimestamp();

      if (filter === 'all time') {
        fromDateUnixMs = new Date('2024-08-01').getTime();
      } else {
        fromDateUnixMs = getUnixTimestampRange(filter);
      }

      const intervalArray = portfolioIntervalMap[filter];
      multiplier = intervalArray[0];
      timespan = intervalArray[1];
       
      const response = await axios.get(`${config.backendUrl}/api/markets/portfolio/datapoints/${username}/${selectedPortfolio._id}`, {
        params: {
          multiplier,
          timespan,
          fromDateUnixMs,
          toDateUnixMs,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setReferenceValue(response.data[0]?.portfolioValue);
      setPortfolioData(response.data);
     
    } catch (error) {
      setPortfolioData([]);
      setPortfolioChangeAmount("--");
      setPortfolioChangePercentage("--");
      console.error('Error fetching portfolio data points:', error);
    }
    setDataFetched(true);
  };

  useEffect(() => {
    if (userToken && selectedPortfolio && selectedPortfolio._id) {
      fetchPortfolioDataPoints();
    }
  }, [userToken, filter, cashBalance, selectedPortfolio?._id, isAuthenticated]);

  useEffect(()=> {
    console.log('selectedPortfolio:', selectedPortfolio);
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!chartRef.current || !dataFetched || !portfolioData.length) return;

    const referenceValueForChart = portfolioData[0]?.portfolioValue;
    setReferenceValue(referenceValueForChart);

    const cleanup = createPortfolioChart({
      chartRef,
      chartInstanceRef: chartInstance,
      portfolioData,
      referenceValueForChart,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    });

    return cleanup;
  }, [portfolioData, dataFetched, filter, theme, cashBalance]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;

    const ws = new WebSocket(config.socketUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      selectedPortfolio.assets.forEach((stock) => {
        const ticker = stock.ticker.toUpperCase();
        ws.send(JSON.stringify({ action: 'subscribe', ticker }));
      });
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'instantPrice') {
        message.data.forEach((data) => {
          if (data.ev === 'T') {
            const ticker = data.sym;
            const livePrice = parseFloat(data.p);
            updateLivePrice(ticker, livePrice);
          }
        });
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current = ws;

    return () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        selectedPortfolio.assets.forEach((stock) => {
          const ticker = stock.ticker.toUpperCase();
          wsRef.current.send(JSON.stringify({ action: 'unsubscribe', ticker }));
        });
        wsRef.current.close();
      }
    };
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;

    const tickers = selectedPortfolio.assets.map((stock) => stock.ticker.toUpperCase());
    const fetchPrice = () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        tickers.forEach((ticker) => {
          wsRef.current.send(JSON.stringify({ action: 'getInstantPrice', ticker }));
        });
      }
    };

    const intervalId = setInterval(fetchPrice, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!selectedPortfolio) return;

    const fetchCurrentPrices = async () => {
      try {
        const pricePromises = selectedPortfolio.assets.map((stock) => {
          const ticker = stock.ticker.toUpperCase();
          return axios
            .get(`${config.backendUrl}/api/markets/dailychange?ticker=${ticker}`)
            .then((response) => ({ ticker, price: response.data.currentPrice }))
            .catch((error) => {
              console.error(`Error fetching price for ${ticker}:`, error);
              return { ticker, price: '--' };
            });
        });

        const prices = await Promise.all(pricePromises);
        const updatedPriceMap = prices.reduce((map, { ticker, price }) => {
          if (isOption(ticker)) {
            price = price * 100;
          }
          map[ticker] = price;
          return map;
        }, {});

        setLivePriceMap((prevMap) => ({ ...prevMap, ...updatedPriceMap }));
      } catch (error) {
        console.error('Error fetching current prices:', error);
      }
    };

    fetchCurrentPrices();
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;

    const quantityMapTemp = selectedPortfolio.assets.reduce((map, stock) => {
      map[stock.ticker.toUpperCase()] = stock.quantity;
      return map;
    }, {});

    setQuantityMap(quantityMapTemp);
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!livePriceMap || !quantityMap) {
      return;
    }

    const liveValueMapTemp = Object.keys(livePriceMap).reduce((map, ticker) => {
      const price = parseFloat(livePriceMap[ticker]);
      const quantity = quantityMap[ticker];
      if (price && quantity) {
        map[ticker] = price * quantity;
      }
      return map;
    }, {});
  
    const assetsValueTemp = Object.values(liveValueMapTemp).reduce((total, value) => total + value, 0);
    setAssetsValue(assetsValueTemp);
  }, [livePriceMap, quantityMap]);

  useEffect(() => {
    if (cashBalance !== null && assetsValue !== null) {
      setBalanceLoading(false);
    }
  }, [cashBalance, assetsValue]);

  useEffect(()=> {
    if (!dataFetched) {
      setPortfolioChangeAmount("--");
      setPortfolioChangePercentage("--");
    } else if (typeof cashBalance === 'number' && typeof assetsValue === 'number' && typeof referenceValue === 'number' && portfolioData.length > 0) {
      const changeAmount = (cashBalance + assetsValue) - referenceValue;
      const changePercentage = (changeAmount / referenceValue) * 100;
      setPortfolioChangeAmount(changeAmount);
      setPortfolioChangePercentage(changePercentage);
    } 
  }, [cashBalance, assetsValue, referenceValue, portfolioData, dataFetched]);

  const updateLivePrice = (ticker, price) => {
    if (isOption(ticker)) {
      price = price * 100;
    }
    setLivePriceMap((prevMap) => ({
      ...prevMap,
      [ticker]: price,
    }));
  };

  const renderTradeCard = (transaction) => {  
    if (transactionsLoading || !livePriceMap || !tickerCompanyMap) {
      return <LoadingSpinner />;
    }
  
    let { ticker, type, price , date, quantity, _id} = transaction;
    let originalPrice = price;
    if (isOption(ticker)) {
      price = price * 100;
    }

    const formattedDate = new Date(date).toLocaleString();
    const livePrice = parseFloat(livePriceMap[ticker]);
    const liveChangeAmount = (typeof livePrice === 'number' && typeof price === 'number') ? livePrice - price : null;
    const liveChangePercentage = (typeof livePrice === 'number' && typeof price === 'number') ? ((livePrice - price) / price) * 100 : null;
    const isBuy = type === 'buy';
    let changePercentageToUse = liveChangePercentage || 0;

    return (
      <div className='PortTrade' 
        onClick={()=> shareTrade(_id, navigate)}
        style={{
          backgroundColor: isBuy ? getChangeColor(changePercentageToUse, tc()) : 'transparent',
          border: !isBuy && `0.05rem solid ${getChangePercentageColor(-changePercentageToUse, tc())}`,
        }}
      >
        <div className='PortTradeHeader'>
          <span className='PortTradeLabel'>
            {`${type === 'buy' ? 'BUY' : 'SELL'} - ${formattedDate}`}
          </span>
          <div className='PortTradeCompanyHeader'>
            <span className='PortTradeLabel'>
              {ticker}
            </span>
            <span className="PortTradeValue bold">
              {tickerCompanyMap[ticker]}
            </span>
          </div>
          <div className="PortTradeTable">
            <div className="PortTradeTableRow">
              <span className='PortTradeLabel'>Price:</span>
              <span className="PortTradeValue bold">${fc(originalPrice)}</span>
              <span></span>
              <span className="PortTradeLabel">Since {isBuy ? 'buy' : 'sale'}:</span>
              <span style = {{color: getChangePercentageColor(liveChangeAmount, tc())}}
                className="PortTradeValue bold">
                {liveChangeAmount >=0 && '+'}{fc(liveChangeAmount * quantity)}
              </span>
            </div>
            <div className="PortTradeTableRow">
              <span className='PortTradeLabel'>{getTypeOfQuantity(ticker)}: </span>
              <span className="PortTradeValue bold">{fc(quantity, isCrypto(ticker) ? 4 : 2)}</span>
              <span></span>
              <span className="PortTradeLabel">Since {isBuy ? 'buy' : 'sale'}:</span>
              <span style = {{color:getChangePercentageColor(liveChangeAmount, tc())}}
                className="PortTradeValue bold">
                {liveChangePercentage >= 0 && '+'}{fc(liveChangePercentage)}%
              </span>
            </div>
            <div className="PortTradeTableRow">
              <span className='PortTradeLabel'>Total:</span>
              <span className="PortTradeValue bold">${fc(price * quantity)}</span>
              <span></span>
              <span className="PortTradeLabel">Present Value:</span>
              <span className="PortTradeValue bold">${fc(livePrice * quantity)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTradeHistory = (transaction) => {
    if (transactionsLoading || !livePriceMap) {
      return <LoadingSpinner />;
    }
    return renderTradeCard(transaction);
  };

  const renderTable = () => { 
    if (transactions?.length === 0) {
      return;
    }

    if (tableLoading || !intervalPriceMap || !livePriceMap || !tickerCompanyMap) {
      return <LoadingSpinner />;
    }
  
    const keysToUse = Object.keys(portfolioStats).filter((ticker) => {
      return ticker !== 'totalInvested' && ticker !== 'marketValue'
        && ticker !== 'totalUnrealizedPercentage' && ticker !== 'totalUnrealizedDollar'
        && ticker !== 'totalRealizedDollar' && ticker !== 'totalRealizedPercentage'
        && ticker !== 'totalBuys' && ticker !== 'totalSells' && ticker !== 'totalBuyVolume' && ticker !== 'totalSellVolume';
    });
  
    const calculatePercentageChange = (currentPrice, previousPrice) => {
      if (!currentPrice || !previousPrice || previousPrice === 0) return '--';
      const change = ((currentPrice - previousPrice) / previousPrice) * 100;
      return isNaN(change) ? '--' : change;
    };
  
    return (
      <div className="port-stats-table">
        <h2 className="port-right-sidebar-header">Holdings</h2>
        <table className="stats-table">
          <thead>
            <tr>
              <th>Ticker</th>
              <th>Name</th>
              <th>Total Investment</th>
              <th>Present Value</th>
              <th>Unrealized Return</th>
              <th>Realized Return</th>
              <th>24h %</th>
              <th>7d %</th>
              <th>1y %</th>
              <th>Buys</th>
              <th>Sells</th>
            </tr>
          </thead>
          <tbody>
            {keysToUse.map((ticker) => {
            
              const currentPrice = livePriceMap[ticker];
              let intervalPrices = { ...intervalPriceMap[ticker] } || {};

              if (isOption(ticker)) {
                intervalPrices['24h'] = intervalPrices['24h'] !== undefined ? intervalPrices['24h'] * 100 : undefined;
                intervalPrices['7d'] = intervalPrices['7d'] !== undefined ? intervalPrices['7d'] * 100 : undefined;
                intervalPrices['1y'] = intervalPrices['1y'] !== undefined ? intervalPrices['1y'] * 100 : undefined;
              }
            
              const change24h = calculatePercentageChange(currentPrice, intervalPrices['24h']);
              const change7d = calculatePercentageChange(currentPrice, intervalPrices['7d']);
              const change1y = calculatePercentageChange(currentPrice, intervalPrices['1y']);
  
              return (
                <tr 
                  className='port-stats-row'
                  onClick={() => handleNavigateToAsset(navigate, ticker)}
                  key={ticker}
                >
                  <td className='bold'>{ticker}</td>
                  <td className='bold'>{tickerCompanyMap[ticker] || "N/A"}</td>
                  <td className='bold'>${fc(portfolioStats[ticker]?.totalInvested) || "--"}</td>
                  <td className='bold'>${fc(portfolioStats[ticker]?.marketValue) || "--"}</td>
                  <td
                    className={
                      portfolioStats[ticker]?.unrealizedPercentage >= 0
                        ? "port-positive bold"
                        : "port-negative bold"
                    }
                  >
                    {portfolioStats[ticker]?.unrealizedDollar >= 0 ? '+' : ''}
                    {fc(portfolioStats[ticker]?.unrealizedDollar)} ({fc(portfolioStats[ticker]?.unrealizedPercentage, 2, true)}%)
                  </td>
                  <td
                    className={
                      portfolioStats[ticker]?.realizedPercentage >= 0
                        ? "port-positive bold"
                        : "port-negative bold"
                    }
                  >
                  {portfolioStats[ticker]?.realizedDollar >= 0 ? '+' : ''}
                    {fc(portfolioStats[ticker]?.realizedDollar)} ({fc(portfolioStats[ticker]?.realizedPercentage, 2, true)}%)
                  </td>
                  <td className="bold" style = {{color: getChangePercentageColor(change24h, tc())}}>{change24h >= 0 && '+'}{fc(change24h, 2)}%</td>
                  <td className="bold" style = {{color: getChangePercentageColor(change7d, tc())}}>{change7d >= 0 && '+'}{fc(change7d, 2)}%</td>
                  <td className="bold" style = {{color: getChangePercentageColor(change1y, tc())}}>{change1y >= 0 && '+'}{fc(change1y, 2)}%</td>
                  <td className='bold'>{portfolioStats[ticker]?.buyCount !== undefined ? fc(portfolioStats[ticker].buyCount, 0) : "--"}</td>
                  <td className='bold'>{portfolioStats[ticker]?.sellCount !== undefined ? fc(portfolioStats[ticker].sellCount, 0) : "--"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderPortfolio = () => {
    // If data is still loading or some values aren't ready, show a spinner or --
    if (!selectedPortfolio || balanceLoading || portfolioChangeAmount === null || portfolioChangePercentage === null) {
      return <LoadingSpinner />;
    }
  
    const displayChangeAmount = isHovering
      ? parseFloat(hoveredChangeAmount)
      : portfolioChangeAmount;
    const displayChangePercentage = isHovering
      ? parseFloat(hoveredChangePercent)
      : portfolioChangePercentage;
  
    // Show -- if data isn't fetched or assetsValue isn't ready
    const displayValue = (dataFetched && typeof cashBalance === 'number' && typeof assetsValue === 'number')
      ? `$${fc(cashBalance + assetsValue)}`
      : '--';
  
    const handleSetAsMainPortfolio = async () => {
      try {
        const response = await axios.put(
          `${config.backendUrl}/api/portfolios/edit-mainPortfolio`,
          { newMainPortfolioId: selectedPortfolio._id },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        // After successfully setting the main portfolio, refresh the list of portfolios
        await fetchAllPortfolios();
        alert(`Main portfolio set to "${selectedPortfolio.name}"`);
      } catch (error) {
        console.error('Error setting main portfolio:', error);
        alert(error.response?.data?.message || 'An error occurred while setting the main portfolio.');
      }
    };
  
    return (
      <div className="port-header-wrapper">
        <div className="port-cards-wrapper">
          {allPortfolios.map((portfolio, index) => (
            <div
              key={index}
              onClick={() => setSelectedPortfolio(portfolio)}
              className={`port-card bold ${selectedPortfolio?._id === portfolio?._id ? 'port-card-selected' : ''}`}
            >
              <span className='bold'>
                {portfolio.name}{' '}
                {portfolio.isMainPortfolio && (
                  <span
                    className={`port-card-main-span ${
                      selectedPortfolio?._id === portfolio?._id ? 'port-card-main-span-selected' : ''
                    }`}
                  >
                    Main
                  </span>
                )}
              </span>
            </div>
          ))}
  
          {isCreatingPortfolio ? (
            <div className="port-card-edit-wrapper">
              <div className='port-card-edit'>
                <input
                  type="text"
                  value={newPortfolioName}
                  onChange={(e) => setNewPortfolioName(e.target.value)}
                  placeholder="Portfolio Name"
                  className='AddCashInput'
                />
                <button
                  className='CreatePostFeedButton'
                  onClick={async () => {
                    if (!newPortfolioName.trim()) {
                      alert('Please enter a portfolio name');
                      return;
                    }
                    await createNewPortfolio(newPortfolioName);
                    setIsCreatingPortfolio(false);
                    setNewPortfolioName('');
                    fetchAllPortfolios();
                  }}
                >
                  Save
                </button>
                <button
                  className='CreatePostFeedButton'
                  onClick={() => {
                    setIsCreatingPortfolio(false);
                    setNewPortfolioName('');
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            currentUsername === username && <div
              className="port-card bold"
              onClick={() => setIsCreatingPortfolio(true)}
            >
              New portfolio +
            </div>
          )}
        </div>
        <span className='port-selected-name bold'>
          {selectedPortfolio?.name}
        </span>
        <span className="port-value bold">
          {isHovering ? `$${fc(hoveredPrice)}`: displayValue}
        </span>
        <span
          className={`StockChange bold ${
            !isNaN(displayChangeAmount) && displayChangeAmount >= 0
              ? 'port-positive'
              : 'port-negative'
          }`}
          style={{ textAlign: 'left' }}
        >
          {!isNaN(displayChangeAmount) ? (
            <>
              {displayChangeAmount >= 0 ? '+' : ''}
              {fc(displayChangeAmount)} (
              {fc(displayChangePercentage, 2, true)}%)
            </>
          ) : (
            <span style={{ color: 'var(--action-grey)' }}>--</span>
          )}
  
          <span className="StockDuration"> {isHovering ? hoveredDateTime : filters[filter] === 'live'
              ? ' live'
              : filters[filter] === 'all time'
              ? ' all time'
              : ` past ${filters[filter]}`}
          </span>
        </span>
        {showAddCash ? (
          <div className="AddCashContainer">
            <input
              type="number"
              value={cashToAdd}
              onChange={(e) => setCashToAdd(e.target.value)}
              placeholder="Enter amount"
              className="AddCashInput"
            />
            <button onClick={handleConfirmAddCash} className="CreatePostFeedButton greenButton">Confirm</button>
            <button onClick={handleCancelAddCash} className="CreatePostFeedButton">Cancel</button>
          </div>
        ) : currentUsername === username && selectedPortfolio && (
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginTop: '0.5rem', marginBottom: '2rem' }}>
            <button
              onClick={() => setShowAddCash(true)}
              className='CreatePostFeedButton greenButton'
              style={{ width: 'fit-content' }}
            >
              Add Cash
            </button>

            {!selectedPortfolio.isMainPortfolio && (
              <button
                onClick={handleSetAsMainPortfolio}
                className='CreatePostFeedButton'
                style={{ width: 'fit-content' }}
              >
                Set as main
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="port-container" style = {{paddingTop: location.state?.fromProfile && '0'}}>
  
      {location.state?.fromProfile && <BackButton padding={"2rem 0"}callback={()=> window.history.back()}/>}
      <div className="port-main-content">
        {renderPortfolio()}
        {!dataFetched ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
             height: 'var(--stock-chart-height)'
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <div style={{ height: 'var(--stock-chart-height)'}}>
            <canvas ref={chartRef}></canvas>
          </div>
        )}
        <div className="StockFilters">
          {Object.keys(filters).map((key) => (
            <button
              key={key}
              className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`}
              onClick={() => setFilter(key)}
            >
              {key}
            </button>
          ))}
        </div>
        <div className="port-stats-filter-section">
          {balanceLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="port-stats-section">
                <div className="port-invested-section">
                  <p>
                    <span>Invested:</span> <strong className="port-value-stat">${fc(portfolioStats?.totalInvested)}</strong>
                  </p>
                  <p>
                    <span>Market value:</span> <strong className="port-value-stat">${fc(portfolioStats?.marketValue)}</strong>
                  </p>
                  <p>
                    <span>Cash: </span> <strong className="port-value-stat">${fc(cashBalance)}</strong>
                  </p>
                </div>

                <div className="port-unrealized-section">
                  <p>
                    <span>Unrealized Return: </span>
                    <strong
                      className={
                        portfolioStats?.totalUnrealizedDollar >= 0 ? 'port-positive bold' : 'port-negative bold'
                      }
                    >
                      { portfolioStats?.totalUnrealizedDollar >= 0 ? '+' : ''}
                      {fc(portfolioStats?.totalUnrealizedDollar)}&nbsp;(
                      <span
                        className={
                          portfolioStats?.totalUnrealizedPercentage >= 0
                            ? 'port-positive bold'
                            : 'port-negative bold'
                        }
                      >
                        {fc(portfolioStats?.totalUnrealizedPercentage, 2, true)}%
                      </span>
                      )
                    </strong>
                  </p>
              
                  <p>
                    <span>Buys: </span>
                    <strong className="port-value-stat">
                      {portfolioStats.totalBuys !== undefined ? fc(portfolioStats.totalBuys, 0) : "--"}
                    </strong>
                  </p>
                  <p>
                    <span>Buy Volume: </span>
                    <strong className="port-value-stat">
                      {portfolioStats.totalBuyVolume !== undefined ? `$${fc(portfolioStats?.totalBuyVolume)}` : "--"}
                    </strong>
                  </p>
                </div>

                <div className="port-buys-sells-section">
                  <p>
                      <span>Realized Return: </span>
                      <strong
                        className={
                          portfolioStats?.totalRealizedDollar >= 0 ? 'port-positive bold' : 'port-negative bold'
                        }
                      >
                        {portfolioStats?.totalRealizedDollar >= 0 ? '+' : ''}
                        {fc(portfolioStats?.totalRealizedDollar)}&nbsp;(
                        <span
                          className={
                            portfolioStats?.totalRealizedPercentage >= 0
                              ? 'port-positive bold'
                              : 'port-negative bold'
                          }
                        >
                          {fc(portfolioStats?.totalRealizedPercentage, 2, true)}%
                        </span>
                        )
                      </strong>
                    </p>
                    
                    <p>
                      <span>Sells: </span>
                      <strong className="port-value-stat">
                        {portfolioStats.totalSells !== undefined ? fc(portfolioStats.totalSells,0) : "--"}
                      </strong>
                    </p>
                    <p>
                      <span>Sell Volume: </span>
                      <strong className="port-value-stat">
                        {portfolioStats.totalSellVolume !== undefined ? `$${fc(portfolioStats?.totalSellVolume)}`: "--"}
                      </strong>
                    </p>
                </div>
              </div>
              {renderTable()}
            </>
          )}
        </div>
      </div>
  
      <div className="port-right-sidebar">
        <h2 style = {{marginBottom: 'calc(0.83rem + 1rem)'}}className="port-right-sidebar-header">Transaction history</h2>
        <div className="port-trade-history">
          {transactionsLoading ?  <LoadingSpinner /> :
          transactions.length === 0 ? (
              <span style = {{textAlign: 'left'}}className="ov-trade-type bold ov-15">No trades</span>
          ) :
          transactions.map((transaction) => renderTradeHistory(transaction))
          }
        </div>
      </div>
    </div>
  );
}
import React, { useState, useEffect, useContext, useRef } from 'react'
import axios, { all } from 'axios'
import { useNavigate } from 'react-router-dom'
import '../styles/Feed.css'
import Post from './Post'
import { AuthContext } from '../context/AuthContext'
import { ThemeContext } from '../context/ThemeContext'
import config from '../config'
import pfp from '../assets/addUser.png'
import { allowedHTML, formatTimeSince, timeSince } from '../helper/PostHelper'
import Select from 'react-select'
import {
  customStylesPlusTrans,
  customStylesTrans,
  customStyles,
} from '../helper/StylesHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  handleQuickPostSaveToDrafts,
  handleQuickPostSubmit,
} from '../helper/FeedHelper'
import { fetchUser, followUser } from '../helper/ProfileHelper'
import {
  postTypeOptions,
  categoryOptions,
  sentimentOptions,
  sortOptions,
  timeOptions,
  sortOptionsTrades,
  tradeTypeOptions,
} from '../helper/FeedHelper'
import DOMPurify from 'dompurify'
import { LoadingSpinner, formatCurrency as fc } from '../helper/StylesHelper'
import useResponsiveWidth from '../hooks/useResponsiveWidth'
import TransactionDetails from './TransactionDetails'
import {
  fetchAllPortfolios,
  fetchUserCashBalance,
  fetchTickerSuggestions,
  getDailyChange,
  handleAddCash,
  handleSellAsset,
  handleBuyAsset,
} from '../helper/MarketsHelper'

/**
 * @typedef {import('../helper/PostHelper').PostObject} PostObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 * */

const Feed = () => {
  useResponsiveWidth()

  const [activeTab, setActiveTab] = useState('all')
  const [activeTabTrade, setActiveTabTrade] = useState('all')

  const [ready, setReady] = useState(false)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [tradesLoading, setTradesLoading] = useState(true)

  const [keywords, setKeywords] = useState([])
  const [newKeyword, setNewKeyword] = useState('')

  /** @type {[PostObject[], Function]} */
  const [posts, setPosts] = useState([])

  // simply a string of transaction ids
  const [trades, setTrades] = useState([])
  const [hasMoreTrades, setHasMoreTrades] = useState(true)

  /** @type {[PostObject[], Function]} */
  //eslint-disable-next-line
  const [bookmarkedPosts, setBookmarkedPosts] = useState([])
  const [trendingPosts, setTrendingPosts] = useState([])

  const { userToken, userId, isAuthenticated } = useContext(AuthContext)
  const { tc, dk } = useContext(ThemeContext)

  /** @type {[UserObject, Function]} */
  const [currentUser, setCurrentUser] = useState(null)

  //Filtering
  const [selectedTime, setSelectedTime] = useState('Today')
  const [selectedSort, setSelectedSort] = useState('Most popular')

  const [selectedTimeTrade, setSelectedTimeTrade] = useState('Today')
  const [selectedSortTrade, setSelectedSortTrade] = useState('Best trades')
  const [selectedTradeType, setSelectedTradeType] = useState(null)

  const [selectedPostTypes, setSelectedPostTypes] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedSentiments, setSelectedSentiments] = useState([])

  // State to keep track of selected filters to display
  const [selectedFilters, setSelectedFilters] = useState([])

  //Quick post
  const [quickPostContent, setQuickPostContent] = useState('')

  //eslint-disable-next-line
  const [selectedPostTypesQuick, setSelectedPostTypesQuick] = useState([])

  //eslint-disable-next-line
  const [selectedCategoriesQuick, setSelectedCategoriesQuick] = useState([])
  //These are sent to backend when submitting a post
  const [selectedCategoryQuick, setSelectedCategoryQuick] = useState([])
  const [selectedPostTypeQuick, setSelectedPostTypeQuick] = useState('')

  //Trending accounts and user search
  const [editTrending, setEditTrending] = useState(false)
  const [editTrendingAccounts, setEditTrendingAccounts] = useState([]) //trending accounts while editing
  const [trendingAccounts, setTrendingAccounts] = useState([]) //trending accounts to display
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedAccountIndex, setSelectedAccountIndex] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const [trendingAccountsLoading, setTrendingAccountsLoading] = useState(true)
  const [trendingPostsLoading, setTrendingPostsLoading] = useState(true)

  //Pagination
  const viewMoreRef = useRef(null) // Ref for the "View more" button
  const page = useRef(0)
  const [hasMorePosts, setHasMorePosts] = useState(true)
  const [showFilters, setShowFilters] = useState(false)

  const viewMoreRefTrades = useRef(null) // Ref for the "View more" button
  const pageTrades = useRef(0)

  const [addTransactionModalOpen, setAddTransactionModalOpen] = useState(false)
  const [selectedTransactionCategory, setSelectedTransactionCategory] =
    useState('Buy')
  const [userPortfolios, setUserPortfolios] = useState([])
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)
  const [userCashBalance, setUserCashBalance] = useState(0)
  const [selectedAssetPrice, setSelectedAssetPrice] = useState(null)

  // Debounced ticker search states
  const [assetSuggestions, setAssetSuggestions] = useState([])
  const [selectedAsset, setSelectedAsset] = useState(null)
  const [placeholderText, setPlaceholderText] = useState('Select asset')
  const [quantity, setQuantity] = useState(0)
  const [shareToFeed, setShareToFeed] = useState(false)
  const [shareToFeedContent, setShareToFeedContent] = useState('')

  let debounceTimer = useRef(null)

  const debounce = (func, delay) => {
    return function (...args) {
      if (debounceTimer.current) clearTimeout(debounceTimer.current)
      debounceTimer.current = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }

  const performTickerFetch = (query) => {
    fetchTickerSuggestions(query, setAssetSuggestions, false, 'all')
  }

  const debouncedTickerFetch = debounce(performTickerFetch, 500)

  const handleTickerInputChange = (inputValue) => {
    if (inputValue.trim().length > 0) {
      debouncedTickerFetch(inputValue)
    } else {
      setAssetSuggestions([])
    }
  }

  useEffect(() => {
    fetchAllPortfolios(
      isAuthenticated,
      userToken,
      setUserPortfolios,
      setSelectedPortfolio
    )
  }, [addTransactionModalOpen, isAuthenticated, userToken])

  useEffect(() => {
    if (selectedPortfolio) {
      fetchUserCashBalance(
        isAuthenticated,
        userToken,
        setUserCashBalance,
        selectedPortfolio._id
      )
    }
  }, [selectedPortfolio, userPortfolios])

  useEffect(() => {
    if (userPortfolios) {
      setSelectedPortfolio(
        userPortfolios.find((pf) => pf._id === selectedPortfolio?._id) || null
      )
    }
  }, [userPortfolios])

  const placeholderUser = {
    _id: null,
    username: 'Placeholder',
    profilePicture: pfp,
    trendingRank: null,
  }

  const ensureArrayLength8 = (accounts) => {
    const filledAccounts = [...accounts]
    for (let i = 0; i < 8; i++) {
      if (!filledAccounts[i]) {
        filledAccounts[i] = { ...placeholderUser, trendingRank: i + 1 } // Ensure the placeholder has a trendingRank
      }
    }
    return filledAccounts
  }

  const handleFollowToggle = async (accountId, currentStatus) => {
    try {
      const newStatus = await followUser(userId, accountId, userToken)

      // Update the `trendingAccounts` state with the modified `isFollowing` status
      setTrendingAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account._id === accountId
            ? { ...account, isFollowing: newStatus }
            : account
        )
      )
    } catch (error) {
      console.error('Error toggling follow status:', error)
    }
  }

  const handleSearch = async (query) => {
    setSearchQuery(query)
    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${config.backendUrl}/api/auth/search-users`,
          {
            params: { query },
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        setSearchResults(response.data)
      } catch (error) {
        console.error('Error searching users:', error)
      }
    } else {
      setSearchResults([])
    }
  }

  const handleUserSelect = (user) => {
    setSelectedUser(user)
    setSearchQuery('')
    setSearchResults([])
  }

  useEffect(() => {
    if (selectedUser && selectedAccountIndex !== null) {
      handleSubmitEdit()
    }
    //eslint-disable-next-line
  }, [selectedUser])

  const handleSubmitEdit = () => {
    if (selectedUser && selectedAccountIndex !== null) {
      const userAlreadyExists = editTrendingAccounts.some(
        (account) => account._id === selectedUser._id
      )

      if (userAlreadyExists) {
        alert('This user is already in the trending accounts.')
        return
      }

      const updatedAccounts = [...editTrendingAccounts]

      // Insert the selected user into the correct index and set the trendingRank
      updatedAccounts[selectedAccountIndex] = {
        ...selectedUser,
        trendingRank: selectedAccountIndex + 1, // Assign the correct rank based on the placeholder's index
      }

      setEditTrendingAccounts(updatedAccounts)
      setAnchorEl(null) // Close the popover
      setSelectedUser(null) // Clear the selected user
    }
  }

  const handleCancelEdit = () => {
    setEditTrending(false)
    setEditTrendingAccounts(trendingAccounts)
  }

  const handleSaveTrendingAccounts = async () => {
    try {
      await axios.put(
        `${config.backendUrl}/api/auth/edit-trending-accounts`,
        {
          accounts: editTrendingAccounts.map((account) => ({
            userId: account._id || null,
            username: account.username || null,
            trendingRank: account.trendingRank, // Use the existing trendingRank
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )

      setTrendingAccounts(editTrendingAccounts)
      setEditTrending(false)
      alert('Trending accounts updated successfully.')
    } catch (error) {
      console.error('Error updating trending accounts:', error)
      alert('Failed to update trending accounts.')
    }
  }

  const fetchPosts = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${config.backendUrl}/api/posts`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: {
          page: page.current,
          limit: 5,
          selectedPostTypes: selectedPostTypes.map((type) => type.value),
          selectedCategories: selectedCategories.map(
            (category) => category.value
          ),
          selectedSentiments: selectedSentiments.map(
            (sentiment) => sentiment.value
          ),
          selectedTime,
          selectedSort,
          activeTab,
          keywords,
          currentUserId: userId,
        },
      })

      if (page.current === 0) {
        setPosts(response.data)
      } else {
        setPosts([...posts, ...response.data])
      }

      setHasMorePosts(response.data.length === 5)
    } catch (error) {
      console.error('Error fetching posts:', error)
    }
    setLoading(false)
  }

  const fetchTrades = async () => {
    setTradesLoading(true)
    try {
      const response = await axios.get(
        `${config.backendUrl}/api/stockActions/publicTransactions`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            page: pageTrades.current,
            limit: 5,
            selectedTime: selectedTimeTrade,
            selectedSort: selectedSortTrade,
            selectedTradeType: selectedTradeType,
            activeTab: activeTabTrade,
          },
        }
      )

      if (pageTrades.current === 0) {
        setTrades(response.data)
      } else {
        setTrades([...trades, ...response.data])
      }
      setHasMoreTrades(response.data.length === 5)
    } catch (error) {
      console.error('Error fetching trades:', error)
    }
    setTradesLoading(false)
  }

  useEffect(
    () => {
      page.current = 0
      setPosts([])
      fetchPosts()
    },
    //eslint-disable-next-line
    [
      selectedFilters,
      selectedPostTypes,
      selectedCategories,
      selectedSentiments,
      selectedTime,
      selectedSort,
      keywords,
      activeTab,
    ]
  )

  useEffect(() => {
    pageTrades.current = 0
    setTrades([])
    fetchTrades()
  }, [selectedTimeTrade, selectedSortTrade, activeTabTrade, selectedTradeType])

  useEffect(() => {
    const fetchTrendingAccounts = async () => {
      setTrendingAccountsLoading(true)
      try {
        const response = await axios.get(
          `${config.backendUrl}/api/auth/trending-accounts`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        const filledTrendingAccounts = ensureArrayLength8(response.data)
        setTrendingAccounts(filledTrendingAccounts)
        setEditTrendingAccounts(filledTrendingAccounts)
      } catch (error) {
        console.error('Error fetching posts:', error)
      }
      setTrendingAccountsLoading(false)
    }

    const fetchCurrentUser = async () => {
      try {
        const fetchedUser = await fetchUser(userId, userToken)
        setCurrentUser(fetchedUser.data)
      } catch (error) {
        console.error('Error fetching current user:', error)
      }
    }

    const fetchBookmarkedPosts = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/api/bookmarks`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        setBookmarkedPosts(response.data)
      } catch (error) {
        console.error('Error fetching bookmarked posts:', error)
      }
      setReady(true)
    }

    const fetchTrendingPosts = async () => {
      setTrendingPostsLoading(true)
      try {
        const response = await axios.get(
          `${config.backendUrl}/api/posts/trending-posts`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        setTrendingPosts(response.data)
      } catch (error) {
        console.error('Error fetching trending posts:', error)
      }
      setTrendingPostsLoading(false)
    }

    const fetchData = async () => {
      await fetchCurrentUser()
      await fetchPosts()
      await fetchBookmarkedPosts()
      //await fetchTrendingAccounts(); // commented out as per code comments
      //await fetchTrendingPosts(); // commented out as per code comments
      await fetchTrades()
    }

    fetchData()
    //eslint-disable-next-line
  }, [userToken, userId])

  const navigateToProfile = (username) => {
    if (!isAuthenticated) {
      alert('Please sign in to view profiles')
      return
    }
    navigate(`/profile/${username}`)
  }

  const handleEditTrending = () => {
    if (!currentUser?.isAdmin) {
      alert('You are not authorized to edit trending accounts')
      return
    }
    setEditTrending(!editTrending)
  }

  useEffect(() => {
    //when posts change, render the content or when user follows someone render again
    renderContent()
    //eslint-disable-next-line
  }, [posts, currentUser])

  useEffect(() => {
    const currentRef = viewMoreRef.current // Capture the current value of viewMoreRef

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMorePosts && !loading) {
          page.current += 1
          fetchPosts() // Fetch more posts when "View more" is in the viewport
        }
      },
      {
        root: null, // Observe relative to the viewport
        rootMargin: '0px', // Adjust as needed to trigger earlier
        threshold: 1.0,
      }
    )

    if (currentRef) {
      observer.observe(currentRef) // Start observing the "View more" button
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef) // Use the captured ref for cleanup
      }
    }
    // eslint-disable-next-line
  }, [hasMorePosts, loading])

  const refreshFollowingStatus = (post) => {
    post.isAuthorFollowed = !post.isAuthorFollowed
  }

  const renderContent = () => {
    const filteredPosts = posts

    if (filteredPosts.length === 0 && !loading) {
      return <div>No posts found</div>
    }

    return (
      <div>
        {filteredPosts.flatMap((post) => {
          const postElements = [
            <Post
              key={post._id}
              postId={post._id}
              profilePic={post.author.profilePicture}
              author={post.author.name}
              verified={post.author.isUserVerified}
              username={post.author.username}
              time={formatTimeSince(timeSince(post.timePosted))}
              content={post.content}
              tradeDetails={post.tradeDetails}
              title={post.title}
              sentiment={post.sentiment}
              type={post.type}
              categories={post.categories}
              taggedAssets={post.taggedAssets}
              onProfileClick={() => navigateToProfile(post.author.username)}
              bookmarked={post.isBookmarked}
              onBookmarkClick={() =>
                handleBookmarkClick(post._id, post.isBookmarked)
              }
              isQuotePost={post.isQuotePost}
              quotePostId={post.quotePostId}
              isCopyRepost={false}
              images={post.images}
              isUpvoted={post.isUpvoted}
              isDownvoted={post.isDownvoted}
              isAdmin={currentUser?.isAdmin}
              caption={post.caption}
              refreshFeed={() =>
                setPosts((posts) => posts.filter((p) => p._id !== post._id))
              }
              isAuthorFollowed={
                post.isAuthorFollowed || userId === post.author._id
              }
              authorId={post.author._id}
              refreshFollowingStatus={() => refreshFollowingStatus(post)}
              upvoteCountParam={post.upvoteCount}
              downvoteCountParam={post.downvoteCount}
              commentCountParam={post.commentCount}
              repostCountParam={post.repostCount}
              isCopyRepostedParam={post.isCopyReposted}
              transactionId={post.transactionId}
            />,
          ]

          // Conditionally render copyReposts only if the filter is 'Most recent'
          if (selectedSort === 'Most recent') {
            postElements.push(
              ...post.copyReposts.map((reposterId) => (
                <Post
                  key={`${post._id}-${reposterId}`}
                  postId={post._id}
                  profilePic={post.author.profilePicture}
                  author={post.author.name}
                  verified={post.author.isUserVerified}
                  username={post.author.username}
                  taggedAssets={post.taggedAssets}
                  time={formatTimeSince(timeSince(post.timePosted))}
                  content={post.content}
                  tradeDetails={post.tradeDetails}
                  title={post.title}
                  sentiment={post.sentiment}
                  type={post.type}
                  categories={post.categories}
                  onProfileClick={() =>
                    navigateToProfile(post.author.username, post.author._id)
                  }
                  bookmarked={post.isBookmarked}
                  onBookmarkClick={() =>
                    handleBookmarkClick(post._id, post.isBookmarked)
                  }
                  isQuotePost={post.isQuotePost}
                  quotePostId={post.quotePostId}
                  isCopyRepost={true}
                  copyReposterId={reposterId}
                  images={post.images}
                  isUpvoted={post.isUpvoted}
                  isDownvoted={post.isDownvoted}
                  isAdmin={currentUser?.isAdmin}
                  caption={post.caption}
                  refreshFeed={() =>
                    setPosts((posts) => posts.filter((p) => p._id !== post._id))
                  }
                  isAuthorFollowed={
                    post.isAuthorFollowed || userId === post.author._id
                  }
                  authorId={post.author._id}
                  refreshFollowingStatus={refreshFollowingStatus}
                  upvoteCountParam={post.upvoteCount}
                  downvoteCountParam={post.downvoteCount}
                  commentCountParam={post.commentCount}
                  repostCountParam={post.repostCount}
                  isCopyRepostedParam={post.isCopyReposted}
                  transactionId={post.transactionId}
                />
              ))
            )
          }

          return postElements
        })}

        {loading && (
          <div style={{ textAlign: 'center', margin: '1rem 0' }}>
            <LoadingSpinner />
          </div>
        )}

        {hasMorePosts && !loading && (
          <button
            ref={viewMoreRef}
            style={{ margin: '0 auto', marginTop: '1rem' }}
            className="cp-AddElementButton"
            onClick={() => {
              page.current += 1
              fetchPosts()
            }}
          >
            View more
          </button>
        )}
        {!hasMorePosts && !loading && posts.length > 0 && (
          <p style={{ textAlign: 'center', margin: '1rem 0' }}>
            No more posts to show
          </p>
        )}
      </div>
    )
  }

  const handleAddKeyword = (keyword) => {
    if (keywords.includes(keyword) || keyword.trim() === '') {
      alert('Keyword already added or empty')
      return
    }

    setKeywords((prevKeywords) => [...prevKeywords, keyword.trim()])
    setNewKeyword('')
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddKeyword(newKeyword)
    }
  }

  const handleRemoveKeyword = (keyword) => {
    setKeywords(keywords.filter((k) => k !== keyword))
  }

  const handleBookmarkClick = async (postId, isBookmarked) => {
    try {
      const url = `${config.backendUrl}/api/bookmarks/${postId}`
      const method = isBookmarked ? 'delete' : 'post'
      await axios({
        method,
        url,
        headers: { Authorization: `Bearer ${userToken}` },
      })
      const fetchBookmarkedPosts = async () => {
        try {
          const response = await axios.get(
            `${config.backendUrl}/api/bookmarks`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          setBookmarkedPosts(response.data)
        } catch (error) {
          console.error('Error fetching bookmarked posts:', error)
        }
      }
      fetchBookmarkedPosts()
    } catch (error) {
      console.error('Error updating bookmark:', error)
    }
  }

  const handleTimeChangeTrade = (selectedOption) => {
    setSelectedTimeTrade(selectedOption.value)
  }

  const handleSortTradeChange = (selectedOption) => {
    setSelectedSortTrade(selectedOption.value)
  }

  const handleTradeTypeChange = (selectedOption) => {
    setSelectedTradeType(selectedOption.value)
  }

  const handleTimeChange = (selectedOption) => {
    setSelectedTime(selectedOption.value)
  }

  const handleSortChange = (selectedOption) => {
    setSelectedSort(selectedOption.value)
  }

  const handlePostTypeChange = (selectedOption) => {
    setSelectedPostTypes(selectedOption)
  }

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategories(selectedOption)
  }

  const handleSentimentChange = (selectedOption) => {
    setSelectedSentiments(selectedOption)
  }

  const handleReset = () => {
    setSelectedPostTypes([])
    setSelectedCategories([])
    setSelectedSentiments([])
    setKeywords([])
    setSelectedFilters([])
  }

  const handleInputChange = (e) => {
    setNewKeyword(e.target.value)
  }

  const handleAccountClick = (event, index) => {
    setAnchorEl(event.currentTarget)
    setSelectedAccountIndex(index)
  }

  const getExcerpt = (content) => {
    const words = content.split(' ')
    const excerpt = words.slice(0, 30).join(' ')
    return words.length > 30 ? `${excerpt}...` : excerpt
  }

  const renderFeaturedPosts = () => {
    if (trendingPostsLoading) {
      return <LoadingSpinner />
    }
    if (trendingPosts.length === 0) {
      return <p>No trending posts available.</p>
    }

    return (
      <div className="featured-posts-list">
        {trendingPosts.map((post) => (
          <div
            className="featured-post-item"
            onClick={() => navigate(`/post/${post._id}`)}
            key={post._id}
          >
            <p className="featured-post-title bold">
              {post.title ? (
                post.title
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      getExcerpt(post.content),
                      allowedHTML
                    ),
                  }}
                />
              )}
            </p>
            <p className="featured-post-author">by {post.author.name}</p>
          </div>
        ))}
      </div>
    )
  }

  const renderWelcomeMessage = () => {
    return (
      <div className="FeedWelcome-container">
        <div className="FeedWelcome-content">
          <h1 className="FeedWelcome-title">Join the finance super app 🚀</h1>
          <p className="FeedWelcome-description">
            Join leaderboards. Copy trades. Share trades. Buy options, stocks,
            crypto. Laugh at your friends. Love Mr. Vestr.{' '}
            <span className="FeedWelcome-highlight bold">
              The retail revolution, in one product.
            </span>
          </p>
        </div>
        <button
          onClick={() => navigate('/signin')}
          className="CreatePostFeedButton greenButton moreButtonPadding FeedWelcome-create-account-btn"
        >
          Create account
        </button>
      </div>
    )
  }

  const portfolioOptions = userPortfolios.map((pf) => ({
    value: pf._id,
    label: pf.name,
  }))

  useEffect(() => {
    let interval
    if (selectedAsset) {
      // Fetch the daily change immediately when `selectedAsset` changes
      getDailyChange(selectedAsset, setSelectedAssetPrice)
      // Set up a 10-second interval
      interval = setInterval(() => {
        getDailyChange(selectedAsset, setSelectedAssetPrice)
      }, 10000)
    }
    // Cleanup the interval when `selectedAsset` becomes falsy or component unmounts
    return () => clearInterval(interval)
  }, [selectedAsset])

  const modalRef = useRef(null)

  // Function to handle clicks outside the modal
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleTransactionModalClose() // Close the modal if a click is detected outside of it
    }
  }

  useEffect(() => {
    if (addTransactionModalOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside) // Cleanup on unmount
    }
  }, [addTransactionModalOpen])

  const renderTransactionModal = () => {
    if (!addTransactionModalOpen) {
      return null
    }

    // Handler for portfolio selection
    const handlePortfolioChange = (selectedOption) => {
      const newPortfolio = userPortfolios.find(
        (pf) => pf._id === selectedOption.value
      )
      setSelectedPortfolio(newPortfolio || null)
    }

    const selectedPortfolioOption = selectedPortfolio
      ? portfolioOptions.find(
          (option) => option.value === selectedPortfolio._id
        ) || null
      : null

    return (
      <div className="FeedTransactionModal" ref={modalRef}>
        <h2 className="FeedPostHeaderH2">Add Transaction</h2>
        <div className="Feed-toggle-container">
          <button
            className={`Feed-toggle-button bold ${
              selectedTransactionCategory === 'Buy' ? 'Feed-active' : ''
            }`}
            onClick={() => setSelectedTransactionCategory('Buy')}
          >
            Buy
          </button>
          <button
            className={`Feed-toggle-button bold ${
              selectedTransactionCategory === 'Sell' ? 'Feed-active' : ''
            }`}
            onClick={() => setSelectedTransactionCategory('Sell')}
          >
            Sell
          </button>
          <button
            className={`Feed-toggle-button bold ${
              selectedTransactionCategory === 'Add cash' ? 'Feed-active' : ''
            }`}
            onClick={() => setSelectedTransactionCategory('Add cash')}
          >
            Add cash
          </button>
        </div>

        {/* Portfolio Selection */}
        <div className="FeedPortfolioSelect">
          <Select
            id="portfolio-select"
            className="FeedPortfolioDropdown"
            value={selectedPortfolioOption}
            onChange={handlePortfolioChange}
            options={portfolioOptions}
            placeholder="Select a portfolio"
            isClearable={false}
            isSearchable={false}
            unstyled
            styles={customStyles(tc(), 'var(--font-med-l)', '3.8rem')}
          />
          {selectedTransactionCategory === 'Buy' ||
          selectedTransactionCategory === 'Add cash' ? (
            <span className="FeedPortfolioSpan">{`You have $${fc(
              userCashBalance,
              2
            )} available in this portfolio.`}</span>
          ) : (
            selectedTransactionCategory === 'Sell' &&
            (selectedAsset ? (
              <span className="FeedPortfolioSpan">
                {`You have ${fc(
                  selectedPortfolio?.assets.find(
                    (asset) => asset.ticker === selectedAsset
                  )?.quantity || 0,
                  2
                )} ($${fc(
                  (selectedPortfolio?.assets.find(
                    (asset) => asset.ticker === selectedAsset
                  )?.quantity || 0) * selectedAssetPrice || 0,
                  2
                )}) shares of ${selectedAsset} available in this portfolio.`}
              </span>
            ) : (
              <span className="FeedPortfolioSpan">
                {`Select an asset to view available shares.`}
              </span>
            ))
          )}
        </div>

        {/* Asset Selection */}
        {selectedTransactionCategory !== 'Add cash' && (
          <div className="FeedPortfolioSelect">
            <Select
              value={
                selectedAsset
                  ? { value: selectedAsset, label: selectedAsset }
                  : null
              }
              onChange={(selectedOption) => {
                setSelectedAsset(selectedOption ? selectedOption.value : null)
              }}
              onInputChange={(inputValue) =>
                handleTickerInputChange(inputValue)
              }
              options={assetSuggestions.map((suggestion) => ({
                value: suggestion.ticker,
                label: `${suggestion.ticker} - ${suggestion.name}`,
              }))}
              isSearchable
              placeholder={placeholderText}
              onFocus={() => setPlaceholderText('Search for a ticker')}
              onBlur={() => setPlaceholderText('Select asset')}
              noOptionsMessage={() => null}
              unstyled
              styles={customStyles(tc(), 'var(--font-med-l)', '3.8rem')}
            />
            <span className="FeedPortfolioSpan">
              {`Price per share`} <br />
              {selectedAssetPrice ? `$${fc(selectedAssetPrice, 2)}` : '--'}
            </span>
          </div>
        )}

        {/* Input Container for Buy/Sell */}
        {selectedAsset &&
          (selectedTransactionCategory === 'Buy' ||
            selectedTransactionCategory === 'Sell') && (
            <div className="FeedInputContainer">
              <div className="FeedInputRow">
                <label className="FeedLabel">Quantity</label>
                <input
                  type="number"
                  className="FeedInputField"
                  placeholder="0"
                  min="0"
                  max={
                    selectedTransactionCategory === 'Sell'
                      ? selectedPortfolio?.assets.find(
                          (asset) => asset.ticker === selectedAsset
                        )?.quantity || 0
                      : null
                  }
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
              <div className="FeedInputRow">
                <label className="FeedLabel">Transaction total</label>
                <input
                  type="text"
                  className="FeedInputField"
                  placeholder="$0"
                  value={`$${fc(quantity * selectedAssetPrice, 2)}`}
                  readOnly
                />
              </div>
              <div className="FeedShareTransaction">
                <div className="FeedToggleRow">
                  <label className="FeedLabel">Share to feed</label>
                  <div
                    className="Feed-toggle-container"
                    style={{ width: '30%' }}
                  >
                    <button
                      className={`Feed-toggle-button bold ${
                        shareToFeed ? 'Feed-active' : ''
                      }`}
                      onClick={() => setShareToFeed(true)}
                    >
                      Yes
                    </button>
                    <button
                      className={`Feed-toggle-button bold ${
                        !shareToFeed ? 'Feed-active' : ''
                      }`}
                      onClick={() => setShareToFeed(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
                {shareToFeed && (
                  <textarea
                    className="FeedInputField"
                    placeholder="Write a caption..."
                    value={shareToFeedContent}
                    onChange={(e) => setShareToFeedContent(e.target.value)}
                    style={{ resize: 'none' }}
                  />
                )}
              </div>
            </div>
          )}

        {/* Input Container for Add Cash */}
        {selectedTransactionCategory === 'Add cash' && (
          <div className="FeedInputContainer">
            <div className="FeedInputRow">
              <label className="FeedLabel">Amount</label>
              <input
                type="number"
                className="FeedInputField"
                placeholder="0"
                min="0"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
          </div>
        )}

        <div className="Feed-buy-wrapper">
          <div
            className="Feed-toggle-container"
            style={{ gap: '1rem', border: 'none' }}
          >
            <button
              onClick={() => handleFeedTransaction()}
              className="Feed-toggle-button bold Feed-active"
            >
              {selectedTransactionCategory}
            </button>
            <button
              className="Feed-toggle-button bold"
              onClick={() => handleTransactionModalClose()}
              style={{
                backgroundColor: 'var(--background-color)',
                borderRadius: '5rem',
                color: 'var(--text-color)',
              }}
            >
              Close
            </button>
          </div>
          {selectedTransactionCategory === 'Sell' && (
            <span className="FeedPortfolioSpan">
              {`Total must be between $0.00 and $${fc(
                (selectedPortfolio?.assets.find(
                  (asset) => asset.ticker === selectedAsset
                )?.quantity || 0) * selectedAssetPrice,
                2
              )}`}
            </span>
          )}
          {selectedTransactionCategory === 'Buy' && (
            <span className="FeedPortfolioSpan">
              {`Total must be between $0.00 and $${fc(userCashBalance, 2)}`}
            </span>
          )}
        </div>
      </div>
    )
  }

  const handleFeedTransaction = async () => {
    if (shareToFeed && shareToFeedContent.trim() === '') {
      alert('Please enter a caption to share to the feed')
      return
    }
    const callback = (transactionId) => {
      fetchAllPortfolios(isAuthenticated, userToken, setUserPortfolios)
      if (shareToFeed && transactionId) {
        handleQuickPostSubmit(
          shareToFeedContent,
          null,
          null,
          transactionId,
          userToken
        )
      }
      alert(`${selectedTransactionCategory} transaction successful`)
    }
    if (selectedTransactionCategory === 'Add cash') {
      handleAddCash(quantity, userToken, selectedPortfolio?._id, callback)
    } else if (selectedTransactionCategory === 'Buy') {
      handleBuyAsset(
        isAuthenticated,
        userToken,
        quantity,
        selectedAsset,
        selectedPortfolio?._id,
        callback
      )
    } else if (selectedTransactionCategory === 'Sell') {
      handleSellAsset(
        isAuthenticated,
        userToken,
        quantity,
        selectedAsset,
        selectedPortfolio?._id,
        callback
      )
    }
  }

  const handleTransactionModalClose = () => {
    setAddTransactionModalOpen(false)
    setSelectedPortfolio(null)
    setSelectedAsset(null)
    setSelectedAssetPrice(null)
  }

  return (
    <>
      {renderTransactionModal()}
      <div className="FeedContainer">
        {!isAuthenticated && renderWelcomeMessage()}

        <div
          className="FeedSearchWrapper"
          style={{
            borderBottomLeftRadius: showFilters && 0,
            borderBottomRightRadius: showFilters && 0,
            position: 'relative',
            overflow: 'visible',
            marginTop: !isAuthenticated && '4rem', // Add margin if user is not authenticated
          }}
        >
          <div
            className="FeedSearchBarKeywordContainer"
            style={{ marginRight: keywords.length > 0 ? '1rem' : '0rem' }}
          >
            {keywords.map((keyword) => (
              <div className="FeedSearchBarKeyword" key={keyword}>
                {keyword}
                <span className="keyword-x">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => handleRemoveKeyword(keyword)}
                  />
                </span>
              </div>
            ))}
          </div>

          <input
            className="FeedSearchBar bold"
            type="text"
            placeholder="Search posts"
            value={newKeyword}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />

          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <button
              className="CreatePostFeedButton ne-search-button"
              onClick={() => {
                handleAddKeyword(newKeyword)
              }}
            >
              Search
            </button>
            <button
              className="ne-filter-button"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              {`Filters ${showFilters ? '-' : '+'}`}
            </button>
          </div>
          {showFilters && (
            <div className="ne-FilterDropdown">
              {/* Filter by Post Type */}
              <div className="ne-FilterCategory">
                <span className="ne-FilterCategoryTitle bold">Post Type</span>
                <div className="ne-FilterOptions">
                  <Select
                    className="FeedPostType"
                    value={selectedPostTypes}
                    onChange={handlePostTypeChange}
                    options={postTypeOptions}
                    isMulti
                    unstyled
                    isSearchable={false}
                    styles={customStylesPlusTrans(tc())}
                    closeMenuOnSelect={false}
                    placeholder="Post type +"
                  />
                </div>
              </div>

              {/* Filter by Category */}
              <div className="ne-FilterCategory">
                <span className="ne-FilterCategoryTitle bold">Category</span>
                <div className="ne-FilterOptions">
                  <Select
                    className="FeedCategory"
                    value={selectedCategories}
                    onChange={handleCategoryChange}
                    options={categoryOptions}
                    isMulti
                    unstyled
                    isSearchable={false}
                    styles={customStylesPlusTrans(tc())}
                    closeMenuOnSelect={false}
                    placeholder="Category +"
                  />
                </div>
              </div>

              {/* Filter by Sentiment */}
              <div className="ne-FilterCategory">
                <span className="ne-FilterCategoryTitle bold">Sentiment</span>
                <div className="ne-FilterOptions">
                  <Select
                    className="FeedSentiment"
                    value={selectedSentiments}
                    onChange={handleSentimentChange}
                    options={sentimentOptions}
                    isMulti
                    unstyled
                    isSearchable={false}
                    styles={customStylesPlusTrans(tc())}
                    closeMenuOnSelect={false}
                    placeholder="Sentiment +"
                  />
                </div>
              </div>

              <button
                className="CreatePostFeedButton transBorder"
                onClick={handleReset}
                style={{ alignSelf: 'center' }}
              >
                Reset
              </button>
            </div>
          )}
        </div>

        <div className="FeedMainContent">
          <div className="FeedContent" id="feedContent">
            <div className="FeedPostHeader">
              <h2 className="FeedPostHeaderH2">Posts</h2>
              <div className="FeedNavItems">
                {['All', 'Following', 'Friends', 'Bookmarks'].map((tab) => (
                  <button
                    key={tab}
                    className={`CreatePostFeedButton ${
                      activeTab.toLowerCase() === tab.toLowerCase()
                        ? 'greenButton'
                        : ''
                    }`}
                    onClick={() => {
                      if (!isAuthenticated && tab !== 'All') {
                        alert('Please sign in to view this tab')
                        return
                      }
                      setActiveTab(tab.toLowerCase())
                    }}
                  >
                    {tab}
                  </button>
                ))}
              </div>

              <div className="FeedInputWrapper">
                <textarea
                  className="FeedInputBar"
                  placeholder="Write something to the Vestr community!"
                  value={quickPostContent}
                  onChange={(e) => {
                    setQuickPostContent(e.target.value)
                    // Auto-resize textarea
                    e.target.style.height = 'auto'
                    e.target.style.height = `${e.target.scrollHeight}px`
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    alignSelf: 'flex-end',
                  }}
                >
                  <button
                    className="CreatePostFeedBtn"
                    onClick={() => {
                      if (!isAuthenticated) {
                        alert('Please sign in to create a post')
                        return
                      }
                      navigate('/createpost', {
                        state: {
                          quickPostContent: quickPostContent,
                          selectedCategoryQuick: selectedCategoryQuick,
                          selectedPostTypeQuick: selectedPostTypeQuick,
                          fromFeed: true,
                        },
                      })
                    }}
                  >
                    Full View
                  </button>

                  <button
                    className="CreatePostFeedBtn"
                    onClick={async () => {
                      if (!isAuthenticated) {
                        alert('Please sign in to create a post')
                        return
                      }
                      if (!quickPostContent) {
                        alert('Content cannot be empty')
                        return
                      }
                      const response = await handleQuickPostSaveToDrafts(
                        quickPostContent,
                        userToken
                      )
                      if (response.status === 201) {
                        alert('Post saved to drafts')
                        setQuickPostContent('')
                      }
                    }}
                  >
                    Save to drafts
                  </button>

                  <button
                    className="CreatePostFeedBtn"
                    style={{
                      color: 'var(--stock-change-pos)',
                      backgroundColor: 'transparent',
                      border: '0.05rem solid var(--stock-change-pos)',
                    }}
                    onClick={async () => {
                      if (!isAuthenticated) {
                        alert('Please sign in to create a post')
                        return
                      }
                      if (!quickPostContent) {
                        alert('Content cannot be empty')
                        return
                      }
                      const response = await handleQuickPostSubmit(
                        quickPostContent,
                        selectedCategoryQuick,
                        selectedPostTypeQuick,
                        null,
                        userToken
                      )
                      if (response.status === 201) {
                        alert('Post submitted successfully')
                        setQuickPostContent('')
                        setSelectedCategoriesQuick([])
                        setSelectedCategoryQuick([])
                        setSelectedPostTypesQuick([])
                        setSelectedPostTypeQuick('')
                      } else {
                        alert('Error submitting post')
                      }
                    }}
                  >
                    Post
                  </button>
                </div>
              </div>
            </div>
            <div className="FeedSubHeader"></div>
            <div className="FeedSortBarWrapper">
              <div className="FeedSortItems">
                <Select
                  className="FeedSortButton"
                  value={sortOptions.find(
                    (option) => option.value === selectedSort
                  )}
                  onChange={handleSortChange}
                  options={sortOptions}
                  isSearchable={false}
                  unstyled
                  styles={customStylesTrans(tc())}
                />
                <Select
                  className="FeedSortButton"
                  value={timeOptions.find(
                    (option) => option.value === selectedTime
                  )}
                  onChange={handleTimeChange}
                  options={timeOptions}
                  isSearchable={false}
                  unstyled
                  styles={customStylesTrans(tc())}
                  title="Filter by time"
                />
                <div style={{ display: 'flex', flex: 1 }}>
                  <input
                    className="KeywordSearchInput bold"
                    type="text"
                    placeholder="Keyword search"
                  />
                </div>
              </div>
            </div>
            {ready && renderContent()}
          </div>
          <div id="sidebar" className="RightSidebar">
            <h2 className="FeedPostHeaderH2">Trades</h2>
            <div className="FeedNavItems">
              {['All', 'Following', 'Friends', 'Subscribed'].map((tab) => (
                <button
                  key={tab}
                  className={`CreatePostFeedButton ${
                    activeTabTrade.toLowerCase() === tab.toLowerCase()
                      ? 'greenButton'
                      : ''
                  }`}
                  onClick={() => {
                    if (!isAuthenticated && tab !== 'All') {
                      alert('Please sign in to view this tab')
                      return
                    }
                    setActiveTabTrade(tab.toLowerCase())
                  }}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div className="FeedSortBarWrapper">
              <div className="FeedSortBar" style={{ paddingTop: '0rem' }}>
                <div className="FeedAddTransaction">
                  <button
                    onClick={() => setAddTransactionModalOpen(true)}
                    className="FeedAddTransactionBtn bold"
                  >
                    Add Transaction +
                  </button>
                  <input
                    className="FeedAddTransactionBtn bold"
                    type="text"
                    placeholder="Keyword search"
                  />
                </div>
                <div className="FeedSortItems" style={{ marginBottom: '2rem' }}>
                  <Select
                    className="FeedSortButton"
                    value={sortOptionsTrades.find(
                      (option) => option.value === selectedSortTrade
                    )}
                    onChange={handleSortTradeChange}
                    options={sortOptionsTrades}
                    isSearchable={false}
                    unstyled
                    styles={customStylesTrans(tc())}
                  />
                  <Select
                    className="FeedSortButton"
                    value={timeOptions.find(
                      (option) => option.value === selectedTimeTrade
                    )}
                    onChange={handleTimeChangeTrade}
                    options={timeOptions}
                    isSearchable={false}
                    unstyled
                    styles={customStylesTrans(tc())}
                    title="Filter by time"
                  />
                  <Select
                    className="FeedPostType"
                    value={tradeTypeOptions.find(
                      (option) => option.value === selectedTradeType
                    )}
                    onChange={handleTradeTypeChange}
                    options={tradeTypeOptions}
                    unstyled
                    isSearchable={false}
                    styles={customStylesTrans(tc())}
                    placeholder="Trade type"
                  />
                </div>
              </div>
            </div>

            <div className="FeedTradesContainer">
              {trades.map((trade, index) => (
                <TransactionDetails key={trade} transactionId={trade} />
              ))}

              {tradesLoading && (
                <div style={{ textAlign: 'center', margin: '1rem 0' }}>
                  <LoadingSpinner />
                </div>
              )}

              {hasMoreTrades && !tradesLoading && (
                <button
                  ref={viewMoreRefTrades}
                  style={{ margin: '0 auto', marginTop: '1rem' }}
                  className="cp-AddElementButton"
                  onClick={() => {
                    pageTrades.current += 1
                    fetchTrades()
                  }}
                >
                  View more
                </button>
              )}

              {!hasMoreTrades && !tradesLoading && trades.length > 0 && (
                <p style={{ textAlign: 'center', margin: '1rem 0' }}>
                  No more trades to show
                </p>
              )}

              {!tradesLoading && trades.length === 0 && (
                <p style={{ textAlign: 'center', margin: '1rem 0' }}>
                  No trades available
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Feed

import axios from 'axios';
import config from '../config';

export const hourOptions = 
[
    { value: '12am', label: '12am' },
    { value: '1am', label: '1am' },
    { value: '2am', label: '2am' },
    { value: '3am', label: '3am' },
    { value: '4am', label: '4am' },
    { value: '5am', label: '5am' },
    { value: '6am', label: '6am' },
    { value: '7am', label: '7am' },
    { value: '8am', label: '8am' },
    { value: '9am', label: '9am' },
    { value: '10am', label: '10am' },
    { value: '11am', label: '11am' },
    { value: '12pm', label: '12pm' },
    { value: '1pm', label: '1pm' },
    { value: '2pm', label: '2pm' },
    { value: '3pm', label: '3pm' },
    { value: '4pm', label: '4pm' },
    { value: '5pm', label: '5pm' },
    { value: '6pm', label: '6pm' },
    { value: '7pm', label: '7pm' },
    { value: '8pm', label: '8pm' },
    { value: '9pm', label: '9pm' },
    { value: '10pm', label: '10pm' },
    { value: '11pm', label: '11pm' },
]


export const minuteOptions = Array.from({ length: 12 }, (_, index) => {
    const value = (index * 5).toString().padStart(2, '0');
    return { value, label: value };
  });
  

export const monthOptions = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];


/**
 * 
 * @param {string} selectedDate
 * @param {string} selectedHour
 * @param {string} selectedMinute
 */
export function convertToScheduledAt(selectedDate, selectedHour, selectedMinute) {
    const [hourString, period] = selectedHour.match(/(\d+)(am|pm)/i).slice(1);
    let hour = parseInt(hourString, 10);
  
    // Convert to 24-hour format
    if (period.toLowerCase() === 'pm' && hour !== 12) {
      hour += 12;
    } else if (period.toLowerCase() === 'am' && hour === 12) {
      hour = 0; // Handle midnight
    }
  
    // Create a new Date object for scheduledAt
    const scheduledAt = new Date(selectedDate);
    scheduledAt.setHours(hour);
    scheduledAt.setMinutes(parseInt(selectedMinute, 10));
    scheduledAt.setSeconds(0);
    scheduledAt.setMilliseconds(0);
  
    return scheduledAt;
  }
  

/**
 * 
 * @param {string} scheduledAt (ISO 8601 string)
 */
export const parseScheduledDateTime = (scheduledAt) => {
    const dateObj = new Date(scheduledAt);
  
    // Extract the selected date (year, month, day)
    const date = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
  
    // Extract the hour (convert to 12-hour format with AM/PM)
    const hours24 = dateObj.getUTCHours();
    const hour = hours24 === 0 ? '12am' : 
                         hours24 < 12 ? `${hours24}am` : 
                         hours24 === 12 ? '12pm' : `${hours24 - 12}pm`;
  
    // Extract the minute
    const minute = dateObj.getUTCMinutes().toString().padStart(2, '0');
  
    return { date, hour, minute };
  };


  export const fetchTransactionDetails = async (transactionId, userToken) => {
    try {
      const response = await axios.get(`${config.backendUrl}/api/stockActions/transactions/transactionDetails/${transactionId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching transaction details:', error);
      return null;
    }
  };
  

  export const shareTrade = (transactionId, navigate) => {
    navigate(`/createpost`, { state: { transactionId: transactionId } });
  }

  export const handleComment = async (postId, commentContent, userToken, isDraft = false) => {
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/posts/${postId}/comments`,
        { content: commentContent, isDraft }, // Include isDraft in the body
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      return response.data;
    } catch (error) {
      console.error('Error posting comment:', error);
      return null;
    }
  };

 

import axios from 'axios';
import config from '../config';
import { DateTime } from 'luxon';
import { formatCurrency as fc, removeCommas as rc } from './StylesHelper';

// Helper function to format dates to YYYY-MM-DD in a given timezone
const formatDateInTimezone = (date, timeZone) => {
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    const parts = formatter.formatToParts(date);
    const year = parts.find(part => part.type === 'year').value;
    const month = parts.find(part => part.type === 'month').value;
    const day = parts.find(part => part.type === 'day').value;

    return `${year}-${month}-${day}`;  // Return formatted date as YYYY-MM-DD
};


export const getUnixTimestampRange = (filter) => {
    const currentDate = new Date();  // Get current time
    switch (filter) {
        case '1y':
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            break;
        case '3m':
            currentDate.setMonth(currentDate.getMonth() - 3);
            break;
        case '1m':
            currentDate.setMonth(currentDate.getMonth() - 1);
            break;
        case '1w':
            currentDate.setDate(currentDate.getDate() - 7);
            break;
        case '1d':
            currentDate.setDate(currentDate.getDate() - 1);
            break;
        case '1h':
            currentDate.setHours(currentDate.getHours() - 1);
            break;
        case 'live':
            return Math.floor(currentDate.getTime() - 300000 ); // 5 minutes ago
        default:
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            break;
    }
    // Return the Unix timestamp (in seconds)
    return Math.floor(currentDate.getTime() );
};

export const getUnixTimestamp = () => {
    return new Date().getTime();  // This gives you the timestamp in milliseconds
};

// Get the current date in EST
export const getESTDate = () => {
    const currentDate = new Date();
    return formatDateInTimezone(currentDate, 'America/New_York');  // Format date in EST
};

export const getHSTDate = () => {
    const currentDate = new Date();
    return formatDateInTimezone(currentDate, 'Pacific/Honolulu');  // Format date in HST
};  

export const getThreeMonthsAgo = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 3);
    return formatDateInTimezone(currentDate, 'America/New_York');
}

// Format large numbers (remains unchanged)
export const formatNumber = (value) => {
  if (!value) return null;
    if (value >= 1e12) {
        return (value / 1e12).toFixed(2) + 'T'; // Trillions
    } else if (value >= 1e9) {
        return (value / 1e9).toFixed(2) + 'B'; // Billions
    } else if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + 'M'; // Millions
    } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + 'K'; // Thousands
    } else {
        return value.toString(); // Less than a thousand
    }
};

export const getMarketStatus = async () => {
    try {
        const response = await axios.get(`${config.backendUrl}/api/markets/marketstatus`);
        return response.data;
    } catch (error) {
        console.error('Error fetching market status:', error);
    }
};

export const filters = {
    'live': 'live',
    '1h': 'hour',
    '1d': 'day',
    '1w': 'week',
    '1m': 'month',
    '3m': '3 months',
    '1y': 'year',
    'all time': 'all time',
};

export const filterHighMap = {
    'live': 'Live',
    '1h': 'One Hour',
    '1d': 'One Day',
    '1w': 'One week',
    '1m': 'One month',
    '3m': 'Three month',
    '1y': 'One year',
    'all time': 'All time',
};

export const intervalMap = {
    'all time': ['1', 'week'],
    '1y': ['1', 'day'],      
    '3m': ['1', 'day'],     
    '1m': ['1', 'hour'],
    '1w': ['15', 'minute'],   
    '1d': ['1', 'minute'],     
    '1h': ['14', 'second'],  
    'live': ['1', 'second'],  
};

export const portfolioIntervalMap = { 
  'all time': ['12', 'hour'],
  '1y': ['12', 'hour'],      
  '3m': ['3', 'hour'],     
  '1m': ['1', 'hour'],
  '1w': ['15', 'minute'],   
  '1d': ['1', 'minute'],     
  '1h': ['14', 'second'],  
  'live': ['1', 'second'],  

};

export const isOldCompany = (dateIPO) => {
    if (!dateIPO) return false;
    const referenceDate = new Date('2003-08-30');
    const ipoDate = new Date(dateIPO);
    return ipoDate <= referenceDate;
};


export const getLastValidTradingDay = async (marketStatus = null) => {
  return getStartOfTradingDay().toFormat('yyyy-MM-dd');
};

// Helper function to get the start of the trading day (last Monday at 1 AM PST on a valid trading week)
  export const getStartOfTradingDay = () => {
    try {
        const currentDate = DateTime.now().setZone('America/Los_Angeles');
        let tradingDate = currentDate.set({ hour: 1, minute: 0, second: 0, millisecond: 0 });

        if (currentDate < tradingDate) {
            tradingDate = tradingDate.minus({ days: 1 });
        }

        // Adjust tradingDate to the most recent valid trading day
        const isWeekend = (date) => date.weekday === 6 || date.weekday === 7; // Saturday and Sunday
        const currentYear = tradingDate.year;
        
        while (isWeekend(tradingDate) || MARKET_HOLIDAYS[currentYear].holidays.includes(tradingDate.toISODate())) {
            tradingDate = tradingDate.minus({ days: 1 });
        }

        return tradingDate; 
    } catch (error) {
        console.error('Error in getStartOfTradingDay:', error);
        return null;
    }
};

// Helper function to get the start of the trading week (last Monday at 1 AM PST on a valid trading week)
export const getStartOfTradingWeek = () => {
    try {
        const currentDate = DateTime.now().setZone('America/Los_Angeles');
        let startOfWeek = currentDate.set({ weekday: 1, hour: 1, minute: 0, second: 0, millisecond: 0 });

        if (currentDate < startOfWeek) {
            startOfWeek = startOfWeek.minus({ weeks: 1 });
        }

        // Adjust startOfWeek to the most recent valid trading day
        const currentYear = startOfWeek.year;
        
        while (MARKET_HOLIDAYS[currentYear].holidays.includes(startOfWeek.toISODate())) {
            startOfWeek = startOfWeek.minus({ days: 1 });
        }

        return startOfWeek; 
    } catch (error) {
        console.error('Error in getStartOfTradingWeek:', error);
        return null;
    }
};

export const getDateWhenClosesOrOpens = async (marketStatus = null) => { 
    try {
        if (!marketStatus) {
            marketStatus = await getMarketStatus();
        }

        // Get server time in UTC and convert it to Eastern Time using luxon
        const serverTime = marketStatus.serverTime;
        let estDate = DateTime.fromISO(serverTime, { zone: 'America/New_York' });

        const currentYear = estDate.year; // Get the current year
        let possibleLastTradingDay = estDate.toISODate(); // Format as YYYY-MM-DD

        if (marketStatus.status === 'open') {
            // Handle early close for holidays
            if (MARKET_HOLIDAYS[currentYear].earlyClose.includes(possibleLastTradingDay)) {
                estDate = estDate.set({ hour: 13, minute: 0, second: 0, millisecond: 0 }); // 1:00 PM ET (early close)
            } else {
                estDate = estDate.set({ hour: 16, minute: 0, second: 0, millisecond: 0 }); // 4:00 PM ET (regular close)
            }

            return estDate.toMillis(); // ISO time stamp in millis
        } else {
            // Adjust the date until it's a valid trading day (excluding weekends and holidays)
            const today = DateTime.now().setZone('America/New_York').toISODate();

            while (
                MARKET_HOLIDAYS[currentYear].holidays.includes(possibleLastTradingDay) || 
                estDate.weekday === 6 || // Saturday
                estDate.weekday === 7 || // Sunday
                today === possibleLastTradingDay // Same day, move to the next day
            ) {
                estDate = estDate.plus({ days: 1 });
                possibleLastTradingDay = estDate.toISODate(); // Recheck for holidays and weekends
            }

            // Set time to 9:30 AM ET (market open)
            estDate = estDate.set({ hour: 9, minute: 30, second: 0, millisecond: 0 });
            return estDate.toMillis(); // ISO time stamp in millis
        }
    } catch (error) {
        console.error('Error fetching next trading day:', error);
        return null;
    }
};



export const timespanToMilliseconds = (multiplier, timespan) => {
    const multipliers = parseInt(multiplier);
    let milliseconds = multipliers;
    switch (timespan) {
      case 'second':
      case 'seconds':
        milliseconds *= 1000;
        break;
      case 'minute':
      case 'minutes':
        milliseconds *= 60 * 1000;
        break;
      case 'hour':
      case 'hours':
        milliseconds *= 60 * 60 * 1000;
        break;
      case 'day':
      case 'days':
        milliseconds *= 24 * 60 * 60 * 1000;
        break;
      case 'week':
      case 'weeks':
        milliseconds *= 7 * 24 * 60 * 60 * 1000;
        break;
      default:
        milliseconds = null; // Handle invalid timespan
    }
    return milliseconds;
  };
  
  const lightGreen = '1DCF5B';
  const lightRed = 'FF6347';
  const darkGreen = '00FF00';
  const darkRed = 'FF0000';
  
  // Helper to convert hex to rgba with specified opacity
  const hexToRgba = (hex, opacity) => {
    const rgb = parseInt(hex.slice(1), 16);
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = rgb & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  
  // Function to determine opacity based on change
  const getOpacity = (change) => {
    if (change <= 1) return 0.2;
    if (change <= 3) return 0.3;
    if (change <= 5) return 0.4;
    if (change <= 10) return 0.5;
    if (change <= 15) return 0.6;
    return 0.7;
  };
  
  // Main function to calculate color with opacity based on change and theme
  export const getChangeColor = (change, theme = 'light') => {
    const opacity = getOpacity(Math.abs(change)); // Ensure positive values for opacity calculation
  
    // Select base colors for theme
    const baseGreen = theme === 'dark' ? darkGreen : lightGreen;
    const baseRed = theme === 'dark' ? darkRed : lightRed;
  
    // Return the appropriate color with calculated opacity
    return change >= 0
      ? hexToRgba(`#${baseGreen}`, opacity)
      : hexToRgba(`#${baseRed}`, opacity);
  };

  export const renderAfterMarketChange = (marketStatus, dailyChangeData, filter, livePrice) => {
    if (!marketStatus || ['1w', '1m', '3m', '1y', 'all time'].includes(filter)) {
        return null;
    }


    const { message } = marketStatus;
    let priceChange, priceChangePercent, duration;
    let price = rc(livePrice || dailyChangeData?.currentPrice);
    let afterHoursPercentChange = (price - dailyChangeData?.todaysClosePrice)/dailyChangeData?.todaysClosePrice * 100;
    

    if (message === 'market is closed (After-Hours).') {
        priceChange = price - dailyChangeData?.todaysClosePrice;
        priceChangePercent = afterHoursPercentChange || dailyChangeData?.afterHoursPercentChange;
        duration = 'after-hours';
    } else if (message === 'market is closed.') {
        priceChange = price - dailyChangeData?.todaysClosePrice;
        priceChangePercent = afterHoursPercentChange || dailyChangeData?.afterHoursPercentChange;
        //duration = lastValidTradingDay === getESTDate() ? 'overnight-hours' : 'after-hours';
        duration = 'after-hours';
    } else if (message === 'market is closed (Pre-Market).') {
        priceChange = price - dailyChangeData?.prevClosePrice;
        priceChangePercent = (priceChange / dailyChangeData?.prevClosePrice) * 100;
        duration = 'pre-market';
    } else {
        return null; // Market is open
    }


    return (
        <p className={`StockChange bold ${priceChangePercent >= 0 ? 'positive' : 'negative'}`}>
            {priceChangePercent >= 0 ? '+' : ''}
            {fc(priceChange)} ({fc(priceChangePercent, 2, true)}%) <span className='StockDuration'>
                {duration}
            </span>
        </p>
    );
};


// Updated buy/sell to include portfolioId

export const handleBuyAsset = async (isAuthenticated, userToken, quantity, ticker, portfolioId, callback, locationState) => {
  if (!isAuthenticated) {
    alert('Please log in to buy assets');
    return;
  }
  try {
    const response = await axios.post(
      `${config.backendUrl}/api/stockActions/buy`,
      {
        ticker,
        quantity,
        portfolioId,
        locationState,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response.status === 201) {
      const {transaction} = response.data;
      callback(transaction?._id);
    } else {

      alert(response.data?.message || 'Failed to complete the transaction.');
      console.log('Error buying asset. Please try again.');
    }

    return response;

  } catch (error) {
    console.error('Error buying asset:', error);
    alert(`Error: ${error.response?.data?.message || 'Failed to complete the transaction.'}`);
    return null;
  }
};

export const handleSellAsset = async (isAuthenticated, userToken, quantity, ticker, portfolioId, callback, locationState) => {
  if (!isAuthenticated) {
    alert('Please log in to sell assets');
    return;
  }

  
  try {
    const response = await axios.post(
      `${config.backendUrl}/api/stockActions/sell`,
      {
        ticker,
        quantity,
        portfolioId,
        locationState,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response.status === 200) {
      const {transaction} = response.data;
      callback(transaction?._id);
    } else {
      alert(response.data.message || 'Failed to complete the transaction.');
      console.log('Error selling asset. Please try again.');
    }
    return response;
  } catch (error) {
    console.error('Error selling asset:', error);
    alert(`Error: ${error.response?.data?.message || 'Failed to complete the transaction.'}`);
    return null;
  }
};

// Modify fetchAllTransactions to accept portfolioId
export const fetchAllTransactions = async (isAuthenticated, userToken, ticker, setAllTransactions, portfolioId) => {  
  if (!isAuthenticated || !portfolioId) {
    return;
  }

  try {
    const response = await axios.get(`${config.backendUrl}/api/stockActions/transactions/ticker/${portfolioId}/${ticker}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    setAllTransactions(response.data);
  } catch (error) {
    console.error('Error fetching user transactions:', error);
  }
};

// Modify fetchUserPortfolio to accept portfolioId
export const fetchUserPortfolio = async (isAuthenticated, userToken, setUserPortfolio, portfolioId) => {  
  if (!isAuthenticated || !portfolioId) {
    return;
  }
  try {
    const response = await axios.get(`${config.backendUrl}/api/stockActions/portfolio/${portfolioId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    setUserPortfolio(response.data);
  } catch (error) {
    console.error('Error fetching user portfolio:', error);
  }
};

  // Function to handle buying options
export const handleBuyOption = async (
  isAuthenticated,
  userToken,
  quantity,
  optionTicker,
  callback,
  portfolioId,
) => {
  if (!isAuthenticated) {
    alert('Please log in to buy options');
    return;
  }

  try {
    const response = await axios.post(
      `${config.backendUrl}/api/stockActions/option/buy`,
      {
        optionTicker,
        contractQuantity: quantity,
        portfolioId,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response.status === 201) {
      callback();
    } else {
      alert(response.data?.message || 'Failed to complete the transaction.');
      console.log('Error buying option. Please try again.');
    }

    return response;
  } catch (error) {
    console.error('Error buying option:', error);
    alert(
      `Error: ${error.response?.data?.message || 'Failed to complete the transaction.'}`
    );

    return null;
  }
};

// Function to handle selling options
export const handleSellOption = async (
  isAuthenticated,
  userToken,
  quantity,
  optionTicker,
  callback,
  portfolioId,
) => {
  if (!isAuthenticated) {
    alert('Please log in to sell options');
    return;
  }

  try {
    const response = await axios.post(
      `${config.backendUrl}/api/stockActions/option/sell`,
      {
        optionTicker,
        contractQuantity: quantity,
        portfolioId,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response.status === 200) {
      callback();
    } else {

      alert(response.data?.message || 'Failed to complete the transaction.');
      console.log('Error selling option. Please try again.');
    }

    return response;
  } catch (error) {
    console.error('Error selling option:', error);
    alert(
      `Error: ${error.response?.data?.message || 'Failed to complete the transaction.'}`
    );

    return null;
  }
};



export const fetchAllTransactionsGeneral = async (isAuthenticated, userToken, setAllTransactions, portfolioId) => {
  if (!isAuthenticated || !portfolioId) {
    return;
  }
  try {
    const response = await axios.get(`${config.backendUrl}/api/stockActions/transactions/all/${portfolioId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    setAllTransactions(response.data);
  } catch (error) {
    console.error('Error fetching all transactions:', error);
  }
};

export const fetchUserCashBalance = async (isAuthenticated, userToken, setCashBalance, portfolioId) => {
  if (!isAuthenticated || !portfolioId) {
    return;
  }
  try {
    const response = await axios.get(`${config.backendUrl}/api/stockActions/cash/${portfolioId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    setCashBalance(response.data);
  } catch (error) {
    console.error('Error fetching user cash balance:', error);
  }
};


  export function calculateReturns(asset, livePrice, assetTransactions) {
    // Sort transactions by date in ascending order
    const transactions = assetTransactions.sort((a, b) => new Date(a.date) - new Date(b.date));
  

    // Initialize holdings and realized gains
    let holdings = [];
    let realizedDollar = 0;
  
    // Process each transaction
    transactions.forEach(tx => {
      if (tx.type === 'buy') {
        // Add the purchased shares to holdings
        holdings.push({
          quantity: tx.quantity,
          costBasis: tx.price, // Cost per share
        });
      } else if (tx.type === 'sell') {
        let quantityToSell = tx.quantity;
  
        // Match sold shares with holdings using FIFO
        while (quantityToSell > 0 && holdings.length > 0) {
          let lot = holdings[0];
  
          if (lot.quantity <= quantityToSell) {
            // Sell the entire lot
            realizedDollar += (tx.price - lot.costBasis) * lot.quantity;
            quantityToSell -= lot.quantity;
            holdings.shift(); // Remove the sold lot
          } else {
            // Sell part of the lot
            realizedDollar += (tx.price - lot.costBasis) * quantityToSell;
            lot.quantity -= quantityToSell;
            quantityToSell = 0;
          }
        }
  
        if (quantityToSell > 0) {
          // More shares are being sold than are available in holdings
          console.error('Attempting to sell more shares than currently held.');
          // You might want to handle this case appropriately
        }
      }
    });
  
    // Calculate total invested amount and current market value
    let totalInvested = holdings.reduce((total, lot) => total + lot.quantity * lot.costBasis, 0);
    let currentMarketValue = holdings.reduce((total, lot) => total + lot.quantity * livePrice, 0);

    if (asset.assetType === 'option') {
      totalInvested = totalInvested * 100;
    }
  
    // Calculate unrealized gains/losses
    const unrealizedDollar = currentMarketValue - totalInvested;
    const unrealizedPercentage = totalInvested > 0 ? (unrealizedDollar / totalInvested) * 100 : 0;
  
    // For realized gains, we can calculate percentage based on total cost basis of shares sold
    let totalCostBasisSold = assetTransactions
      .filter(tx => tx.type === 'sell')
      .reduce((total, tx) => total + tx.quantity * tx.price, 0);


    if (asset.assetType === 'option') {
      totalCostBasisSold = totalCostBasisSold * 100;
      realizedDollar = realizedDollar * 100;

    }


    const realizedPercentage = totalCostBasisSold > 0 ? (realizedDollar / totalCostBasisSold) * 100 : 0;
  
    return {
      unrealizedDollar,
      unrealizedPercentage,
      realizedDollar,
      realizedPercentage
    };
  }

  export const getStartPriceOnDate = async (ticker, date) => {  
    try {
      const response = await axios.get(`${config.backendUrl}/api/markets/stock/${ticker}/${date}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching start price on date:', error);
      return null; // Handle error appropriately in your application
      }
    };

    // New helper function to fetch all portfolios
export const fetchAllPortfolios = async (isAuthenticated, userToken, setAllPortfolios = null, setSelectedPortfolio = null, portfolioIdToSet = null) => {
  if (!isAuthenticated) return;

  try {
    const response = await axios.get(`${config.backendUrl}/api/portfolios/userPortfolios`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    if (response.data && response.data.length > 0 && typeof setAllPortfolios === 'function') {
      setAllPortfolios(response.data);
    }
    if (response.data && response.data.length > 0 && typeof setSelectedPortfolio === 'function') {
      setSelectedPortfolio(response.data[0]);
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolios:', error);
  }
};
 

  // Fetch stock lists
  export const fetchStockLists = async (isAuthenticated, userToken, setListOptions, setSelectedLists, ticker) => {
    if (!isAuthenticated || !userToken) {
      return;
    }
    try {
      const response = await axios.get(`${config.backendUrl}/api/markets/stocklists`, {
        headers: { Authorization: `Bearer ${userToken}` },
        params: {
          includeDayData: false,
        },
      });
  
      const allLists = response.data;
  
      // Set list options to all lists
      setListOptions(allLists.map(list => ({ value: list._id, label: list.name })));
  
      // Find the lists that contain the stock
      const selectedStockLists = allLists.filter(list =>
        list.stocks.some(stock => stock.ticker.toLowerCase() === ticker.toLowerCase())
      );
  
      // Set selected lists
      setSelectedLists(selectedStockLists.map(list => ({ value: list._id, label: list.name })));
  
    } catch (error) {
      console.error('Error fetching stock lists:', error);
    }
  };

  export const handleAddToList = async (isAuthenticated, userToken, ticker, listId, name, setListOptions, setSelectedLists) => {
    if (!isAuthenticated) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/markets/stocklists/${listId}/stocks`,
        { ticker },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      if (response.status === 200) {
        alert(`${ticker} added to list "${name}"`);
        fetchStockLists(isAuthenticated, userToken, setListOptions, setSelectedLists, ticker); // Pass 'ticker' here
      } else {
        console.error("Failed to add stock to the list");
      }
    } catch (error) {
      console.error("Error adding stock to list:", error);
    }
  };

  export const handleRemoveFromList = async (isAuthenticated, userToken, ticker, listId, name, setListOptions, setSelectedLists) => {
    if (!isAuthenticated) {
      return;
    }
    try {
      const response = await axios.delete(
        `${config.backendUrl}/api/markets/stocklists/${listId}/stocks/${ticker}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      if (response.status === 200) {
        alert(`${ticker} removed from list "${name}"`);
        fetchStockLists(isAuthenticated, userToken, setListOptions, setSelectedLists, ticker); // Pass 'ticker' here
      } else {
        console.error("Failed to remove stock from the list");
      }
    } catch (error) {
      console.error("Error removing stock from list:", error);
    }
  };


 export const getDailyChange = async (ticker, setCurrentPrice, setDailyChangeData) => {
    try {
        const response = await axios.get(`${config.backendUrl}/api/markets/dailychange?ticker=${ticker}`);
        const data = response.data;
        if (typeof setCurrentPrice === 'function') {
        setCurrentPrice(data.currentPrice);
        }
        if (typeof setDailyChangeData === 'function') {
        setDailyChangeData(data);
        }
        return data;
    } catch (error) {
        console.error('Error fetching daily change:', error);
    }
};


   // Fetch daily open/close data
export const fetchDailyOpenClose = async (date, ticker, setDailyData) => {
 
     try {
         const response = await axios.get(
             `${config.backendUrl}/api/markets/daily-open-close`,  
             {
                 params: {
                     ticker,  // Pass ticker as a query parameter
                     date     // Pass date as a query parameter
                 }
             }
         );
         if (typeof setDailyData === 'function') {
         setDailyData(response.data);  // Store daily data in state
         }
         return response.data;
     } catch (error) {
         console.error('Error fetching daily open/close data from backend:', error);
     }
 };


 // Fetch EPS and calculate P/E ratio
 export const fetchEPSAndPERatio = async (ticker, setEps, setPERatio) => {
     try {
         // Make a request to your backend API
         const response = await axios.get(`${config.backendUrl}/api/markets/stock/${ticker}/eps`);

         const { eps, peRatio } = response.data;

         // Set the EPS value in state
         setEps(eps);
        
         // Set the P/E ratio if available
         if (peRatio) {
             setPERatio(peRatio);
         }
     } catch (error) {
         console.error('Error fetching EPS or P/E ratio:', error);
     }
 };
export const fetchDividendYield = async (ticker, currentPrice, setDividendYield) => {
     try {
         const response = await axios.get(
             `${config.backendUrl}/api/markets/stock/${ticker}/dividend-yield`  // Call the backend API
         );
      
         const dividendData = response.data;

         if (dividendData.dividendYield !== 'N/A') {
             // Calculate Dividend Yield
             if (currentPrice) {
                 const yieldValue = (dividendData.dividendYield / currentPrice) * 100;
                 setDividendYield(fc(yieldValue));
             }
         } else {
             setDividendYield('N/A');
         }
      
     } catch (error) {
         console.error('Error fetching dividend yield from backend:', error);
         setDividendYield('N/A');
     }
 };


 export const fetchCompanyDetails = async (ticker, setCompanyStates, setCompanyDetailsFetched) => {
  
     try {
         const companyResponse = await axios.get(
             `${config.backendUrl}/api/markets/stock/${ticker}/company-details` // Call your backend API
         );
         const companyInfo = companyResponse.data;
         if (typeof setCompanyDetailsFetched === 'function') {  
            setCompanyStates(companyInfo );
         }
         return companyInfo;
 
     } catch (error) {
         console.error('Error fetching company details from backend:', error);
     }
     if (typeof setCompanyDetailsFetched === 'function') {
     setCompanyDetailsFetched(true);
     }
 };



 export const handleAddToWatchlist = async (ticker, userToken, setIsInWatchlist) => {  
  try {
    const response = await axios.post(`${config.backendUrl}/api/markets/stocklists/watchlist/${ticker}`, null, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    
    setIsInWatchlist(true);
    getWatchList(setIsInWatchlist);
    console.log('Added to watchlist:', response.data);
  } catch (error) {
    console.error('Error adding to watchlist:', error);
  }
};
  
export const handleRemoveFromWatchlist = async (ticker, userToken, setIsInWatchlist ) => { 
  try {
    await axios.delete(`${config.backendUrl}/api/markets/stocklists/watchlist/${ticker}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    setIsInWatchlist(false);
    getWatchList(ticker, userToken, setIsInWatchlist);
    console.log('Removed from watchlist');
  } catch (error) {
    console.error('Error removing from watchlist:', error);
  }
};

export const getWatchList = async (ticker, userToken, setIsInWatchlist) => {
  try {
    const response = await axios.get(`${config.backendUrl}/api/markets/stocklists/watchlist`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    setIsInWatchlist(response.data.stocks.some((stock) => stock.ticker === ticker));
  } catch (error) {
    console.error('Error fetching watchlist:', error);
  }
};

export const getTickerNews = async (ticker) => {
  try {
    const response = await axios.get(`${config.backendUrl}/api/markets/news/${ticker}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching news:', error);
  }
}

  // Fetch ticker suggestions
  export const fetchTickerSuggestions = async (query, setSuggestions, includeChanges = false, market = "stocks", setIsSearching = null) => {
    if (!query.trim()) {
      setSuggestions([]);
      return;
    }

    if (typeof setIsSearching === 'function') {
      setIsSearching(true);
    }
    try {
      // Call the backend API for ticker suggestions
      const response = await axios.get(`${config.backendUrl}/api/markets/tickersuggestions`, {
        params: { query, includeChanges, market },
      });

      // Update the suggestions in the state
      setSuggestions(response.data || []);
      return response.data;
    } catch (error) {
      console.error('Error fetching ticker suggestions:', error);
    }

    if (typeof setIsSearching === 'function') {
      setIsSearching(false);
    }



  };
  
 export const handleSearchStock = async (e, stock, isAuthenticated, userToken, navigate, market = "stocks") => {
    if (e.key === 'Enter' && stock.trim()) {
      const ticker = stock.trim().toUpperCase();

      try {
        if (isAuthenticated) {
          await axios.post(
            `${config.backendUrl}/api/markets/users/recentsearches`,
            { ticker },
            {
              headers: { Authorization: `Bearer ${userToken}` },
            }
          );
        }
      } catch (error) {
        console.error('Error adding recent search:', error);
        alert(`Error searching for stock ${ticker}.`);
        return;
      }
        if (market === 'stocks') {
          navigate(`/stocks/${ticker}`);
        } else if (market === 'crypto') {
          navigate(`/crypto/${ticker}`);
        }
    }
  };

export const handleSuggestionClick = async (ticker, isAuthenticated, userToken, navigate, market = "stocks") => {
    try {
      if (isAuthenticated) {
        await axios.post(
          `${config.backendUrl}/api/markets/users/recentsearches`,
          { ticker },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        );
      }
      if (market === 'stocks') {
        navigate(`/stocks/${ticker}`);
      } else if (market === 'crypto') {
        navigate(`/crypto/${ticker}`);
      }

    } catch (error) {
      console.error('Error adding recent search:', error);
    }
  };


export const handleAddCash = async (amount, userToken, portfolioId, callback) => {
    try {
      if (isNaN(amount) || amount <= 0) {
        alert('Please enter a valid amount greater than 0.');
        return;
      }
      const response = await axios.post(
        `${config.backendUrl}/api/stockActions/addCash`,
        { amount, portfolioId},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      alert(response.data.message);
      if (typeof callback === 'function') {
        callback();
      }
      
    } catch (error) {
      console.error('Error adding cash:', error);
      alert(error.response?.data?.message || 'An error occurred while adding cash.');
    }
  };

export const MARKET_HOLIDAYS = {
    2024: {
      holidays: [
        "2024-01-01", // New Year's Day
        "2024-01-15", // Martin Luther King, Jr. Day
        "2024-02-19", // Washington's Birthday
        "2024-03-29", // Good Friday
        "2024-05-27", // Memorial Day
        "2024-06-19", // Juneteenth National Independence Day
        "2024-07-04", // Independence Day
        "2024-09-02", // Labor Day
        "2024-11-28", // Thanksgiving Day
        "2024-12-25"  // Christmas Day
      ],
      earlyClose: [
        "2024-07-03", // Independence Day Early Close
        "2024-11-29", // Day after Thanksgiving Early Close
        "2024-12-24"  // Christmas Eve Early Close
      ]
    },
    2025: {
      holidays: [
        "2025-01-01", // New Year's Day
        "2025-01-20", // Martin Luther King, Jr. Day
        "2025-02-17", // Washington's Birthday
        "2025-04-18", // Good Friday
        "2025-05-26", // Memorial Day
        "2025-06-19", // Juneteenth National Independence Day
        "2025-07-04", // Independence Day
        "2025-09-01", // Labor Day
        "2025-11-27", // Thanksgiving Day
        "2025-12-25"  // Christmas Day
      ],
      earlyClose: [
        "2025-07-03", // Independence Day Early Close
        "2025-11-28", // Day after Thanksgiving Early Close
        "2025-12-24"  // Christmas Eve Early Close
      ]
    },
    2026: {
      holidays: [
        "2026-01-01", // New Year's Day
        "2026-01-19", // Martin Luther King, Jr. Day
        "2026-02-16", // Washington's Birthday
        "2026-04-03", // Good Friday
        "2026-05-25", // Memorial Day
        "2026-06-19", // Juneteenth National Independence Day
        "2026-07-03", // Independence Day (Observed)
        "2026-09-07", // Labor Day
        "2026-11-26", // Thanksgiving Day
        "2026-12-25"  // Christmas Day
      ],
      earlyClose: [
        "2026-07-02", // Independence Day Early Close
        "2026-11-27", // Day after Thanksgiving Early Close
        "2026-12-24"  // Christmas Eve Early Close
      ]
    }
  }; 

export const getChangePercentageColor = (change, theme) => { 
  change = rc(change);
  if (theme === 'dark') {
      return change >= 0 ? '#00FF00' : '#FF0000';
  }

  return change >= 0 ? '#1DCF5B' : '#ff6347';
}

export const isOption = (ticker) => {
  if (typeof ticker !== 'string') {
      return false;
  }
  return ticker.startsWith('O:') 
}

export const isCrypto = (ticker) => {
  if (typeof ticker !== 'string') {
      return false;
  }
  return ticker.startsWith('X:')
}

export const isForex = (ticker) => {
  if (typeof ticker !== 'string') {
      return false;
  }
  return ticker.startsWith('C:')
}

export const isIndex = (ticker) => {
  if (typeof ticker !== 'string') {
      return false;
  }
  return ticker.startsWith('I:')
}




export const getTypeOfQuantity = (ticker) => {
  if (isOption(ticker)) {
    return 'Contracts';
  } else if (isCrypto(ticker)) {
    return 'Shares'; // Unsure about this
  } else {
    return 'Shares';
  }
}





export const handleNavigateToAsset = (navigate, ticker) => {
  if (isOption(ticker)) {
    navigate(`/options/${ticker}`);
  } else if (isCrypto(ticker)) {
    navigate(`/crypto/${ticker}`);
  } else if (isForex(ticker)) {
    //navigate(`/forex/${ticker}`); TODO: Add forex page
    alert('Forex page coming soon!');
   return;  
  } else {
    navigate(`/stocks/${ticker}`);
  }
};



export const calculatePercentageChange = (currentPrice, targetPrice) => {
  if (!currentPrice || !targetPrice) {
    return 0;
  }
  return ((targetPrice - currentPrice) / currentPrice) * 100;
};
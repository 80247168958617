import React, { useContext, useState, useEffect, useRef } from 'react';
import '../styles/CreatePost.css';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import {  formatOptionNameFromTicker} from '../helper/StylesHelper';
import { formatCurrency as fc} from '../helper/StylesHelper';
import { fetchTransactionDetails } from '../helper/CreatePostHelper';
import { useNavigate } from 'react-router-dom';
import {  getChangeColor, getChangePercentageColor, getDailyChange, getTypeOfQuantity, handleNavigateToAsset  } from '../helper/MarketsHelper';
import 'react-datepicker/dist/react-datepicker.css';


export default function TransactionDetails({transactionId}) {
    const navigate = useNavigate();
    const { userToken } = useContext(AuthContext);
    const { tc } = useContext(ThemeContext);


    const [transactionDetails, setTransactionDetails] = useState(null);
    useEffect(()=> {
        const fetchTransaction = async () => {
        if (transactionId) {
          const transaction = await fetchTransactionDetails(transactionId, userToken);
          setTransactionDetails(transaction);
        }
      }
      fetchTransaction();
      }, [transactionId]);

    const renderPostTrade = (transactionDetails) => {
        if (!transactionDetails) {
          return null;
        }
    
        const { type, ticker, currentPrice, name, username, date, quantity, assetType, copyCount, price} = transactionDetails;
    
        const formattedDate = new Date(date).toLocaleString();
        const isBuy = type === 'buy';
        const isOption = assetType === 'option';
        const isCrypto = assetType === 'crypto';
        const change = currentPrice - price;
        const changePercent = (change / price) * 100;
    
        return (
          <div className="PostTradeContainer" 
          style={{ 
             backgroundColor: isBuy ? getChangeColor(changePercent, tc()) : 'transparent',
             border: !isBuy && `0.05rem solid ${getChangePercentageColor(-changePercent, tc())}`,
          }}
          >

            <div className='PostTradeHeader'>
              <span className='PostTradeDate'><span className='bold'>{name}</span> - {formattedDate}</span>
              <span className='PostTradeType bold'>
              {isBuy ? 'BUY' : 'SELL'} - {isOption ? formatOptionNameFromTicker(ticker) : ticker}
            </span>
            </div>
    
            <div className="PostTradeContentContainer">
              <div className='PostTradeLabelAndValue'>
                <span className="PostTradeDate">
                  Price:
                </span>
                <span className='PostTradeType bold'>
                  ${fc(price)}
                </span>
                
              </div>
              <div className='PostTradeLabelAndValue'>
                <span className="PostTradeDate">
                  Current Price:
                </span>
                <span className='PostTradeType bold'>
                  ${fc(currentPrice)}
                </span>
              </div>
              <div className='PostTradeLabelAndValue'>
                <span className="PostTradeDate">
                  Price since:
                </span>
                <span className='PostTradeType bold' style = {{color:getChangePercentageColor(changePercent, tc())}}>
                {changePercent >= 0 && '+'}{fc(changePercent)}%
                </span>
              </div>
              <div className='PostTradeLabelAndValue'>
                <span className="PostTradeDate">
                  Copiers:
                </span>
                <span className='PostTradeType bold'>
                 {fc(copyCount, 0)}
                </span>
              </div>
              <div className='PostTradeLabelAndValue'>
                <span className="PostTradeDate">
                {getTypeOfQuantity(ticker)}
                </span>
                <span className='PostTradeType bold'>
                  {fc(quantity, isCrypto ? 4 : 2)}
                </span>
              </div>
              <div className='PostTradeLabelAndValue'>
                <span className="PostTradeDate">
                  Market Value:
                </span>
                <span className='PostTradeType bold'>
                  ${fc(currentPrice * quantity * (isOption ? 100 : 1))}
                </span>
              </div>
    
              </div>
    
    
              <div className='PostTradeButtonsContainer'>
                <button className='CreatePostFeedButton transBorder' onClick={()=> {
                        const isOption = assetType === 'option';
                        const isCrypto = assetType === 'crypto';
                        if (isOption) {
                          navigate(`/options/${ticker}`, {state: {isCopy: true, copyTransactionId: transactionId, isBuy }});
                        } else if (isCrypto) {
                          navigate(`/crypto/${ticker}`, {state: {isCopy: true, copyTransactionId: transactionId, isBuy }});
                        } else {
                          navigate(`/stocks/${ticker}`, {state: {isCopy: true, copyTransactionId: transactionId, isBuy }});
                        }
                }}>
                  Copy trade
                </button>
                <button className='CreatePostFeedButton transBorder' onClick={()=> {
                     handleNavigateToAsset(navigate,ticker);
                }}>
                  {ticker}
                </button>
              {username &&   <button className='CreatePostFeedButton transBorder' onClick={()=> navigate(`/profile/${username}`)}>
                  {name}
                </button>}
    
    
              </div>
    
          </div>
    
    
        )
    
    
      }

  return (
   renderPostTrade(transactionDetails)
  )
}

// src/helper/ProfileHelper.js
/**
 * @typedef {Object} UserObject
 * @property {string} _id - Unique identifier for the user.
 * @property {string} username - The username of the user.
 * @property {string} name - The name of the user.
 * @property {string} email - The email address of the user.
 * @property {string} bio - A short biography or description of the user.
 * @property {string} profilePicture - path to the user's profile picture.
 * @property {string} bannerPicture - path to the user's banner picture.
 * @property {boolean} isUserVerified - Indicates if the user is verified.
 * @property {boolean} isPublic - Indicates if the user's profile is public.
 * @property {string[]} followers - Array of user IDs that follow this user.
 * @property {string[]} following - Array of user IDs that this user follows.
 * @property {Object} social - Object containing user's social media links.
 * @property {string} social.instagram - User's Instagram URL.
 * @property {string} social.twitter - User's Twitter URL.
 * @property {string} social.youtube - User's YouTube URL.
 * @property {string} social.discord - User's Discord URL.
 * @property {string} social.tiktok - User's TikTok URL.
 * @property {string} social.substack - User's Substack URL.
 * @property {string} social.github - User's GitHub URL.
 * @property {string} social.website - User's personal website URL.
 * @property {string[]} bookmarks - Array of post IDs that the user has bookmarked.
 * @property {string[]} followers - Array of user IDs of users followers.
 * @property {string[]} following - Array of user IDs of users following.
 * @property {string[]} downvotedPosts - Array of post IDs that the user has downvoted.
 * @property {string[]} upvotedPosts - Array of post IDs that the user has upvoted.
 * @property {string[]} posts - Array of post IDs that the user made
 * @property {string[]} comments - Array of post IDs that the user commented on.
 * @property {boolean} isAdmin - Indicates if the user has admin privileges.
 * @property {boolean} isPublic - Indicates if the user is public.
 * @property {boolean} isUserVerified - Indicates if the user is verified.
 * @property {string} phone - The user's phone number.
 * 
 */

/**
 *  @typedef {import('../helper/PostHelper').PostObject} PostObject
 * */



import { calculateTotalCount } from "./PostHelper";
import axios from 'axios';
import config from '../config';

/**
 * @param {PostObject[]} userPosts
 * @param {string} selectedTime
 * @param {string} selectedSort
 * @returns {PostObject[]}
 */
export const filterPosts = (userPosts, selectedTime, selectedSort) => {
    const now = new Date();
    const filteredPostsByTime = userPosts.filter((post) => {
      const postDate = new Date(post.timePosted);
      if (selectedTime === 'Today') {
        return now - postDate < 24 * 60 * 60 * 1000; // 24 hours
      } else if (selectedTime === '7 days') {
        return now - postDate < 7 * 24 * 60 * 60 * 1000; // 7 days
      } else if (selectedTime === '1 month') {
        return now - postDate < 30 * 24 * 60 * 60 * 1000; // 30 days
      } else {
        return true; // All time
      }

    });
  
    const sortedPosts = filteredPostsByTime.sort((a, b) => {
      if (selectedSort === 'Most popular') {
        return (
          calculateTotalCount(b.upvotes) -
          calculateTotalCount(b.downvotes) -
          (calculateTotalCount(a.upvotes) - calculateTotalCount(a.downvotes))
        );
      } else if (selectedSort === 'Most recent') {
        return new Date(b.timePosted) - new Date(a.timePosted);
      } else if (selectedSort === 'Most commented') {
        return b.comments.length - a.comments.length;
      } else {
        return 0;
      }
    });
  
    return sortedPosts;
  };

export const fetchUser = async (userId, userToken) => {
    try {
      const response = await axios.get(`${config.backendUrl}/api/auth/user/id/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      return response;
    } catch (error) {
      console.error('Error fetching current user:', error);
    }

}



//current user is the user that is logged in and viewing user is the user that is being viewed
export const followUser = async (currentUserId, viewingUserId, userToken, callback = null) => {

  try {
    const response = await axios.post(
      `${config.backendUrl}/api/auth/follow`,
      { currentUserId, viewingUserId: viewingUserId },
      { headers: { Authorization: `Bearer ${userToken}` } }
    );


    if (typeof callback === 'function') {
      callback(response.data.isFollowing);
    }
    return response.data.isFollowing;

  } catch (error) {
    console.error('Error updating follow status:', error);
  }
};


export const getAuthStatus = async (userToken, username) => { 
  try {
    const response = await axios.get(`${config.backendUrl}/api/auth/status/${username}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching auth status:', error);
  }

}




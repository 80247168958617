import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../styles/Header.css";
import vestrLogo1light from "../assets/logo1light.svg";
import vestrLogo1dark from "../assets/logo1dark.svg";
import defaultPfp from "../assets/defaultPfp.jpeg";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import config from "../config";
import { debounce, set } from "lodash";
import { getImageUrl, formatCurrency as fc } from "../helper/StylesHelper";
import { ThemeContext } from "../context/ThemeContext";
import { getChangePercentageColor, fetchTickerSuggestions, handleNavigateToAsset } from "../helper/MarketsHelper";


const Header = () => {
  const {
    isAuthenticated,
    username,
    signOut,
    profilePicture,
    userToken,
  } = useContext(AuthContext);
  const { toggleTheme, tc, dk } = useContext(ThemeContext);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [indicesData, setIndicesData] = useState([]);
  const [tickerSuggestions, setTickerSuggestions] = useState([]);
  const [searchMode, setSearchMode] = useState("stocks");
  const [isSearching, setIsSearching] = useState(false);  

  const navigate = useNavigate();
  const location = useLocation();

  const scrollingContainerRef = useRef(null);
  const [scrollDuration, setScrollDuration] = useState(30); // default duration



  useEffect(() => {
    var count = 0;
    const interval1 = setInterval(() => {
      count++;
    }, 1000);
    const interval2 = setInterval(() => {
      fetchIndicesData(location.pathname.replace(/\//, ""), count);
    }, 120000);
    // Cleanup intervals on component unmount
    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      if (count) {
        fetchIndicesData(location.pathname.replace(/\//, ""), count);
      }
    };
    // eslint-disable-next-line
  }, [location]);

  
  const handleToggleSearchMode = (mode) => {
    setSearchMode(mode);
  };

  useEffect(()=> {
    setSearchResults([]);
    setTickerSuggestions([]);
  }, [searchMode])


  const fetchIndicesData = async (page, count) => {
    if (!page) {
      page = "overview";
    }
    try {
      await axios.post(
        `${config.backendUrl}/api/analytics/save`,
        {
          page: page,
          duration: count,
          token: userToken,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching indices data:", error);
    }
  };

  useEffect(() => {
    const fetchIndicesData = async () => {
      try {
        const response = await axios.get(
          `${config.backendUrl}/api/markets/indices`
        );
        setIndicesData(response.data);
      } catch (error) {
        console.error("Error fetching indices data:", error);
      }
    };

    fetchIndicesData();
  }, []);

  useEffect(() => {
    if (scrollingContainerRef.current) {
      const containerWidth = scrollingContainerRef.current.scrollWidth;
      const duration = containerWidth / 75; // Adjust speed as desired (e.g., 100px per second)
      setScrollDuration(duration);
    }
  }, [indicesData]);

  const toggleProfileModal = () => {
    setIsProfileModalVisible(!isProfileModalVisible);
  };


  const handleSignOut = () => {
    signOut();
    toggleProfileModal();
    navigate("/signin");
  };



  const handleViewProfile = () => {
    navigate(`/profile/${username}`);
  };
  const fetchSearchResults = async (query) => {
    if (!isAuthenticated) {
      alert("Please sign in to search.");
      return;
    }

    if (query.length > 0) {
      if (searchMode === "users") {
        setIsSearching(true);
        try {
          const response = await axios.get(`${config.backendUrl}/api/auth/search-users`, {
            params: { query },
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
          setSearchResults(response.data);
        } catch (error) {
          console.error("Error searching users:", error);
        }
        setIsSearching(false);
      } else if (searchMode === "stocks" || searchMode === "crypto") {
        fetchTickerSuggestions(query, setTickerSuggestions, true, searchMode, setIsSearching); 
      }
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false);
    }


 
  };

  const debouncedFetchSearchResults = useCallback(
    debounce((query) => {
      if (isAuthenticated) {
        fetchSearchResults(query);
      }
    }, 300),
    [isAuthenticated, userToken, searchMode]
  );


  // Fetch search results on search mode change
  useEffect(() => {
    setSearchResults([]);
    if (searchQuery) {
      fetchSearchResults(searchQuery);
    }
  }, [searchMode]);



  const handleSearch = (e) => {
    if (!isAuthenticated) {
      alert("Please sign in to search.");
      return;
    }


    const query = e.target.value;
    setSearchQuery(query);
    debouncedFetchSearchResults(query);
  };

  const handleSelectUser = (selectedUsername) => {
    navigate(`/profile/${selectedUsername}`);
    setSearchQuery("");
    setIsDropdownVisible(false);
  };

  return (
    <div className="HeaderContainer">
      <div className="Header">
      <div className="KeyIndicators marquee">
    <div
      className="marquee-content"
      ref={scrollingContainerRef}
      style={{ "--scroll-duration": `${scrollDuration}s` }}
    >
      {indicesData.length > 0 ? (
        indicesData.concat(indicesData).map((index, idx) => (
          <div key={idx} className="indicator">
            <span className="bold">{index?.name}</span> $
            {fc(index?.close)}{" "}
            <span
              className="bold"
              style={{
                color: getChangePercentageColor(index?.percentChange, tc()),
              }}
            >
              {index?.isPositive ? "↑" : "↓"}
              {fc(index?.percentChange, 2, true)}%
            </span>
          </div>
        ))
      ) : (
       // Placeholder for empty data
      <div className="indicator">
      <span className="bold">&nbsp;</span>{" "}
      &nbsp;&nbsp;{" "}
      <span
        className="bold"
        style={{
          color: "transparent",
        }}
      >
        &nbsp;&nbsp;
      </span>
    </div>
      )}
    </div>
  </div>
 
      </div>
      <div className="SubHeaderWrapper">
      <div className="SubHeader">

      <div className="subheader-ads" >
      <div className="vest-logo-container" onClick={() => navigate("/")}>
    <span className="vest-logo-main bold">VESTR</span>
    <img src={dk() ? vestrLogo1dark : vestrLogo1light} alt="vestr logo 1" className="vestr-logo" />
</div>
  </div>
  <div className="h-search-container" style = {{border: !searchQuery && 'none'}}>
          <input
            type="text"
            className="h-search-input"
            placeholder="Search Vestr"
            value={searchQuery}
            onChange={handleSearch}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (searchMode === "users" && searchResults.length > 0) {
                  handleSelectUser(searchQuery);
                } else if (searchMode === "stocks" && tickerSuggestions.length > 0) {
                  navigate(`/stocks/${searchQuery}`);
                } else if (searchMode === "crypto" && tickerSuggestions.length > 0) {
                  navigate(`/crypto/${searchQuery}`);
                }
              }
            }
          }

          />
      
          {isDropdownVisible && searchQuery && (
            <div className="h-search-dropdown">
                  <div className="search-toggle-buttons">
              <button

              style={{ padding: '1.2rem 3rem', width: 'calc(33% - 1rem)'}}
              className={`CreatePostFeedButton ${searchMode === "stocks" ? "greenButton" : ""}`}
              onClick={() => handleToggleSearchMode("stocks")}
            >
              Stocks
            </button>
            <button

              style={{ padding: '1.2rem 3rem', width: 'calc(33% - 1rem)'}}
              className={`CreatePostFeedButton ${searchMode === "crypto" ? "greenButton" : ""}`}
              onClick={() => handleToggleSearchMode("crypto")}
              >
              Crypto
              </button>
            <button
              style={{ padding: '1.2rem 3rem', width: 'calc(33% - 1rem)'}}
              className={`CreatePostFeedButton ${searchMode === "users" ? "greenButton" : ""}`}
              onClick={() => handleToggleSearchMode("users")}
            >
              People
            </button>
          </div>
          {searchMode === "users" && (
              searchResults.length > 0 ? (
                searchResults.map((user) => (
                  <div
                    key={user._id}
                    className="h-search-result-item"
                    onClick={() => handleSelectUser(user.username)}
                  >
                    <img
                      className="h-search-result-pic"
                      src={getImageUrl(user.profilePicture)}
                      alt={user.username}
                    />
                    <div className="h-user-info-wrapper">
                      <span className="bold">{user.name}</span>
                      <span className="h-user-span"> @{user.username}</span>
                    </div>
                  </div>
                ))
              ) : (
                !isSearching ? <div className="h-stock-item">
                <span className="bold">No results found</span>
              </div> :
              (
                <div className="h-stock-item">
                <span className="bold">Searching...</span>
              </div>
              )
              )
            )}

          {(searchMode === "stocks" || searchMode === "crypto") && (
              tickerSuggestions.length > 0 ? (
                tickerSuggestions.map((ticker) => (
                  <div
                    key={ticker.ticker}
                    className="h-stock-item"
                    onClick={() => handleNavigateToAsset(navigate, ticker?.ticker)}
                  >
                    <div className="h-stock-col t-left">
                      <span>{ticker?.name?.length > 10 ? `${ticker.name.slice(0, 20)}...` : ticker.name}</span>
                      <span className="h-stock-ticker">{ticker?.ticker}</span>
                    </div>
                    <div className="h-stock-col t-right">
                      {ticker?.currentPrice >= 0 && <span>${fc(ticker.currentPrice)}</span>}
                      {ticker?.todaysPercentChange !== null && (
                        <span 
                          className="bold"
                          style={{ color: getChangePercentageColor(ticker.todaysPercentChange, tc()) }}
                        >
                          {ticker.todaysPercentChange >= 0 ? '+' : ''}
                          {fc(ticker.todaysPercentChange)}%
                        </span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                !isSearching ? <div className="h-stock-item">
                  <span className="bold">No results found</span>
                </div> :
                (
                  <div className="h-stock-item">
                  <span className="bold">Searching...</span>
                </div>
                )
              )
            )}
              
            </div>
          )}
        </div>

          <div className="nav-items">
        
          <NavLink
            to="/feed"
            className={({ isActive }) =>
              `nav-button bold ${
                (isActive || location.pathname === "/") ? "active" : ""
              }`
            }
          >
            Feed
          </NavLink>
          {/* <NavLink
            to="/news"
            className={({ isActive }) =>
              `nav-button bold ${isActive ? "active" : ""}`
            }
          >
            News
          </NavLink> */}

          <NavLink
            to="/markets"
            className={({ isActive }) =>
              `nav-button bold ${isActive ? "active" : ""}`
            }
          >
            Markets
          </NavLink>
          <NavLink
            to="/mrvestr"
            className={({ isActive }) =>
              `nav-button bold ${isActive ? "active" : ""}`
            }
          >
            Mr. Vestr
          </NavLink>
          <NavLink
            to={`/portfolio/${username}`}
            className={({ isActive }) =>
              `nav-button bold ${isActive ? "active" : ""}`
            }
          >
            Portfolio
          </NavLink>
      
     
        </div>
        <div className="subheader-ads">
     
         
          <div className="Icons">
     
              <>
                  <img 
                    onClick={toggleProfileModal}
                    className="profile-pic-header"
                    src={isAuthenticated ? getImageUrl(profilePicture) : defaultPfp}
                    alt="Profile pic"
                  />
                 
              </>
            
        {isAuthenticated ? 
        <>
  
                    {isProfileModalVisible &&
                      (
                <div className="profile-modal">
                  <div className="profile-modal-item bold" onClick={() => {handleViewProfile();setIsProfileModalVisible(false) }}>
                    Profile
                  </div>
                  <div className="profile-modal-item bold" onClick={() => {navigate("/settings");setIsProfileModalVisible(false) }}>
                    Settings
                  </div>
                  <div className="profile-modal-item bold" onClick={() =>{ navigate("/vestrplus");setIsProfileModalVisible(false) }}>
                    Vestr+
                  </div>
                  <div className="profile-modal-item bold" onClick={() => {navigate("/terms"); setIsProfileModalVisible(false)} }>
                    Terms of Service
                  </div>
                  <div className="profile-modal-item bold" onClick={() => {navigate("/privacy"); setIsProfileModalVisible(false)} }>
                    Privacy Policy
                  </div>
                  <div className="profile-modal-item bold" onClick={() => {toggleTheme(); setIsProfileModalVisible(false)}}>
                    {dk() ? "Light Mode" : "Dark Mode"}
                  </div>
                  <div className="profile-modal-item bold" onClick={handleSignOut}>
                    Sign Out
                  </div>
                
                </div>
              )}
            </>

      : 
      <>
           {isProfileModalVisible &&
                      (
                <div className="profile-modal">
                  
                  <div className="profile-modal-item bold" onClick={()=>{ navigate('/signin'); setIsProfileModalVisible(false)}}>
                    Sign in
                  </div>
                  <div className="profile-modal-item bold" onClick={() => {navigate("/vestrplus"); setIsProfileModalVisible(false)} }>
                    Vestr+
                  </div>
                  <div className="profile-modal-item bold" onClick={() => {navigate("/terms"); setIsProfileModalVisible(false)} }>
                    Terms and Conditions
                  </div>
                  <div className="profile-modal-item bold" onClick={() => {navigate("/privacy"); setIsProfileModalVisible(false)} }>
                    Privacy Policy
                  </div>
                  <div className="profile-modal-item bold" onClick={() => {toggleTheme(); setIsProfileModalVisible(false)}}>
                    {dk() ? "Light Mode" : "Dark Mode"}
                  </div>


              
                
                </div>
              )}

      </>
    
    }
        </div>
        
        </div>

      </div>
      </div>
      </div>

  );
};

export default Header;
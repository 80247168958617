import axios from "axios";
import DataTable from "./subcomponents/DataTable";
import { useEffect, useState } from "react";
import config from "../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const AnalyticTable = () => {
  const analyticstoken = localStorage.getItem("analyticstoken");
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setdata] = useState([]);
  const [filter_date, setfilter_date] = useState("");
  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  const getData = async () => {
    setdata([]);
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/analytics/list`,
        {
          token: analyticstoken,
          page: "0",
          size: "1000",
          filter_date: filter_date,
        }
      );

      if (response.data.status === 1) {
        setdata(response.data.data.list);
      } else {
        setdata([]);
      }
    } catch (error) {
      setdata([]);
      alert(error.response.data);
    }
  };
  const changeDate = (date) => {
    setSelectedDate(date);
    const demodate = dayjs(date).format("YYYY-MM-DD");
    setfilter_date(demodate);
  };
  useEffect(() => {
    if (filter_date) {
      getData();
    }
  }, [filter_date]);

  return (
    <div style={{ padding: "2rem" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "2rem",
          alignItems: "center",
        }}
      >
        <div style={{ flexGrow: "1" }}>
          <h1>Analytics Records</h1>
        </div>
        <div style={{ flexGrow: "2" }}>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => changeDate(date)}
            dateFormat="MM/dd/yyyy"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            placeholderText="Filter with date"
          />
        </div>
        <div style={{ flexGrow: "3" }}>
          <button
            className="dt-pagination-button"
            onClick={() => {
              localStorage.removeItem("analyticstoken");
              window.location.reload();
            }}
          >
            Logout
          </button>
        </div>
      </div>

      <DataTable data={data} rowsPerPage={10} />
    </div>
  );
};

export default AnalyticTable;

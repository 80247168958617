// PortfolioChartHelper.js

import { Chart } from 'chart.js/auto';
import { formatCurrency as fc } from './StylesHelper';

// Helper function to create and update a portfolio chart
export const createPortfolioChart = ({
  chartRef,
  chartInstanceRef,
  portfolioData,
  referenceValueForChart,
  setHoveredPrice,
  setHoveredChangeAmount,
  setHoveredChangePercent,
  setHoveredDateTime,
  setIsHovering,
  theme,
}) => {

  const colorsToUse = theme === 'light' ? { 
    changePos: '#1dcf5b',
    changeNeg: '#ff6347',
  } : {
    changePos: '#00FF00',
    changeNeg: '#FF0000',
  };
  // Destroy existing chart instance to avoid memory leaks
  if (chartInstanceRef.current) {
    chartInstanceRef.current.destroy();
  }

  const ctx = chartRef.current.getContext('2d');

  // Prepare labels and data
  const labels = portfolioData.map((data) => {
    const date = new Date(data.time);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  });

  const values = portfolioData.map((data) => data.portfolioValue);

  const referenceValue = referenceValueForChart || (portfolioData.length > 0 ? portfolioData[0].portfolioValue : 0);

  // Plugin to draw horizontal line at reference value
  const horizontalLinePlugin = {
    id: 'horizontalLinePlugin',
    beforeDraw: (chart) => {
      if (chart.tooltip?._active?.length) return;
      const { ctx, chartArea: { right, left }, scales: { y } } = chart;
      ctx.save();
      ctx.strokeStyle = '#707070';
      ctx.setLineDash([5, 5]);
      ctx.beginPath();
      ctx.moveTo(left, y.getPixelForValue(referenceValue));
      ctx.lineTo(right, y.getPixelForValue(referenceValue));
      ctx.stroke();
      ctx.restore();
    },
  };

  // Plugin to draw vertical line when hovering
  const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDatasetsDraw: function (chart) {
      if (chart.tooltip && chart.tooltip.getActiveElements().length > 0) {
        const ctx = chart.ctx;
        const activePoint = chart.tooltip.getActiveElements()[0];
        const x = activePoint.element.x;
        const topY = chart.chartArea.top;
        const bottomY = chart.chartArea.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#707070'; 
        ctx.setLineDash([5, 5]);
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  // Calculate change amount for coloring
  const changeAmount = values[values.length - 1] - referenceValue;

  // Create a new chart instance
  chartInstanceRef.current = new Chart(ctx, {
    type: 'line',
    data: {
      labels,
      spanGaps: true,
      datasets: [{
        data: values,
        borderColor: changeAmount >= 0.00 ? colorsToUse.changePos : colorsToUse.changeNeg,
        fill: false,
        borderWidth: 2,
        pointRadius: function (context) {
          const index = context.dataIndex;
          const dataset = context.dataset.data;
          return index === dataset.length - 1 ? 2 : 0; // Set radius 2 for the last point, 0 for others
        },
        pointHoverRadius: 5,
        pointBackgroundColor: changeAmount >= 0.00 ?  colorsToUse.changePos : colorsToUse.changeNeg,
      }],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        y: {
          duration: 0, // Disable animation for y-axis changes
        },
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      hover: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      scales: {
        x: {
          display: false,
          grid: { display: false },
          border: { color: '#fff', display: true },
        },
        y: {
          display: false,
          grid: { display: false },
          border: { color: '#fff', display: true },
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: true,
          mode: 'nearest',
          intersect: false,
          displayColors: false, 
          callbacks: {
            label: function (context) {
              return ''; // This should remove the label text
            },
          },
          
          external: function (context) {
            const { tooltip } = context;
            if (tooltip.dataPoints) {
              const dataPoint = tooltip.dataPoints[0];
              const index = dataPoint.dataIndex;
              const price = dataPoint.parsed.y;

              // Calculate change amount and percentage from reference value
              const changeAmount = price - referenceValue;

              const changePercent = (((changeAmount / referenceValue) * 100));

              // Update state variables
              setHoveredPrice(fc(price));
              setHoveredChangeAmount((changeAmount));
              setHoveredChangePercent(changePercent);
              setHoveredDateTime(labels[index]);
            }
          },
        },
      },
      font: { family: 'SF Pro Display Medium' },
    },
    plugins: [horizontalLinePlugin, verticalLinePlugin],
  });

  // Add event listeners for mouse enter/leave
  const canvas = chartInstanceRef.current.canvas;
  const handleMouseLeave = () => setIsHovering(false);
  const handleMouseEnter = () => setIsHovering(true);
  canvas.addEventListener('mouseleave', handleMouseLeave);
  canvas.addEventListener('mouseenter', handleMouseEnter);

  // Clean up the event listener and destroy the chart on unmount or re-render
  return () => {
    canvas.removeEventListener('mouseleave', handleMouseLeave);
    canvas.removeEventListener('mouseenter', handleMouseEnter);
    chartInstanceRef.current.destroy();
  };
};
import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext'; 
import config from '../config';

export default function PaymentComplete() {
  const { userToken } = useContext(AuthContext);
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get('session_id');
    if (sessionId) {
      setLoading(true);
      axios.get(`${config.backendUrl}/api/payments/${sessionId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then(() => {
        setMessage('Your payment was successful. Thank you!');
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error updating membership:', err);
        setMessage('There was an error completing your payment. Please contact support.');
        setLoading(false);
      });
    }
  }, [location.search, userToken]);
  
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: '2rem',
    }}>
      <div style = {{fontSize: '3rem'}}>
        {loading ? 'Processing your payment...' : message}
      </div>
    </div>
  );
}
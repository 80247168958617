import axios from 'axios';
import Compressor from 'compressorjs';
import config from '../config';
import { getChangePercentageColor, getDailyChange, handleNavigateToAsset } from './MarketsHelper';
import { formatCurrency as fc } from './StylesHelper';



/**
 * @typedef {Object} PostObject
 * @property {string} _id - Unique identifier for the post.
 * @property {Object} author - The author of the post.
 * @property {string} author._id - Unique identifier for the author.
 * @property {string} author.name - The name of the post author.
 * @property {string} author.username - The username of the post author.
 * @property {string} author.profilePicture - URL to the author's profile picture.
 * @property {boolean} author.isUserVerified - Indicates if the author is verified.
 * @property {string} timePosted - The time the post was created.
 * @property {string} lastEdited - The time the post was last edited.
 * @property {string} content - The main content of the post.
 * @property {Object[]} comments - The comment ID's on the post.
 * @property {Object[]} reposts - The repost ID's of the post.
 * @property {Number} upvoteCount - The upvotes on the post.
 * @property {Number} downvoteCount - The downvotes on the post.
 * @property {Boolean} isUpvoted - Indicates if the post is upvoted by the user.
 * @property {Boolean} isDownvoted - Indicates if the post is downvoted by the user.
 * @property {Boolean} isCopyReposted - Indicates if the post is copyreposted by the user.
 * @property {string} title - The title of the post.
 * @property {string} sentiment - The sentiment associated with the post.
 * @property {string} type - The type of the post.
 * @property {string} topicLine - The topicLine of the post.
 * @property {string[]} categories - The categories of the post.
 * @property {string[]} taggedAssets - The tagged assets of the post.
 * @property {boolean} isQuotePost - Indicates if the post is a quote post.
 * @property {boolean} isDraft - Indicates if the post is a draft.
 * @property {string} quotePostId - The ID of the quoted post.
 * @property {boolean} isCopyRepost - Indicates if the post is a copy repost.
 * @property {string[]} images - The images associated with the post.
 * @property {string} caption - The caption of the post.
 * @property {Object[]} copyReposts - The copy reposts associated with the post.
 * 
 */


/**
 * @typedef {Object} QuotePostDetailsObject
 * @property {string} postId 
 * @property {Object} author 
 * @property {string} author.name 
 * @property {string} author.username 
 * @property {string} author.profilePicture 
 * @property {boolean} author.isUserVerified 
 * @property {string} content 
 * @property {string} title 
 * @property {string} timePosted 
 * @property {string} sentiment 
 * @property {string} type 
 * @property {string[]} categories 
 * @property {string[]} images  
 * @property {string[]} taggedAssets - The tagged assets of the post.
 * 
 */

/**
 *  @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 * */

//styling
export const getSentimentColor = (sentiment) => {
  if (!sentiment) return 'var(--action-grey)'; // Original gray
  switch (sentiment.toLowerCase()) {
    case 'positive':
      return 'var(--stock-change-pos)'; // Green
    case 'negative':
      return 'var(--stock-change-neg)'; // Light red
    case 'neutral':
    default:
      return 'var(--action-grey)'; // Original gray
  }
};

export const getReturnClass = (returnValue) => {
  if (returnValue.startsWith('-')) {
    return 'Negative';
  }
  return 'Positive';
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const day = date.getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // general rule
    switch (day % 10) {
      case 1:  return 'st';
      case 2:  return 'nd';
      case 3:  return 'rd';
      default: return 'th';
    }
  };

  return formattedDate.replace(`${day}`, `${day}${daySuffix(day)}`);
};

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);

  const years = Math.floor(seconds / (3600 * 24 * 365));
  const days = Math.floor((seconds % (3600 * 24 * 365)) / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return { yearsAgo: years, daysAgo: days, hoursAgo: hours, minutesAgo: minutes };
};

export const formatTimeSince = (timeSince) => {
  if (timeSince.yearsAgo > 0) return `${timeSince.yearsAgo} years ago`;
  if (timeSince.daysAgo > 0) return `${timeSince.daysAgo} days ago`;
  if (timeSince.hoursAgo > 0) return `${timeSince.hoursAgo} hours ago`;
  if (timeSince.minutesAgo > 0) return `${timeSince.minutesAgo} minutes ago`;
  return `just now`;
};


export const renderTagLine = 
(selectedType = null, selectedCategory = null, selectedSentiment = null) => { 
  const tagLine = [];
  if (selectedType) {
    tagLine.push(<span style = {{color: 'var(--action-grey)'}} key="type">{selectedType}</span>);
  }
  if ( selectedCategory.length > 0) {
    const categoryText = `${tagLine.length > 0 ? ' - ' : ''}${selectedCategory.join(', ')}`;
    tagLine.push(<span style = {{color: 'var(--action-grey)'}} key="category">{categoryText}</span>);
  }
 
  if (selectedSentiment) {  
    const sentimentText =`${selectedSentiment}`;
    tagLine.push(<><span  style = {{color: 'var(--action-grey)'}}>{tagLine.length > 0 ? ' · ' : ''}</span><span className="bold" key="sentiment" style={{ color: getSentimentColor(selectedSentiment) }}>{sentimentText}</span></>);
  }

  if (tagLine.length > 0) {
  return <>{tagLine}</>;
  } else {
    return null;
  }
};

export const renderTaggedAssets = (taggedAssets, dailyChangeData, navigate, tc) => {
  return (
    Array.isArray(taggedAssets) &&
      taggedAssets.length > 0 &&
      dailyChangeData && (
        <div className='PostTaggedAssets'>
          {taggedAssets.map((ticker, index) => {
            const percentChange = dailyChangeData[ticker]?.todaysPercentChange;
            const assetText = `${ticker}`;
            return (
              <button onClick={() => handleNavigateToAsset(navigate, ticker)} className='CreatePostFeedButton transBorder' key={ticker}>
                <span
                >
                       <span className='bold'>{assetText} </span>
  
                  <span 
                  className='bold'
                  style = {{color: getChangePercentageColor(percentChange, tc())}}
                  >
                  {percentChange
                    ? `(${percentChange >= 0 ? '+' : ''}${fc(percentChange, 2)}%)`
                    : ''}
                           </span>
                </span>
              </button>
            );
          })}
        </div>
      )
  )
}

export const fetchDailyDataForTaggedAssets = async (taggedAssets) => {
  const data = {};

  await Promise.all(
    taggedAssets.map(async (ticker) => {
      const dailyChangeData = await getDailyChange(ticker);
      data[ticker] = dailyChangeData;
    })
  );

 return data;
};


/** 
 * @param {UpvoteDownvoteObject[]} votes - Array of vote objects
 * @returns {number}
 */
export const calculateTotalCount = (votes) => votes.reduce((total, vote) => total + (vote.count || 1), 0);


//auth
/**
 * @param {Object} params 
 * @param {boolean} params.isAuthenticated 
 * @param {UserObject} params.currentUser 
 * @param {boolean} params.isUpvoted
 * @param {Function} params.setIsUpvoted 
 * @param {Function} params.setIsDownvoted 
 * @param {Function} params.setUpvoteCount 
 * @param {Function} params.setDownvoteCount 
 * @param {string} params.postId 
 * @param {string} params.userToken 
 */
export const handleUpvoteClick = async ({
  isAuthenticated,
  currentUser,
  isUpvoted,
  setIsUpvoted,
  setIsDownvoted,
  setUpvoteCount,
  setDownvoteCount,
  postId,
  userToken
}) => {
  if (!isAuthenticated) {
    alert('Please sign in to upvote posts');
    return;
  }

  try {
    if (currentUser.isAdmin && isUpvoted) {
      const response = await axios.post(
        `${config.backendUrl}/api/posts/${postId}/upvote`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        }
      );

      setUpvoteCount(response.data.upvoteCount);
      setDownvoteCount(response.data.downvoteCount);

      if (response.data.maxUpvotesRemoved) {
        setIsUpvoted(false);
        setIsDownvoted(false);
      }
    } else if (currentUser.isAdmin && !isUpvoted) {
    } else {
      const response = await axios.post(
        `${config.backendUrl}/api/posts/${postId}/upvote`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        }
      );

      setUpvoteCount(response.data.upvoteCount);
      setDownvoteCount(response.data.downvoteCount);

      if (response.data.message.includes('added')) {
        setIsUpvoted(true);
        setIsDownvoted(false);
      } else {
        setIsUpvoted(false);
        setIsDownvoted(false);
      }
    }
  } catch (error) {
    console.error('Error upvoting the post:', error);
  }
};

/**
 * 
 * @param {Object} params 
 * @param {boolean} params.isAuthenticated 
 * @param {UserObject} params.currentUser 
 * @param {boolean} params.isDownvoted 
 * @param {Function} params.setIsUpvoted 
 * @param {Function} params.setIsDownvoted 
 * @param {Function} params.setUpvoteCount 
 * @param {Function} params.setDownvoteCount 
 * @param {string} params.postId 
 * @param {string} params.userToken 
 */

export const handleDownvoteClick = async ({
  isAuthenticated,
  currentUser,
  isDownvoted,
  setIsUpvoted,
  setIsDownvoted,
  setUpvoteCount,
  setDownvoteCount,
  postId,
  userToken
}) => {
  if (!isAuthenticated) {
    alert('Please sign in to downvote posts');
    return;
  }

  try {
    if (currentUser.isAdmin && isDownvoted) {
      const response = await axios.post(
        `${config.backendUrl}/api/posts/${postId}/downvote`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        }
      );

      setUpvoteCount(response.data.upvoteCount);
      setDownvoteCount(response.data.downvoteCount);

      if (response.data.maxDownvotesRemoved) {
        setIsUpvoted(false);
        setIsDownvoted(false);
      }
    } else if (currentUser.isAdmin && !isDownvoted) {
    } else {
      const response = await axios.post(
        `${config.backendUrl}/api/posts/${postId}/downvote`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        }
      );

      setUpvoteCount(response.data.upvoteCount);
      setDownvoteCount(response.data.downvoteCount);

      if (response.data.message.includes('added')) {
        setIsDownvoted(true);
        setIsUpvoted(false);
      } else {
        setIsDownvoted(false);
        setIsUpvoted(false);
      }
    }
  } catch (error) {
    console.error('Error downvoting the post:', error);
  }
};

/**
 *  
 * @param {Object} params
 * @param {number} params.upvoteCountInput
 * @param {Function} params.setUpvoteCount
 * @param {Function} params.setDownvoteCount
 * @param {Function} params.setIsUpvoted
 * @param {Function} params.setIsDownvoted
 * @param {Function} params.closeUpvoteModal
 * @param {string} params.postId
 * @param {string} params.userToken
 */

export const submitUpvoteCount = async ({
  upvoteCountInput,
  setUpvoteCount,
  setDownvoteCount,
  setIsUpvoted,
  setIsDownvoted,
  closeUpvoteModal,
  postId,
  userToken
}) => {
  try {
    const response = await axios.post(
      `${config.backendUrl}/api/posts/${postId}/upvote`,
      { count: upvoteCountInput },
      {
        headers: {
          'Authorization': `Bearer ${userToken}`,
        },
      }
    );

    setUpvoteCount(response.data.upvoteCount);
    setDownvoteCount(response.data.downvoteCount);
    setIsUpvoted(true);
    setIsDownvoted(false);
    closeUpvoteModal();
  } catch (error) {
    console.error('Error upvoting the post:', error);
  }
};

/**
 * 
 * @param {Object} params
 * @param {number} params.downvoteCountInput
 * @param {Function} params.setUpvoteCount
 * @param {Function} params.setDownvoteCount
 * @param {Function} params.setIsDownvoted
 * @param {Function} params.setIsUpvoted
 * @param {Function} params.closeDownvoteModal
 * @param {string} params.postId
 * @param {string} params.userToken
 */
export const submitDownvoteCount = async ({
  downvoteCountInput,
  setUpvoteCount,
  setDownvoteCount,
  setIsDownvoted,
  setIsUpvoted,
  closeDownvoteModal,
  postId,
  userToken
}) => {
  try {
    const response = await axios.post(
      `${config.backendUrl}/api/posts/${postId}/downvote`,
      { count: downvoteCountInput },
      {
        headers: {
          'Authorization': `Bearer ${userToken}`,
        },
      }
    );
    setUpvoteCount(response.data.upvoteCount);
    setDownvoteCount(response.data.downvoteCount);
    setIsDownvoted(true);
    setIsUpvoted(false);
    closeDownvoteModal();
  } catch (error) {
    console.error('Error downvoting the post:', error);
  }
};



/**
 * Function to handle post deletion.
 * 
 * @param {Object} params
 * @param {string} params.postId 
 * @param {string} params.userToken 
 * @param {boolean} params.isCopyRepost
 * @param {string} params.parentPostId //For comments this will be the parents post ID
 * @param {boolean} params.isComment
 * 
 */

//eslint-disable-next-line
export const handleDeletePost = async ({ postId, userToken, isCopyRepost, parentPostId, isComment }) => {
  try {
    const response = await axios.delete(`${config.backendUrl}/api/posts/${postId}`, {
      headers: {
        'Authorization': `Bearer ${userToken}`,
      },
      data: { isCopyRepost, parentPostId }, 
    });

    if (response.status === 200) {
      return true; // Indicates success
    } else {
      console.error('Error deleting the post:', response.data.message);
      return false; // Indicates failure
    }
  } catch (error) {
    console.error('Error deleting the post:', error);
    return false;
  }
};




/**
 * Handle report post
 * @param {string} postId 
 * @param {string} userToken 
 * @param {Function} closePopover 
 * @returns 
 */
export const handleReportClick = async (postId, userToken, closePopover) => {
  const confirm = window.confirm('Are you sure you want to report this post?');
  if (!confirm) {
    closePopover();
    return;
  }
  try {
    await axios.post(
      `${config.backendUrl}/api/posts/${postId}/report`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    alert('Thank you for reporting the post. Our team will review it shortly.');
  } catch (error) {
    alert('Failed to report the post. Please try again.');
    console.error('Error reporting the post:', error);
  } finally {
    closePopover();
  }
};

/**
 * Handle trenidng rank submit
 * @param {string} postId
 * @param {string} userToken
 * @param {number} trendingRank
 * @param {Function} setTrendingRankAnchorEl
 */
export const handleTrendingRankSubmit = async (postId, userToken, trendingRank, setTrendingRankAnchorEl) => {
  try {
    await axios.put(`${config.backendUrl}/api/posts/edit-trending-post`, {
      postId,
      trendingRank,
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    alert('Trending rank updated successfully!');
    setTrendingRankAnchorEl(null); // Close popover after submission
  } catch (error) {
    console.error('Error updating trending rank:', error);
    alert('Failed to update trending rank');
  }
};




/**
 * Handle repost
 * @param {string} postId
 * @param {string} userToken
 * @param {Function} setIsCopyReposted
 * @param {Function} setRepostCount
 * @param {Function} setAnchorEl
 */
export const handleRepost = async (postId, userToken, setIsCopyReposted, setRepostCount, setAnchorEl) => {
  try {
    await axios.post(
      `${config.backendUrl}/api/posts/${postId}/repost`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    setIsCopyReposted(true);
    setRepostCount(repostCount => repostCount + 1);
  } catch (error) {
    alert(error.response.data.message);
    console.error('Error reposting the post:', error);
  } finally {
    setAnchorEl(null);
  }
};

/**
 * Handle undo repost
 * @param {string} postId
 * @param {string} userToken
 * @param {Function} setIsCopyReposted
 * @param {Function} setRepostCount
 */

export const handleUndoRepost = async (postId, userToken, setIsCopyReposted, setRepostCount) => {
  try {
    await axios.post(
      `${config.backendUrl}/api/posts/${postId}/repost`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    setRepostCount(repostCount => repostCount - 1);
    setIsCopyReposted(false);
  } catch (error) {
    alert(error.response.data.message);
    console.error('Error undo reposting the post:', error);
  }
};




//images
export const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6, // Set quality between 0 and 1 for lossy compression (0.6 is a good balance)
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
          resolve(reader.result); // Base64 string
        };
      },
      error(err) {
        reject(err);
      },
    });
  });
};


export const allowedHTML = {
  ALLOWED_TAGS: ['b', 'i', 'a'],
  ALLOWED_ATTR: ['href', 'target'],
};
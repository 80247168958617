import React, {useState, useEffect, useContext} from 'react';
import '../styles/MarketsV2.css';
import MarketSearchBar from './MarketSearchBar';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import { formatNumber, getChangePercentageColor, handleNavigateToAsset, isOption } from '../helper/MarketsHelper';
import { formatOptionNameFromTicker, formatCurrency as fc, LoadingSpinner } from '../helper/StylesHelper';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function MarketsV2() {
    useResponsiveWidth();
    const [activeIndicatorIndex, setActiveIndicatorIndex] = useState(0);

    const [stockLists, setStockLists] = useState([]);
    const [stockListsLoading, setStockListsLoading] = useState(false);

    const [keyIndicatorData, setKeyIndicatorData] = useState([]);

    // Separate states for gainers and losers and loading states
    const [gainersData, setGainersData] = useState([]);
    const [losersData, setLosersData] = useState([]);
    const [mostActiveData, setMostActiveData] = useState([]);
    const [worldMarketsData, setWorldMarketsData] = useState([]);
    const [forexData, setForexData] = useState([]);   
    


    const [gainersLoading, setGainersLoading] = useState(false);
    const [losersLoading, setLosersLoading] = useState(false);
    const [keyIndicatorsLoading, setKeyIndicatorsLoading] = useState(false);    
    const [mostActiveLoading, setMostActiveLoading] = useState(false);
    const [worldMarketsLoading, setWorldMarketsLoading] = useState(false);
    const [forexLoading, setForexLoading] = useState(false);


    const [gainersPage, setGainersPage] = useState(1);
    const [losersPage, setLosersPage] = useState(1);
    const [mostActivePage, setMostActivePage] = useState(1);
    const [worldMarketsPage, setWorldMarketsPage] = useState(1);
    const [forexPage, setForexPage] = useState(1);


    const [gainersTotalPages, setGainersTotalPages] = useState(0);
    const [losersTotalPages, setLosersTotalPages] = useState(0);
    const [mostActiveTotalPages, setMostActiveTotalPages] = useState(0);
    const [worldMarketsTotalPages, setWorldMarketsTotalPages] = useState(0);
    const [forexTotalPages, setForexTotalPages] = useState(0);

    const [selectedGainersType, setSelectedGainersType] = useState('stocks');
    const [selectedLosersType, setSelectedLosersType] = useState('stocks');
    const [selectedMostActiveType, setSelectedMostActiveType] = useState('stocks');
    const [selectedWorldMarketsType, setSelectedWorldMarketsType] = useState('us');
    const [selectedForexType, setSelctedForexType] = useState('commodities');

    const [activeTopType, setActiveTopType] = useState('stocks');
    const [activeUsFilter, setActiveTopFilter] = useState('mostactive');
    const [activeTopData, setActiveTopData] = useState([]);
    const [activeTopLoading, setActiveTopLoading] = useState(false);
    const [activeTopPage, setActiveTopPage] = useState(1);


    const [selectedExploreTab, setSelectedExploreTab] = useState('overview');

    const { isAuthenticated, userToken } = useContext(AuthContext);
    const { tc } = useContext(ThemeContext);

    const [activeWatchlist, setActiveWatchlist] = useState(null);
    const [activeListStocks, setActiveListStocks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isCreatingNewList, setIsCreatingNewList] = useState(false);
    const [newListName, setNewListName] = useState('');
    const [stock, setStock] = useState(null);
    const navigate = useNavigate();


    const CustomReferenceLine = ({ data, value, style }) => {
        if (!data || data.length === 0) return null;
    
        const points = data;
        const max = Math.max(...points);
        const min = Math.min(...points);
    
        if (max === min) return null;
    
        const percentY = (value - min) / (max - min);
        const y = (1 - percentY) * 100;
    
        return (
            <line
                x1="0"
                x2="100%"
                y1={`${y}%`}
                y2={`${y}%`}
                style={style}
            />
        );
    };

    const fetchGainers = async () => {
        setGainersLoading(true);
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/gainerslosers`, {
                params: {
                    includeDayData: true,
                    page: gainersPage,
                    assetType: selectedGainersType,
                    type: 'gainers',
                },
            });
            setGainersData(response.data.data);
            setGainersTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching gainers:', error);
        }
        setGainersLoading(false);
    };
    // Function to fetch toplist data
    const fetchToplistData = async () => {
        setActiveTopLoading(true);
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/toplist`, {
                params: {
                    assetType: activeTopType,
                    type: activeUsFilter,
                    page: activeTopPage,
                },
            });
            setActiveTopData(response.data);
        
        } catch (error) {
            console.error('Error fetching toplist data:', error.message);
        }
        setActiveTopLoading(false);
    };

    const fetchWorldMarkets = async () => { 
        setWorldMarketsLoading(true);
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/worldMarkets`, {
                params: {
                    includeDayData: true,
                    page: worldMarketsPage,
                    type: selectedWorldMarketsType,
                },
            });
            setWorldMarketsData(response.data.data);
            setWorldMarketsTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching world markets:', error);
        }
        setWorldMarketsLoading(false);
    };



    const fetchLosers = async () => {
        setLosersLoading(true);
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/gainerslosers`, {
                params: {
                    includeDayData: true,
                    page: losersPage,
                    assetType: selectedLosersType,
                    type: 'losers',
                },
            });
            setLosersData(response.data.data);
            setLosersTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching losers:', error);
        }
        setLosersLoading(false);
    };

    const fetchMostActive = async () => {   
        setMostActiveLoading(true);
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/mostactive`, {
                params: {
                    includeDayData: true,
                    page: mostActivePage,
                    assetType: selectedMostActiveType,
                },
            });
            setMostActiveData(response.data.data);
            setMostActiveTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching most active:', error);
        }
        setMostActiveLoading(false);
    };

    const fetchForex = async () => {
        setForexLoading(true);
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/forexAndCommodities`, {
                params: {
                    includeDayData: true,
                    page: forexPage,
                    assetType: selectedForexType,
                },
            });
            setForexData(response.data.data);
            setForexTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching forex:', error);
        }
        setForexLoading(false);

    }

    useEffect(() => {
        fetchForex();
    }, [selectedForexType, forexPage, selectedExploreTab]);

    useEffect(() => {
            fetchGainers();
    }, [selectedGainersType, gainersPage, selectedExploreTab]);

    useEffect(() => {
        fetchMostActive();
    }, [selectedMostActiveType, mostActivePage, selectedExploreTab]);

    useEffect(() => {
        fetchLosers();
    }, [selectedLosersType, losersPage, selectedExploreTab]);

    useEffect(() => {
        fetchWorldMarkets();
    }, [selectedWorldMarketsType, worldMarketsPage, selectedExploreTab]);

    useEffect(() => {
        fetchToplistData();
    }, [activeTopType, activeUsFilter, activeTopPage, selectedExploreTab]);

   

    const renderStockItem = (stock) => {
        if (!stock) return null;
    
        const closingPrices = stock.oneDayData?.map((dataPoint) => dataPoint.c) || [];
        let tickerToShow = isOption(stock.ticker) ? formatOptionNameFromTicker(stock.ticker) : stock.ticker;
        const chartColor = stock.todaysPercentChange >= 0 ? 'var(--stock-change-pos)' : 'var(--stock-change-neg)';
    
        return (
            <div className='mv2-stock-item' key={stock?.ticker} onClick={()=> handleNavigateToAsset(navigate, stock.ticker)}>
                <div className='mv2-stock-item-col'>
                    <span className='mv2-stock-item-span bold'>{tickerToShow}</span>
                    <span className='mv2-stock-item-span' style={{color: 'var(--action-grey)'}}>
                    {stock?.companyName?.length > 12 
                    ? `${stock.companyName.slice(0, 12)}...` 
                    : stock?.companyName ?? ''}
                    </span>
                </div>
                <div className='mv2-stockitem-graph'>
                    <Sparklines data={closingPrices} width={100} height={20} margin={5}>
                        <SparklinesLine
                            color={chartColor}
                            style={{ strokeWidth: 1, fill: 'none' }}
                            spotRadius={0}
                        />
                        <CustomReferenceLine
                            data={closingPrices}
                            value={stock.prevClosePrice}
                            style={{ stroke: 'var(--action-grey)', strokeDasharray: '2, 2', strokeWidth: 1 }}
                        />
                    </Sparklines>
                </div>
                <div className='mv2-stock-item-col'>
                    <span className='mv2-stock-item-span bold'>${fc(stock.currentPrice, 2)}</span>
                    <span
                        className='mv2-stock-item-span bold'
                        style={{ color: getChangePercentageColor(stock.todaysPercentChange, tc()) }}
                    >
                        {stock.todaysPercentChange >= 0 ? '+' : ''}
                        {fc(stock.todaysPercentChange, 2)}%
                    </span>
                </div>
            </div>
        );
    };

    const renderStockCard = (stock) => {
        if (!stock) return null;

        const closingPrices = stock.oneDayData?.map((dataPoint) => dataPoint.c) || [];
        const chartColor = stock.todaysPercentChange >= 0 ? 'var(--stock-change-pos)' : 'var(--stock-change-neg)';



        return (
            <div
        className="mv2-stock-card"
        onClick={() => handleNavigateToAsset(navigate, stock?.ticker)}
      >
        <div className='mv2-stock-card-header'>
          <span className='bold'>{formatOptionNameFromTicker(stock?.ticker)}</span>
          <span className="mv2-companyName">
  {stock?.companyName?.length > 50 ? `${stock.companyName.slice(0, 50)}...` : stock.companyName}
</span>
        </div>
        <div className='mv2-stock-card-graph'>
                    <Sparklines data={closingPrices} width={100} height={20} margin={5}>
                        <SparklinesLine
                            color={chartColor}
                            style={{ strokeWidth: 1, fill: 'none' }}
                            spotRadius={0}
                        />
                        <CustomReferenceLine
                            data={closingPrices}
                            value={stock.prevClosePrice}
                            style={{ stroke: 'var(--action-grey)', strokeDasharray: '2, 2', strokeWidth: 1 }}
                        />
                    </Sparklines>
                </div>

        <div className='mv2-stock-card-footer'>
          <span 
          className='bold'
          >
            $
            {stock?.todaysClosePrice > 0
              ? fc(stock?.todaysClosePrice)
              : fc(stock?.currentPrice)}
          </span>
          <span
            className={`bold ${
              stock?.todaysPercentChange >= 0 ? 'ma-positive' : 'ma-negative'
            }` }
          >
            {stock?.todaysPercentChange >= 0 ? '+' : ''}
            {fc(stock?.todaysPercentChange)}%
          </span>
        </div>
         </div>
        )
  
      };


  


    const renderKeyIndicators = () => {
        if (keyIndicatorsLoading) {
            return (
                <div className="mv2-keyindicators">
                <h2 className="mv2-watchlists-h2">Key Indicators</h2>
                <LoadingSpinner />
            </div>
            )

        }
     
        const dotMap = {
            'I:SPX': 'blue',
            'I:DJI': 'green',
            'I:NDX': 'red',
            'I:RUT': 'orange',
            'I:VIX': 'purple',
            'I:VXN': 'pink',
            'X:BTCUSD': 'gold',
            'X:ETHUSD': 'silver',
            'X:SOLUSD': 'teal'
        };
       
        
        const activeIndicator = keyIndicatorData[activeIndicatorIndex];
        if (!activeIndicator) {
            return null;
        }


        const {  prevClosePrice, ticker } = activeIndicator;
        const lineColor = dotMap[ticker] || 'white';
        const closingPrices = activeIndicator.oneDayData.map(p => p.c);

        const globalMin = Math.min(...closingPrices);
        const globalMax = Math.max(...closingPrices);

        return (
            <div className="mv2-keyindicators">
                <h2 className="mv2-watchlists-h2">Key Indicators</h2>
                {keyIndicatorsLoading && <LoadingSpinner />}
               {!keyIndicatorsLoading && <div className="mv2-keyindicators-grid">
                    {keyIndicatorData.map((indicator, index) => {
                        const { name, currentPrice, percentChange } = indicator;
                        const changeColor = getChangePercentageColor(percentChange, tc());

                        return (
                            <div className='mv2-indicator-wrapper' key={index}>
                                <div className="mv2-indicator-card">
                                    <div className="mv2-indicator-header">
                                        <span
                                            className="mv2-indicator-dot"
                                            style={{ backgroundColor: dotMap[indicator.ticker] }}
                                        ></span>
                                        <span className="mv2-indicator-name">{name}</span>
                                    </div>
                                    <div className="mv2-indicator-value bold">
                                        ${fc(currentPrice, 2)}
                                    </div>
                                    <div
                                        className="mv2-indicator-change bold"
                                        style={{ color: changeColor }}
                                    >
                                        {percentChange >= 0 ? '+' : ''}
                                        {fc(percentChange, 2)}%
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>}

                <div style={{ position: 'relative', backgroundColor: 'transparent' }}>
                    <Sparklines data={closingPrices} width={500} height={300} min={globalMin} max={globalMax} margin={5}>
                        <SparklinesLine
                            color={lineColor}
                            style={{ strokeWidth: 2, fill: 'none' }}
                            spotRadius={0}
                        />
                        <CustomReferenceLine
                            data={closingPrices}
                            value={prevClosePrice}
                            style={{ stroke: lineColor, strokeDasharray: '2, 2', strokeWidth: 1 }}
                        />
                    </Sparklines>
                </div>
                
                <div className="mv2-tabs">
                    {keyIndicatorData.map((indicator, index) => (
                        <div
                            key={indicator.ticker}
                            className={`stock-filter-btn ${index === activeIndicatorIndex ? 'selected' : 'not-selected'}`}
                            onClick={() => setActiveIndicatorIndex(index)}
                            style={{
                                backgroundColor: index === activeIndicatorIndex && 'var(--stock-change-pos)', 
                                width:'fit-content',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                              <div
                            className="mv2-indicator-dot"
                            style={{ backgroundColor: dotMap[indicator.ticker]  }}
                        ></div>
                            <span>{indicator.ticker.split(":")[1]}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderActiveWatchListItems = () => {
        if (stockListsLoading) {
            return <LoadingSpinner />;
        }

        if (!activeWatchlist) {
            return null;
        }

        if (activeListStocks.length === 0) {
            return <p>No stocks in this watchlist.</p>;
        }

        return (
            <div className='mv2-stock-cards-container'>
                {activeListStocks.map((stock) => renderStockCard(stock))}
            </div>
        );
    };

    const renderForexSection = () => { 
        return (
            <div className='mv2-keyindicators'>
                <h2 className="mv2-watchlists-h2">Assets</h2>
                <div className='mv2-section-filters-wrapper'>
                    <div className='mv2-section-filters'>
                        <button 
                            onClick={() => { setSelctedForexType('commodities'); setForexPage(1); }}
                            className={`CreatePostFeedButton ${selectedForexType === 'commodities' && 'greenButton'}`}
                        >
                            Commodities
                        </button>
                        <button 
                            onClick={() => { setSelctedForexType('forex'); setForexPage(1); }}
                            className={`CreatePostFeedButton ${selectedForexType === 'forex' && 'greenButton'}`}
                        >
                            Forex
                        </button>
                    </div>
                    <div className='mv2-pagination-arrows'>
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setForexPage((prev) => Math.max(prev - 1, 1))}
                            disabled={forexPage === 1}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{
                                    fontSize: '2rem',
                                    color: forexPage === 1 ? 'var(--border-color)' : 'var(--text-color)',
                                }}
                            />
                        </button>
                        
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setForexPage((prev) => Math.min(prev + 1, forexTotalPages))}
                            disabled={forexPage === forexTotalPages}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{
                                    fontSize: '2rem',
                                    color: forexPage === forexTotalPages ? 'var(--border-color)' : 'var(--text-color)'
                                }}
                            />
                        </button>
                    </div>
                </div>
                {forexLoading ? <LoadingSpinner /> : forexData.map((stock) => renderStockItem(stock))}
            </div>
        );

    };

    const renderMostActiveSection = () => {
        return (
            <div className='mv2-keyindicators'>
                <h2 className="mv2-watchlists-h2">Most Active</h2>

                <div className='mv2-section-filters-wrapper'>
                    <div className='mv2-section-filters'>
                        <button 
                            onClick={() => { setSelectedMostActiveType('stocks'); setMostActivePage(1); }}
                            className={`CreatePostFeedButton ${selectedMostActiveType === 'stocks' && 'greenButton'}`}
                        >
                            Stocks
                        </button>
                        <button 
                            onClick={() => { setSelectedMostActiveType('crypto'); setMostActivePage(1); }}
                            className={`CreatePostFeedButton ${selectedMostActiveType === 'crypto' && 'greenButton'}`}
                        >
                            Crypto
                        </button>
                    </div>
                    <div className='mv2-pagination-arrows'>
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setMostActivePage((prev) => Math.max(prev - 1, 1))}
                            disabled={mostActivePage === 1}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{
                                    fontSize: '2rem',
                                    color: mostActivePage === 1 ? 'var(--border-color)' : 'var(--text-color)',
                                }}
                            />
                        </button>
                        
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setMostActivePage((prev) => Math.min(prev + 1, mostActiveTotalPages))}
                            disabled={mostActivePage === mostActiveTotalPages}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{
                                    fontSize: '2rem',
                                    color: mostActivePage === mostActiveTotalPages ? 'var(--border-color)' : 'var(--text-color)'
                                }}
                            />
                        </button>
                    </div>
                </div>
                {mostActiveLoading ? <LoadingSpinner /> : mostActiveData.map((stock) => renderStockItem(stock))}
            </div>
        );
    };

        

    const renderGainersSection = () => {
        return (
            <div className='mv2-keyindicators'>
                <h2 className="mv2-watchlists-h2">Biggest Winners</h2>

                <div className='mv2-section-filters-wrapper'>
                    <div className='mv2-section-filters'>
                        <button 
                            onClick={() => { setSelectedGainersType('stocks'); setGainersPage(1); }}
                            className={`CreatePostFeedButton ${selectedGainersType === 'stocks' && 'greenButton'}`}
                        >
                            Stocks
                        </button>
                        <button 
                            onClick={() => { setSelectedGainersType('crypto'); setGainersPage(1); }}
                            className={`CreatePostFeedButton ${selectedGainersType === 'crypto' && 'greenButton'}`}
                        >
                            Crypto
                        </button>
                    </div>
                    <div className='mv2-pagination-arrows'>
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setGainersPage((prev) => Math.max(prev - 1, 1))}
                            disabled={gainersPage === 1}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{
                                    fontSize: '2rem',
                                    color: gainersPage === 1 ? 'var(--border-color)' : 'var(--text-color)',
                                }}
                            />
                        </button>
                        
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setGainersPage((prev) => Math.min(prev + 1, gainersTotalPages))}
                            disabled={gainersPage === gainersTotalPages}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{
                                    fontSize: '2rem',
                                    color: gainersPage === gainersTotalPages ? 'var(--border-color)' : 'var(--text-color)'
                                }}
                            />
                        </button>
                    </div>
                </div>
                {gainersLoading ? <LoadingSpinner /> : gainersData.map((stock) => renderStockItem(stock))}
            </div>
        );
    };

    const renderLosersSection = () => {
        return (
            <div className='mv2-keyindicators'>
                <h2 className="mv2-watchlists-h2">Biggest Losers</h2>
                <div className='mv2-section-filters-wrapper'>
                    <div className='mv2-section-filters'>
                        <button 
                            onClick={() => { setSelectedLosersType('stocks'); setLosersPage(1); }}
                            className={`CreatePostFeedButton ${selectedLosersType === 'stocks' && 'greenButton'}`}
                        >
                            Stocks
                        </button>
                        <button 
                            onClick={() => { setSelectedLosersType('crypto'); setLosersPage(1); }}
                            className={`CreatePostFeedButton ${selectedLosersType === 'crypto' && 'greenButton'}`}
                        >
                            Crypto
                        </button>
                    </div>
                    <div className='mv2-pagination-arrows'>
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setLosersPage((prev) => Math.max(prev - 1, 1))}
                            disabled={losersPage === 1}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{
                                    fontSize: '2rem',
                                    color: losersPage === 1 ? 'var(--border-color)' : 'var(--text-color)'
                                }}
                            />
                        </button>
                        
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setLosersPage((prev) => Math.min(prev + 1, losersTotalPages))}
                            disabled={losersPage === losersTotalPages}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{
                                    fontSize: '2rem',
                                    color: losersPage === losersTotalPages ? 'var(--border-color)' : 'var(--text-color)'
                                }}
                            />
                        </button>
                    </div>
                </div>
                {losersLoading ? <LoadingSpinner /> : losersData.map((stock) => renderStockItem(stock))}
            </div>
        );
    };

    const renderWorldMarketsSection = () => {
        return (
            <div className='mv2-keyindicators'>
                <h2 className="mv2-watchlists-h2">World Markets</h2>
                <div className='mv2-section-filters-wrapper'>
                    <div className='mv2-section-filters'>
                        <button 
                            onClick={() => { setSelectedWorldMarketsType('us'); setWorldMarketsPage(1); }}
                            className={`CreatePostFeedButton ${selectedWorldMarketsType === 'us' && 'greenButton'}`}
                        >
                            United States
                        </button>
                       
                    </div>
                    <div className='mv2-pagination-arrows'>
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setWorldMarketsPage((prev) => Math.max(prev - 1, 1))}
                            disabled={worldMarketsPage === 1}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{
                                    fontSize: '2rem',
                                    color: worldMarketsPage === 1 ? 'var(--border-color)' : 'var(--text-color)',
                                }}
                            />
                        </button>
                        
                        <button
                            className='mv2-pagination-button'
                            onClick={() => setWorldMarketsPage((prev) => Math.min(prev + 1, worldMarketsTotalPages))}
                            disabled={worldMarketsPage === worldMarketsTotalPages}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{
                                    fontSize: '2rem',
                                    color: worldMarketsPage === worldMarketsTotalPages ? 'var(--border-color)' : 'var(--text-color)'
                                }}
                            />
                        </button>
                    </div>
                </div>
                {worldMarketsLoading ? <LoadingSpinner /> : worldMarketsData.map((stock) => renderStockItem(stock))}
            </div>
        );

    }

    const renderActiveTopSection = () => {
        if (!activeTopData || !activeTopData.data) {
            return null;
        }
    
        let page = activeTopData.page;
        let totalPages = activeTopData.totalPages;
        let data = activeTopData.data;
    
        return (
            <div className="mv2-us-container">
              
                <div className='mv2-section-filters-wrapper' style={{ margin: 0 }}>
                    <div className="mv2-section-filters">
                        <button onClick={() => { setActiveTopPage(1); setActiveTopFilter('mostactive'); }} className={`CreatePostFeedButton ${activeUsFilter === 'mostactive' && 'greenButton'}`}>Most active</button>
                        <button onClick={() => { setActiveTopPage(1); setActiveTopFilter('marketcap'); }} className={`CreatePostFeedButton ${activeUsFilter === 'marketcap' && 'greenButton'}`}>Market cap</button>
                        <button onClick={() => { setActiveTopPage(1); setActiveTopFilter('trending'); }} className={`CreatePostFeedButton ${activeUsFilter === 'trending' && 'greenButton'}`}>Trending</button>
                    </div>
    
                    <div className="mv2-pagination-arrows">
                        <button
                            className="mv2-pagination-button"
                            onClick={() => setActiveTopPage((prev) => Math.max(prev - 1, 1))}
                            disabled={activeTopPage === 1}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                style={{
                                    fontSize: '2rem',
                                    color: activeTopPage === 1 ? 'var(--border-color)' : 'var(--text-color)',
                                }}
                            />
                        </button>
    
                        <button
                            className="mv2-pagination-button"
                            onClick={() => setActiveTopPage((prev) => Math.min(prev + 1, totalPages))}
                            disabled={activeTopPage === totalPages}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{
                                    fontSize: '2rem',
                                    color: activeTopPage === totalPages ? 'var(--border-color)' : 'var(--text-color)',
                                }}
                            />
                        </button>
                    </div>
                </div>
    
                {/* Wrap the table area with a min-height container to prevent layout shifting */}
                <div style={{ minHeight: '80rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {activeTopLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <table className="mv2-table">
                            <thead className='mv2-thead'>
                                <tr className='mv2-thead-tr'>
                                    <th></th>
                                    <th>Symbol</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>1d change</th>
                                    <th>Volume</th>
                                    {activeTopType === 'stocks' && <th>Market cap</th>}
                                    {activeTopType === 'stocks' && <th>P/E Ratio</th>}
                                </tr>
                            </thead>
                            <tbody className='mv2-tbody'>
                                {data.map((item, index) => (
                                    <tr
                                        style={{ cursor: 'pointer' }}
                                        key={index} 
                                        onClick={() => handleNavigateToAsset(navigate, item?.ticker)}
                                    >
                                        <td className='mv2-table-rank'>{(page - 1) * data.length + (index + 1)}</td>
                                        <td>{item?.ticker || '--'}</td>
                                        <td>{item?.name || '--'}</td>
                                        <td>
                                            ${fc(item?.dailyChangeData?.currentPrice, 2) || '--'}
                                        </td>
                                        <td style={{ color: getChangePercentageColor(item?.dailyChangeData?.todaysPercentChange, tc()) }}>
                                            {item?.dailyChangeData?.todaysPercentChange !== undefined
                                                ? `${item?.dailyChangeData?.todaysPercentChange >= 0 ? '+' : ''}${fc(item?.dailyChangeData?.todaysPercentChange, 2)}%`
                                                : '--'}
                                        </td>
                                        <td>{formatNumber(item?.dailyChangeData?.todaysVolume) || '--'}</td>
                                        {activeTopType === 'stocks' && <td>{formatNumber(item?.marketCap) || '--'}</td>}
                                        {activeTopType === 'stocks' && <td>{item?.peRatio || '--'}</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        );
    };
    const fetchStockLists = async () => {
        setStockListsLoading(true);
        if (!isAuthenticated) {
            setStockListsLoading(false);
            return;
        }
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/stocklists`, {
                headers: { Authorization: `Bearer ${userToken}` },
                params: {
                    includeStocks: false,
                },
            });
            setStockLists(response.data);
            setActiveWatchlist(response.data[0]?._id);
        } catch (error) {
            console.error('Error fetching stock lists:', error);
        }
        setStockListsLoading(false);
    };

    const fetchKeyIndicators = async () => {  
        setKeyIndicatorsLoading(true);  
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/keyindicators`, {
                headers: { Authorization: `Bearer ${userToken}` },
                params: {
                    includeDayData: true,
                },
            });
            setKeyIndicatorData(response.data);
        } catch (error) {
            console.error('Error fetching key indicators:', error);
        }
        setKeyIndicatorsLoading(false);
    };

    const fetchActiveListStocks = async () => {
        setStockListsLoading(true);
        try {
            const response = await axios.get(`${config.backendUrl}/api/markets/stocklists`, {
                headers: { Authorization: `Bearer ${userToken}` },
                params: {
                    listIds: activeWatchlist,
                    page: currentPage,
                    includeDayData: true,
                },
            });

            if (response.data.length > 0) {
                const activeList = response.data[0];
                setActiveListStocks(activeList.stocks);
                setTotalPages(activeList.totalPages);
            } else {
                setActiveListStocks([]);
                setTotalPages(1);
            }
        } catch (error) {
            console.error('Error fetching active list stocks:', error);
        }
        setStockListsLoading(false);
    };

    const handleCreateList = async () => {
        if (!newListName.trim()) {
            alert('Please enter a list name');
            return;
        }
        try {
            const response = await axios.post(
                `${config.backendUrl}/api/markets/stocklists`,
                { name: newListName },
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            );
            setStockLists([...stockLists, response.data]);
            setNewListName('');
            setIsCreatingNewList(false);
        } catch (error) {
            alert(error.response.data.error || 'Error creating new stock list');
            console.error('Error creating new stock list:', error);
        }
    };

    const handleDeleteList = async (listId) => {
        if (!window.confirm('Are you sure you want to delete this stock list?')) {
            return;
        }
        try {
            await axios.delete(`${config.backendUrl}/api/markets/stocklists/${listId}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            });
            setStockLists((prev) => prev.filter((list) => list?._id !== listId));
        } catch (error) {
            console.error('Error deleting stock list:', error);
        }
    };

    useEffect(() => {
        fetchStockLists();
        fetchKeyIndicators();
    }, [userToken, isAuthenticated]);

    useEffect(() => {
        if (activeWatchlist) {
            fetchActiveListStocks();
        }
    }, [activeWatchlist, currentPage]);



    

    return (
        <div className="MarketsV2">
        
           {isAuthenticated &&  <div className='mv2-watchlists-wrapper' style = {{marginTop:'2rem', paddingBottom: '3rem', borderBottom: 'var(--border)'}}>
                <div className="mv2-watchlists">
                    <div className="mv2-watchlists-header">
                        <h2 className="mv2-watchlists-h2">Watchlists</h2>
                        <div className="mv2-watchlist-row">
                            {stockLists.map((list) => (
                                <div key={list._id} className="mv2-watchlist-item">
                                    <div className='mv2-watchlist-btn-wrapper'>
                                        <button
                                            className={`CreatePostFeedButton ${activeWatchlist === list._id ? 'greenButton' : ''}`}  
                                            onClick={() =>{
                                                setActiveWatchlist(list._id);
                                                setCurrentPage(1);
                                            }}
                                        >
                                            {list.name}
                                        </button>
                                        <button
                                            className='keyword-x'
                                            onClick={() => handleDeleteList(list._id)}
                                        >
                                            X
                                        </button>
                                    </div>
                                </div>
                            ))}
                            {isCreatingNewList ? (
                                <div style = {{display: 'flex', gap: '1rem'}}>
                                    <input
                                        type="text"
                                        value={newListName}
                                        className="AddCashInput"
                                        placeholder="Enter watchlist name"
                                        onChange={(e) => setNewListName(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleCreateList();
                                        }}
                                    />
                                    <button className="CreatePostFeedButton" onClick={handleCreateList}>
                                        Save
                                    </button>
                                    <button className="CreatePostFeedButton" onClick={() => setIsCreatingNewList(false)}>
                                        Cancel
                                    </button>
                                </div>
                            ) : (
                                <button className="CreatePostFeedButton" onClick={() => setIsCreatingNewList(true)}>
                                    Add new
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="mv2-watchlist-col" style = {{justifyContent: 'end', display: 'flex', flexDirection: 'column'}}>

                        {activeWatchlist && activeListStocks?.length > 0 && totalPages > 1 && (
                            <div className="mv2-pagination-arrows">
                                <button
                                    className="mv2-pagination-button"
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowLeft}

                                        style={{
                                            fontSize: '2rem',
                                            color: currentPage === 1 ? 'var(--border-color)' : 'var(--text-color)',
                                        }}
                                    />
                                </button>
                               
                                <button
                                    className="mv2-pagination-button"
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        style={{
                                            fontSize: '2rem',
                                            color: currentPage === totalPages ? 'var(--border-color)' : 'var(--text-color)',
                                        }}
                                    />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {renderActiveWatchListItems()}
            </div>

        }   
        <div className='mv2-explore' style = {{marginTop: !isAuthenticated && '2rem'}}>


        <h2 className="mv2-watchlists-h2">Explore</h2>
         <MarketSearchBar 
                        stock={stock} 
                        setStock={setStock} 
                        isAuthenticated={isAuthenticated} 
                        userToken={userToken}
                        navigate={navigate}
                        marginVertical='1.5rem'
                    />
                    <div className='mv2-watchlist-row' style = {{marginBottom: '1.5rem'}}> 
                        <button
                        className={`CreatePostFeedButton ${selectedExploreTab === 'overview' && 'greenButton'}`}
                         onClick={()=> setSelectedExploreTab('overview')}>
                            Overview    
                        </button>
                        <button 
                        onClick={() => { setActiveTopPage(1); setActiveTopType('stocks'); setSelectedExploreTab('charts') }}
                         className={`CreatePostFeedButton ${activeTopType === 'stocks' && selectedExploreTab === 'charts' && 'greenButton'}`}>Stocks</button>
                        <button
                         onClick={() => { setActiveTopPage(1); setActiveTopType('crypto'); setSelectedExploreTab('charts')}} 
                        className={`CreatePostFeedButton ${activeTopType === 'crypto' && selectedExploreTab === 'charts' && 'greenButton'}`}>Crypto</button>
                    </div>
         {selectedExploreTab === 'overview' &&   <>
         <div className='mv2-activelist-flex'>
                {renderKeyIndicators()}
                {renderGainersSection()}
                {renderLosersSection()}
            </div>


            <div className='mv2-activelist-flex'>
                {renderMostActiveSection()}
                {renderForexSection()}
                {renderWorldMarketsSection()}
            </div>
            </>}

           {(selectedExploreTab === 'charts') && renderActiveTopSection()}
           </div>
            
        </div>
    );
}
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function BackButton({callback, fontSize = 'var(--font-med-l)', padding = '0' }){
  return (
    <div className="bb-container"
    style = {{
        fontSize: fontSize,
        padding: padding,
    }}
    >
    <button
    style = {{ border: 'none', cursor: 'pointer', backgroundColor: 'transparent', color: 'var(--action-grey)', padding: '0', margin: '0', fontSize: 'inherit' }}
      onClick={callback}
    >
      <FontAwesomeIcon icon={faArrowLeft} /> <span style={{ color: 'var(--action-grey)', fontSize:'inherit' }}>Back</span>
    </button>
  </div>
  )
}

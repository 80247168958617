import React, { useState } from "react";
import "./Modal.css"; // Import CSS for styling
import axios from "axios";
import config from "../../config";

const Modal = ({ isOpen, onClose }) => {
  const [password, setPassword] = useState("");
  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/analytics/login`,
        {
          password: password,
        }
      );

      if (response.data.status === 1) {
        localStorage.setItem("analyticstoken", response.data.data.token);
        window.location.reload();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert(error.response.data);
    }
  };

  return (
    <div className="mo-modal-overlay">
      <div className="mo-modal-content">
        <h2 className="mo-modal-title">Login</h2>
        <form className="mo-login-form">
          <div className="mo-input-wrapper" style={{ display: "flex" }}>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Please enter password"
              className="mo-password-input"
              required
            />
          </div>
          <button
            onClick={handleSubmit}
            className="mo-signin-button mo-bold"
            type="button"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  // State to hold the current theme
  const [theme, setTheme] = useState('dark');

  // Load the saved theme from local storage or default to light
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'dark';
    setTheme(savedTheme);
  }, []);

  // Function to toggle between light and dark themes
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme); // Save the theme to local storage
  };

  //Function to return true if the theme is dark
  const dk = () => {return theme === 'dark'}

  // Function to return the current theme class 
  const tc = () => {
    return theme === 'light' ? 'light' : 'dark';
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, tc, dk }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
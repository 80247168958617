import { useState } from "react";
import Modal from "./subcomponents/Modal";
import AnalyticTable from "./AnalyticTable";

const Analytics = () => {
  const [isOpen, setIsOpen] = useState(true);

  //eslint-disable-next-line
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const analyticstoken = localStorage.getItem("analyticstoken");

  return (
    <>
      {!analyticstoken && <Modal isOpen={isOpen} onClose={closeModal} />}
      {analyticstoken && <AnalyticTable />}
    </>
  );
};

export default Analytics;
